import React from 'react';

export default function tag(props) {
	const { type, name, values, unit } = props.tag;

	if (type === 'enum') {
		return (
			<div className={type + '-tag-container tag-container'}>
				<div className='tag-content-container'>
					<p className='tag-name required'>
						{unit !== undefined ? name + '(' + unit + ')' : name}
					</p>
					<select
						onChange={(e) => {
							props.updateValue(props.tag, e);
						}}
					>
						<option value='' selected disabled hidden>
							Vælg mulighed
						</option>
						{values.map((value, i) => {
							return <option key={i}>{value}</option>;
						})}
					</select>
				</div>
				<div className='tag-remove-container'>
					<button
						className='tag-remove-button rounded-button'
						onClick={(e) => {
							e.preventDefault();
							props.removeTag(props.tag);
						}}
					>
						Remove
					</button>
				</div>
			</div>
		);
	} else if (type === 'text') {
		return (
			<div className={type + '-tag-container tag-container'}>
				<div className='tag-content-container'>
					<p className='tag-name required'>
						{unit !== undefined ? name + '(' + unit + ')' : name}
					</p>
					<input
						type='text'
						placeholder={name}
						value={tag.value}
						onChange={(e) => {
							props.updateValue(props.tag, e);
						}}
					/>
				</div>
				<div className='tag-remove-container'>
					<button
						className='tag-remove-button rounded-button'
						onClick={(e) => {
							e.preventDefault();
							props.removeTag(props.tag);
						}}
					>
						Remove
					</button>
				</div>
			</div>
		);
	} else if (type === 'number') {
		return (
			<div className={type + '-tag-container tag-container'}>
				<div className='tag-content-container'>
					<p className='tag-name required'>
						{unit !== undefined ? name + ' (' + unit + ')' : name}
					</p>
					<input
						type='text'
						pattern='[1-9][0-9]*'
						placeholder={name}
						value={tag.value}
						onChange={(e) => {
							props.updateValue(props.tag, e);
						}}
					/>
				</div>
				<div className='tag-remove-container'>
					<button
						className='tag-remove-button rounded-button'
						onClick={(e) => {
							e.preventDefault();
							props.removeTag(props.tag);
						}}
					>
						Remove
					</button>
				</div>
			</div>
		);
	} else {
		return (
			<div className={type + '-tag-container tag-container'}>
				<div className='tag-content-container'>
					<p className='tag-name'>{name}</p>
				</div>
				<div className='tag-remove-container'>
					<button
						className='tag-remove-button rounded-button'
						onClick={(e) => {
							e.preventDefault();
							props.removeTag(props.tag);
						}}
					>
						Remove
					</button>
				</div>
			</div>
		);
	}
}
