import { useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

const CreateNote = ({ toggle, id, addNote }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [note, setNote] = useState('');

	const { addToaster } = useToaster();
	const { auth } = useAuth();

	const createNote = async () => {
		if (note !== '') {
			setIsLoading(true);
			try {
				const response = await axios.post(
					`/notes/calender-object/${id}`,
					{
						note: note,
					},
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response?.status === 200) {
					let note = { ...response.data };
					note.created_at = new Date(note.created_at);
					addNote(note);
					addToaster({
						title: 'Create New Note',
						text: 'New note was created',
						type: 'success',
					});
					toggle();
				} else {
					addToaster({
						title: 'Create New Note',
						text: 'Error creating a new note',
						type: 'error',
					});
					setIsLoading(false);
				}
			} catch (error) {
				addToaster({
					title: 'Create New Note',
					text: 'Error creating a new note',
					type: 'error',
				});
				setIsLoading(false);
			}
		}
	};

	return (
		<Popup
			toggle={toggle}
			name='Opret Note'
			action={createNote}
			actionRunning={isLoading}
			buttonText='Opret'
		>
			<section className='vertical-form'>
				<div>
					<h1>Note</h1>
					<textarea
						className='create-note-text-area'
						value={note}
						onChange={(e) => setNote(e.target.value)}
					/>
				</div>
			</section>
		</Popup>
	);
};

export default CreateNote;
