import { useRef, useState, useEffect } from 'react';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

import './styles.css';

export const DeleteObject = (props) => {
	const [verification, setVerification] = useState('');

	const userRef = useRef();
	const { addToaster } = useToaster();

	useEffect(() => {
		userRef.current.focus();
	}, []);

	const deleteAction = () => {
		if (verification.toLocaleLowerCase() === 'slet') {
			props.action();
		} else {
			addToaster({
				title: 'Enter verification text',
				text: 'Please enter the verification text: "slet"',
				type: 'warning',
			});
		}
	};

	return (
		<Popup
			toggle={props.toggle}
			name={props.name}
			action={deleteAction}
			actionRunning={props.isLoading}
			buttonText='Slet'
		>
			<section className='vertical-form'>
				<div className='object-delete-layout'>
					<div>
						<p>Er du sikker på at du vil slette dette objekt?</p>
						<p>Skriv "Slet" for at kunne slette det.</p>
					</div>
					<input
						type='text'
						required
						value={verification}
						ref={userRef}
						autoComplete='off'
						placeholder='Slet'
						onChange={(e) => setVerification(e.target.value)}
					/>
				</div>
			</section>
		</Popup>
	);
};
