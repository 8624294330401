import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import Popup from './popup';
import useAuth from '../../hooks/useAuth';
import Loading from '../loading/Loading';
import useToaster from '../toaster/useToaster';

const EditPopular = (props) => {
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const [popularAutocampers, setPopularAutocampers] = useState([]);
	const [unpopularAutocampers, setUnpopularAutocampers] = useState([]);
	const [fetchIsLoading, setFetchIsLoading] = useState(true);
	const [updateIsLoading] = useState(false);

	useEffect(() => {
		var isMounted = true;
		const controller = new AbortController();

		const fetchAutocampers = async () => {
			try {
				const response = await axios.get('/autocampers', {
					headers: {
						authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response.status === 200) {
					const popular = [];
					const unpopular = [];

					response.data.forEach((autocamper) => {
						if (
							autocamper.popular_autocamper !== null &&
							autocamper.popular_autocamper?.id
						) {
							popular.push({
								autocamper: autocamper,
								updated: false,
							});
						} else {
							unpopular.push({
								autocamper: autocamper,
								updated: false,
							});
						}
					});

					if (isMounted) {
						setPopularAutocampers(popular);
						setUnpopularAutocampers(unpopular);
					}
				} else {
					addToaster({
						title: 'Fetching autocampers',
						text: 'Error fecthing autocampers',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching autocampers',
					text: 'Error fecthing autocampers',
					type: 'error',
				});
			} finally {
				setFetchIsLoading(false);
			}
		};

		fetchAutocampers();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [auth, addToaster]);

	/**
	 * Moves autocampers from one list to the other.
	 *
	 * @param {*} index Index of the autocamper in its related array.
	 * @param {*} isPopular True if it is in the popular autocamper list.
	 */
	const toggleAutocamper = (index, isPopular) => {
		const popular = [...popularAutocampers];
		const unpopular = [...unpopularAutocampers];
		if (isPopular) {
			let autocamper = popular[index];
			autocamper.updated = !autocamper.updated;
			unpopular.push(autocamper);
			popular.splice(index, 1);
		} else {
			let autocamper = unpopular[index];
			autocamper.updated = !autocamper.updated;
			popular.push(autocamper);
			unpopular.splice(index, 1);
		}

		setPopularAutocampers(popular);
		setUnpopularAutocampers(unpopular);
	};

	const updatePopular = async () => {
		const updateList = [];
		unpopularAutocampers.forEach((obj) => {
			if (obj.updated === true)
				updateList.push({ id: obj.autocamper.id, add: false });
		});

		popularAutocampers.forEach((obj) => {
			if (obj.updated === true)
				updateList.push({ id: obj.autocamper.id, add: true });
		});

		if (updateList.length > 0) {
			try {
				const response = await axios.put(
					'/popular-autocampers',
					JSON.stringify({ updateList }),
					{
						headers: {
							authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response?.status === 200 && response?.data) {
					props.updatePopularList();
					props.toggle();
					addToaster({
						title: 'Updating popular autocampers',
						text: 'Successfully updateing popular autocampers',
						type: 'success',
					});
				} else {
					addToaster({
						title: 'Updating popular autocampers',
						text: 'Error updating popular autocampers',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Updating popular autocampers',
					text: 'Error updating popular autocampers',
					type: 'error',
				});
			}
		} else {
			addToaster({
				title: 'Updating popular autocampers',
				text: 'No changes was made',
				type: 'info',
			});
		}
	};

	return (
		<Popup
			toggle={props.toggle}
			name='Opdater Populærer Autocampere'
			action={updatePopular}
			actionRunning={updateIsLoading}
			buttonText='Opdater'
		>
			{fetchIsLoading ? (
				<Loading />
			) : (
				<section className='edit-popular-layout-container'>
					<div className='edit-popular-autocamper-container'>
						<h1>Ikke populærer</h1>
						{unpopularAutocampers.map((obj, i) => {
							return (
								<div
									key={i}
									className='edit-popular-autocamper'
									onClick={() => toggleAutocamper(i, false)}
								>
									<p>{obj.autocamper.name}</p>
									<span className='edit-popular-switch-label'>
										<p>+</p>
									</span>
								</div>
							);
						})}
					</div>
					<div className='edit-popular-autocamper-container'>
						<h1>Populærer</h1>
						{popularAutocampers.map((obj, i) => {
							return (
								<div
									key={i}
									className='edit-popular-autocamper'
									onClick={() => toggleAutocamper(i, true)}
								>
									<p>{obj.autocamper.name}</p>
									<span className='edit-popular-switch-label'>
										<p>-</p>
									</span>
								</div>
							);
						})}
					</div>
				</section>
			)}
		</Popup>
	);
};

export default EditPopular;
