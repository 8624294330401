import React, { Component } from 'react';
import LoadButton from '../../loading/LoadButton';
import TimeOption from '../../timeoption/TimeOption';
import { get } from 'lodash';
import validTimes from './times.json';

class CalenderDay extends Component {
	state = {
		showTimes: false,
	};

	toggleTimes = () => {
		this.props.updateDeliveryDay(
			this.props.day,
			!this.props.day.deliveryDay
		);
	};

	render() {
		const { day, updateValue, showValue, updateNewTime, updateTime } =
			this.props;

		const week = [
			'mandag',
			'tirsdag',
			'onsdag',
			'torsdag',
			'fredag',
			'lørdag',
			'søndag',
		];

		return (
			<div
				className={
					'day-item' + (day.update ? ' day-item-highlight' : '')
				}
			>
				<span className='day-weekday'>
					{!day.update && week[day.weekday]}
				</span>
				<span
					className={
						day.update ? 'day-close-update' : 'day-close-hide'
					}
					onClick={() =>
						this.props.toggleUpdate(this.props.day, false)
					}
				>
					X
				</span>
				{!day.update &&
					(showValue ? (
						<p
							className='day-value'
							onClick={() =>
								this.props.toggleUpdate(this.props.day, true)
							}
						>
							{day.value}
						</p>
					) : (
						<div
							className='day-times'
							onClick={() =>
								this.props.toggleUpdate(this.props.day, true)
							}
						>
							{day.swapday ? (
								<React.Fragment>
									<div>
										<p className='time-text'>
											Ind:{' '}
											{day.swapday &&
												day.deliveryStart.slice(0, -7)}
											-
											{day.swapday &&
												day.deliveryEnd.slice(0, -7)}
										</p>
									</div>
									<div>
										<p className='time-text'>
											Ud:{' '}
											{day.swapday &&
												day.pickupStart.slice(0, -7)}
											-
											{day.swapday &&
												day.pickupEnd.slice(0, -7)}
										</p>
									</div>
								</React.Fragment>
							) : (
								<div>
									<p className='no-swap-day'>
										Ikke byttedag!
									</p>
								</div>
							)}
						</div>
					))}
				{day.update &&
					(showValue ? (
						<div className='day-update-form'>
							<input
								type='number'
								className='day-update-input'
								onChange={(e) =>
									this.props.updateNewValue(
										day,
										e.target.valueAsNumber
									)
								}
							/>
							<LoadButton
								classes='day-update-button'
								action={() => updateValue(day)}
								isLoading={day.isUpdating}
								disabled={!day.update}
								color='green'
								text='Opdater'
							/>
						</div>
					) : (
						<div
							className={
								'update-time-container' +
								(!day.deliveryDay
									? ' update-time-hide-container'
									: '')
							}
						>
							{day.deliveryDay && (
								<React.Fragment>
									<div className='pickup-start-container'>
										<select
											className='time-selection'
											defaultValue={get(
												validTimes,
												['defaultDeliveryStart'],
												{}
											)}
											onChange={(e) =>
												updateNewTime(
													day,
													true,
													false,
													e.target.value
												)
											}
										>
											<TimeOption />
										</select>
									</div>
									<div className='pickup-to-container'>
										<p className='update-time-to-text'>-</p>
									</div>
									<div className='pickup-end-container'>
										<select
											className='time-selection'
											defaultValue={get(
												validTimes,
												['defaultDeliveryEnd'],
												{}
											)}
											onChange={(e) =>
												updateNewTime(
													day,
													false,
													false,
													e.target.value
												)
											}
										>
											<TimeOption />
										</select>
									</div>
									<div className='delivery-start-container'>
										<select
											className='time-selection'
											defaultValue={get(
												validTimes,
												['defaultPickupStart'],
												{}
											)}
											onChange={(e) =>
												updateNewTime(
													day,
													true,
													true,
													e.target.value
												)
											}
										>
											<TimeOption />
										</select>
									</div>
									<div className='delivery-to-container'>
										<p className='update-time-to-text'>-</p>
									</div>
									<div className='delivery-end-container'>
										<select
											className='time-selection'
											defaultValue={get(
												validTimes,
												['defaultPickupEnd'],
												{}
											)}
											onChange={(e) =>
												updateNewTime(
													day,
													false,
													true,
													e.target.value
												)
											}
										>
											<TimeOption />
										</select>
									</div>
								</React.Fragment>
							)}
							<div className='swap-day-container'>
								<input
									type='checkbox'
									checked={day.deliveryDay}
									onChange={this.toggleTimes}
								/>
								<label className='label-swap-day'>
									Skiftedag?
								</label>
							</div>
							<div className='update-time-button-container'>
								<LoadButton
									classes='day-update-button'
									isLoading={day.isUpdating}
									color='green'
									text='Opdater'
									action={() => updateTime(day)}
								/>
							</div>
						</div>
					))}
			</div>
		);
	}
}

export default CalenderDay;
