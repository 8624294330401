import Toaster from '../../toaster/Toaster';
import SideBar from './sidebar/Sidebar';

export const DefaultStructure = ({ children }) => {
	return (
		<>
			<SideBar />
			<div className='main-body'>{children}</div>
			<Toaster />
		</>
	);
};
