import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import Pagination from '../../pagination/Pagination';
import UserRow from './UserRow';

const UsersPage = () => {
	const [users, setUsers] = useState(null);
	const [userCount, setUserCount] = useState(null);
	const [pageNumber, setPageNumber] = useState(0);
	const [sortBy, setSortBy] = useState('id');
	const [pageSize, setPageSize] = useState(10);
	const [order, setOrder] = useState('ASC');
	const [email, setEmail] = useState('');

	const [debounceUserCount] = useDebounce(userCount, 1000);

	return (
		<Pagination
			url='/users'
			countUrl='/users/count'
			count={debounceUserCount}
			setCount={setUserCount}
			page={pageNumber}
			setPage={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			setObjects={setUsers}
			sortBy={sortBy}
			setSortBy={setSortBy}
			sortByList={[
				{ name: 'ID', value: 'id' },
				{ name: 'Email', value: 'email' },
				{ name: 'Brugernavn', value: 'username' },
				{ name: 'Fornavn', value: 'firstname' },
				{ name: 'Efternavn', value: 'lastname' },
			]}
			order={order}
			setOrder={setOrder}
			parameter={'email'}
			searchParameter={email}
			setSearchParameter={setEmail}
		>
			{users !== null &&
				users.map((user, i) => {
					return <UserRow key={i} user={user} />;
				})}
		</Pagination>
	);
};

export default UsersPage;
