import { useState, useEffect } from 'react';
import Popup from './popup';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';
import TimeOption from '../timeoption/TimeOption';
import useToaster from '../toaster/useToaster';

export const CreateCalenderObject = (props) => {
	const [type, setType] = useState('reservation');
	const [startTime, setStartTime] = useState('12:00');
	const [startDate, setStartDate] = useState('');
	const [endTime, setEndTime] = useState('12:00');
	const [endDate, setEndDate] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	useEffect(() => {
		const { date } = props;
		const newStartDate =
			date.getFullYear() +
			'-' +
			(date.getMonth() < 9
				? '0' + (date.getMonth() + 1)
				: date.getMonth() + 1) +
			'-' +
			(date.getDate() < 10 ? '0' + date.getDate() : date.getDate());

		setStartDate(newStartDate);
	}, [props]);

	const toggleType = () => {
		setType(type === 'reservation' ? 'maintenance' : 'reservation');
	};

	const createCalenderObject = async () => {
		const selectedStartDate = startDate.split('-');
		const selectedStartTime = startTime.split(':');
		const start = new Date(
			selectedStartDate[0],
			selectedStartDate[1] - 1,
			selectedStartDate[2],
			selectedStartTime[0],
			selectedStartTime[1]
		);

		const selectedEndDate = endDate.split('-');
		const selectedEndTime = endTime.split(':');
		const end = new Date(
			selectedEndDate[0],
			selectedEndDate[1] - 1,
			selectedEndDate[2],
			selectedEndTime[0],
			selectedEndTime[1]
		);

		if (start && end && start.getTime() <= end.getTime()) {
			try {
				setIsLoading(true);
				const response = await axios.post(
					'/admin/calender-objects',
					JSON.stringify({
						autocamper: props.id,
						type: type,
						start: start.getTime(),
						end: end.getTime(),
					}),
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response.status === 200) {
					addToaster({
						title: 'Creating Calender Object',
						text: 'Successfully created a new calender object',
						type: 'success',
					});

					props.toggle();
				} else {
					addToaster({
						title: 'Creating Calender Object',
						text: 'Error creating the calender object',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Creating Calender Object',
					text: 'Error creating the calender object',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			addToaster({
				title: 'Creating Calender Object',
				text: 'Invalid selected times',
				type: 'warning',
			});
		}
	};

	return (
		<Popup
			toggle={props.toggle}
			name='Nyt kalender objekt'
			action={() => createCalenderObject()}
			actionRunning={isLoading}
		>
			<div className='create-calender-container'>
				<div className='vertical-form'>
					<div className='create-calender-object-type-div'>
						<div>
							<p>Reservation</p>
							<span
								className={
									'calender-object-type-input' +
									(type === 'reservation'
										? ' calender-object-type-reservation'
										: ' calender-object-type-maintenance')
								}
								onClick={() => toggleType()}
							>
								<span className='calender-object-type-indicator'></span>
							</span>
							<p>Maintenance</p>
						</div>
					</div>
					<div>
						<p>
							For en reservation bliver "swap margin" tilføjet til
							tiden efterfølgende, ligesom en almindelig booking.
						</p>
					</div>
					<div>
						<label className='required'>Start</label>
						<div className='form-field-input-container'>
							<input
								type='date'
								onChange={(e) => setStartDate(e.target.value)}
								defaultValue={startDate}
							/>
							<select
								onChange={(e) => setStartTime(e.target.value)}
								defaultValue='12:00'
							>
								<TimeOption />
							</select>
						</div>
						<label className='required'>Slut</label>
						<div className='form-field-input-container'>
							<input
								type='date'
								onChange={(e) => setEndDate(e.target.value)}
							/>
							<select
								onChange={(e) => setEndTime(e.target.value)}
								defaultValue='12:00'
							>
								<TimeOption />
							</select>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default CreateCalenderObject;
