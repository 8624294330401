import { useEffect, useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import utility from '../../utils/utility';
import LoadingIcon from '../loading/LoadingIcon';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

const PayIncome = ({ income, toggle, updateIncome }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [accountDetails, setAccountDetails] = useState(null);
	const [isLoadingAccount, setIsLoadingAccount] = useState(true);
	const [displayBookings, setDisplayBookings] = useState(false);
	const [sendEmail, setSendEmail] = useState(true);

	const { addToaster } = useToaster();
	const { auth } = useAuth();

	const handlePayIncome = async () => {
		setIsLoading(true);
		try {
			const response = await axios.put(
				`/organization-income/${income?.id}/paid`,
				{
					sendEmail: sendEmail,
				},
				{
					headers: { Authorization: `Bearer ${auth?.jwt}` },
				}
			);

			if (response?.status === 200) {
				updateIncome(response?.data);
				toggle();
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		let isMounted = true;

		const fetchOrganizationAccount = async () => {
			try {
				const response = await axios.get(
					`/organizations/${income?.organization?.id}/account`,
					{
						headers: { Authorization: `Bearer ${auth?.jwt}` },
					}
				);

				if (response?.status === 200) {
					if (isMounted) setAccountDetails(response?.data);
				}
			} catch (error) {
				addToaster({
					title: 'Fetching organization account',
					text: 'Something went wrong',
					type: 'error',
				});
			}
			if (isMounted) setIsLoadingAccount(false);
		};

		fetchOrganizationAccount();

		return () => {
			isMounted = false;
		};
	}, [income, auth, addToaster]);

	const bookingPrice = (booking) => {
		let price = booking?.autocamperPrice + booking?.startPrice;
		booking?.payment_addons?.forEach((addon) => {
			price += addon.price;
		});
		return price;
	};

	return (
		<Popup
			toggle={toggle}
			name='Månedlig Betaling'
			action={handlePayIncome}
			actionRunning={isLoading}
			buttonText={'Betal'}
		>
			<div className='popup-account-number-layout'>
				<div>
					<div className='popup-account-booking-header'>
						<h1>Bookinger</h1>
						<button
							className='circle-button'
							onClick={() => setDisplayBookings(!displayBookings)}
						>
							{displayBookings ? 'V' : '>'}
						</button>
					</div>
					<div
						className={
							displayBookings
								? 'popup-account-booking-list'
								: 'popup-account-booking-list popup-account-booking-list-hidden'
						}
					>
						{income?.booking_payments?.map((booking, i) => {
							return (
								<div
									key={i}
									className='popup-account-booking-item'
								>
									<p>
										Booking ID:{' '}
										<span>
											{utility.getIdFormat(booking?.id)}
										</span>
									</p>
									<p>
										Total:{' '}
										<span>
											{bookingPrice(booking).toFixed(2)}{' '}
											DKK
										</span>
									</p>
									<p>
										Udlejers Andel:{' '}
										<span>
											{(
												bookingPrice(booking) *
												income?.incomeRate
											).toFixed(2)}{' '}
											DKK
										</span>
									</p>
								</div>
							);
						})}
					</div>
				</div>
				<div>
					<h1>Konto- & Registreringsnummer</h1>
					<div className='popup-account-number-container'>
						{isLoadingAccount ? (
							<div className='popup-account-number-loading'>
								<LoadingIcon className='popup-account-number-loading-icon' />
							</div>
						) : accountDetails === null ? (
							<p>Noget gik galt...</p>
						) : (
							<div>
								<p>
									Beløb:{' '}
									<span>
										{(
											income?.income * income?.incomeRate
										).toFixed(2)}{' '}
										DKK
									</span>
								</p>
								<p>
									Kontonummer:{' '}
									<span>{accountDetails?.accountNumber}</span>
								</p>
								<p>
									Registreringsnummer:{' '}
									<span>
										{accountDetails?.registrationNumber}
									</span>
								</p>
								<input
									type='checkbox'
									value={sendEmail}
									checked={sendEmail}
									onChange={(e) => setSendEmail(!sendEmail)}
								/>
								<label>Send email?</label>
							</div>
						)}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default PayIncome;
