import './styles.css';
import ToasterItem from './ToasterItem';
import useToaster from './useToaster';

const Toaster = () => {
	const {
		toasters,
		removeToaster,
		toasterNotify,
		displayToasters,
		showToasters,
		hideToasters,
	} = useToaster();

	const toggleDisplayToasters = () => {
		if (displayToasters !== true) showToasters();
		else hideToasters();
	};

	return (
		<div className='toaster-outer-container'>
			<div
				className={
					toasters.length === 0
						? 'toaster-button toaster-button-hidden'
						: 'toaster-button'
				}
				onClick={() => toggleDisplayToasters()}
			>
				<img
					src='/bell.svg'
					alt='Notifications'
					className='toaster-icon'
				/>
				<span
					className={
						toasterNotify === true
							? 'toaster-button-notify'
							: 'toaster-button-notify toaster-button-notify-hidden'
					}
				></span>
			</div>
			{displayToasters && toasters.length > 0 && (
				<div className='toaster-list-container'>
					<div className='toaster-list'>
						{toasters.map((toaster, i) => (
							<ToasterItem
								key={i}
								toaster={toaster}
								remove={() => removeToaster(i)}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default Toaster;
