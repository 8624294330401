import SidebarItem from './SidebarItem';
import useAuth from '../../../../hooks/useAuth';

const ROLES = {
	lessor: 'lessor',
	admin: 'admin',
};

const Sidebar = () => {
	const { auth } = useAuth();
	const items = [
		{
			id: 1,
			name: 'Start',
			icon: '/home.svg',
			alt: '',
			link: '/dashboard',
			access: [ROLES.lessor, ROLES.admin],
		},
		{
			id: 2,
			name: 'Organisation',
			icon: '/business.svg',
			alt: 'Organization icon',
			link: '/organization',
			access: [ROLES.lessor, ROLES.admin],
		},
		{
			id: 3,
			name: 'Autocamper',
			icon: '/autocamper.svg',
			alt: 'Autocamper icon',
			link: '/autocamper',
			access: [ROLES.lessor, ROLES.admin],
		},
		{
			id: 4,
			name: 'Email',
			icon: '/message.svg',
			alt: 'Messages',
			link: '/email',
			access: [ROLES.admin],
		},

		{
			id: 6,
			name: 'Indkomst',
			icon: '/income.svg',
			alt: 'Income',
			link: '/income',
			access: [ROLES.lessor, ROLES.admin],
		},
		{
			id: 7,
			name: 'Udbetaling',
			icon: '/lessor.svg',
			alt: 'Lessors',
			link: '/incomes',
			access: [ROLES.admin],
		},
		{
			id: 8,
			name: 'Depositum',
			icon: '/deposit.svg',
			alt: 'Deposit',
			link: '/deposit',
			access: [ROLES.admin],
		},
		{
			id: 5,
			name: 'Brugere',
			icon: '/user.svg',
			alt: 'Bruger icon',
			link: '/user',
			access: [ROLES.admin],
		},
		{
			id: 9,
			name: 'Global',
			icon: '/globe.svg',
			alt: 'Global icon',
			link: '/global',
			access: [ROLES.admin],
		},
	];

	return (
		<nav className='sidebar'>
			{items.map(
				(item) =>
					item.access.includes(auth?.user?.role?.type) && (
						<SidebarItem sideBarItem={item} key={item.id} />
					)
			)}
		</nav>
	);
};

export default Sidebar;
