import React, { useState } from 'react';
import PublicStructure from '../../structures/public/PublicStructure';
import './styles.css';
import validateSignup from '../../../utils/validateSignup';
import SignupInput from './SignupInput';
import axios from '../../../api/axios';
import { Link } from 'react-router-dom';
import SignupProgressItem from './SignupProgressItem';

const SignupPage = () => {
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [birthday, setBirthday] = useState('');
	const [phonenumber, setPhonenumber] = useState('');
	const [street, setStreet] = useState('');
	const [streetNumber, setStreetNumber] = useState('');
	const [zipcode, setZipcode] = useState('');
	const [city, setCity] = useState('');
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');

	const [inputErrors, setInputErrors] = useState({});
	const [signupError, setSignupError] = useState(null);
	const [inputSectionNumber, setInputSectionNumber] = useState(0);

	const signupProgress = () => {
		let validation = false;
		let errors = {};
		if (inputSectionNumber === 0) {
			const validFirstname = validateSignup.validateFirstname(firstname);
			if (!validFirstname) errors.firstname = 'Ugyldigt fornavn';
			const validLastname = validateSignup.validateLastname(lastname);
			if (!validLastname) errors.lastname = 'Ugyldigt efternavn';
			const validBirthdate = validateSignup.validateBirthdate(birthday);
			if (!validBirthdate) errors.birthday = 'Ugyldigt fødselsdato';
			const validPhonenumber =
				validateSignup.validatePhoneNumber(phonenumber);
			if (!validPhonenumber)
				errors.phonenumber = 'Ugyldigt telefonnummer';

			validation =
				validFirstname &&
				validLastname &&
				validBirthdate &&
				validPhonenumber;
		} else if (inputSectionNumber === 1) {
			const validStreet = validateSignup.validateStreet(street);
			if (!validStreet) errors.street = 'Ugyldigt vejnavn';
			const validStreetnumber =
				validateSignup.validateStreetNumber(streetNumber);
			if (!validStreetnumber) errors.streetnumber = 'Ugyldigt husnummer';
			const validZipcode = validateSignup.validatePostalCode(zipcode);
			if (!validZipcode) errors.zipcode = 'Ugyldigt postnummer';
			const validCity = validateSignup.validateCity(city);
			if (!validCity) errors.city = 'Ugyldig by';

			validation =
				validStreet && validStreetnumber && validZipcode && validCity;
		} else if (inputSectionNumber === 2) {
			const validUsername = validateSignup.validateUsername(username);
			if (!validUsername) errors.username = 'Ugyldigt brugernavn';
			const validEmail = validateSignup.validateEmail(email);
			if (!validEmail) errors.email = 'Ugyldig email';
			const validPassword = validateSignup.validatePassword(
				password,
				passwordRepeat
			);
			if (!validPassword) errors.password = 'Ugyldig adgangskode';

			validation = validUsername && validEmail && validPassword;
		}

		if (validation === true) {
			if (inputSectionNumber < 2) {
				setInputSectionNumber(
					(inputSectionNumber) => inputSectionNumber + 1
				);
			} else {
				createUserRequest();
			}
		}
		setInputErrors(errors);
	};

	const createUserRequest = async () => {
		try {
			const birthdateSplit = birthday.split('-');
			const birthdate = `${birthdateSplit[2]}-${birthdateSplit[1]}-${birthdateSplit[0]}`;

			const response = await axios.post(
				'/auth/local/register',
				{
					username: username,
					email: email,
					password: password,
					firstname: firstname,
					lastname: lastname,
					address: {
						street: street,
						streetNumber: streetNumber,
						postalCode: zipcode,
						city: city,
					},
					birthdate: birthdate,
					phoneNumber: phonenumber,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response?.status === 200) {
				setInputSectionNumber(3);
			}
		} catch (error) {
			if (error?.response?.status === 400) {
				setSignupError(
					'Noget gik galt, emailen eller brugernavnet kan allerede være i brug.'
				);
			} else {
				setSignupError('Server fejl');
			}
		}
	};

	const progressItems = ['Personlig', 'Adresse', 'Profil'];

	return (
		<PublicStructure headerBackground='green'>
			<section className='public-first-section signup-page-container'>
				<div className='signup-page-signup-container'>
					{inputSectionNumber < 3 ? (
						<form className='signup-page-form'>
							<div className='signup-page-form-header'>
								<h1>Opret profil</h1>
							</div>
							<div className='signup-page-signup-progress-container'>
								{progressItems.map((item, i) => {
									return (
										<SignupProgressItem
											key={i}
											title={item}
											progress={i}
											currentProgress={inputSectionNumber}
										/>
									);
								})}
							</div>
							<div className='signup-page-signup-input-section'>
								{inputSectionNumber === 0 ? (
									<>
										<SignupInput
											name='Fornavn'
											type='text'
											value={firstname}
											setValue={setFirstname}
											errorText={inputErrors?.firstname}
										/>
										<SignupInput
											name='Efternavn'
											type='text'
											value={lastname}
											setValue={setLastname}
											errorText={inputErrors?.lastname}
										/>
										<SignupInput
											name='Fødselsdato'
											type='text'
											placeholder='DD-MM-YYYY'
											value={birthday}
											setValue={setBirthday}
											errorText={inputErrors?.birthday}
										/>
										<SignupInput
											name='Telefonnummer'
											type='number'
											value={phonenumber}
											setValue={setPhonenumber}
											errorText={inputErrors?.phonenumber}
										/>
									</>
								) : inputSectionNumber === 1 ? (
									<>
										<SignupInput
											name='Vej'
											type='text'
											value={street}
											setValue={setStreet}
											errorText={inputErrors?.street}
										/>
										<SignupInput
											name='Husnummer'
											type='text'
											value={streetNumber}
											setValue={setStreetNumber}
											errorText={
												inputErrors?.streetNumber
											}
										/>
										<SignupInput
											name='Postnummer'
											type='number'
											value={zipcode}
											setValue={setZipcode}
											errorText={inputErrors?.zipcode}
										/>
										<SignupInput
											name='By'
											type='text'
											value={city}
											setValue={setCity}
											errorText={inputErrors?.city}
										/>
									</>
								) : (
									<>
										<SignupInput
											name='Brugernavn'
											type='text'
											value={username}
											setValue={setUsername}
											errorText={inputErrors?.username}
										/>
										<SignupInput
											name='Email'
											type='email'
											value={email}
											setValue={setEmail}
											errorText={inputErrors?.email}
										/>
										<SignupInput
											name='Adgangskode'
											type='password'
											value={password}
											setValue={setPassword}
											errorText={inputErrors?.password}
										/>
										<SignupInput
											name='Gentag adgangskode'
											type='password'
											value={passwordRepeat}
											setValue={setPasswordRepeat}
											errorText={
												inputErrors?.passwordRepeat
											}
										/>
									</>
								)}
							</div>
							<div className='signup-page-signup-direct-buttons-container'>
								{inputSectionNumber > 0 && (
									<div>
										<input
											className='signup-page-signup-back-button'
											type='button'
											value='Tilbage'
											onClick={() =>
												setInputSectionNumber(
													(n) => n - 1
												)
											}
										/>
									</div>
								)}
								<div>
									<button
										onClick={(e) => {
											e.preventDefault();
											signupProgress();
										}}
										className='signup-page-signup-button'
									>
										{inputSectionNumber < 2
											? 'Næste'
											: 'Opret'}
									</button>
								</div>
							</div>
							{signupError && (
								<span className='signup-page-error-text'>
									{signupError}
								</span>
							)}
							<div className='signup-page-already-account-container'>
								<Link
									to='/login'
									className='signup-page-already-account-text'
								>
									Jeg har allerede en profil
								</Link>
							</div>
						</form>
					) : (
						<div className='signup-page-signup-complete-container'>
							<img
								src='./small-logo.png'
								className='singup-page-signup-complete-logo'
								alt='Logo'
							/>
							<h1 className='signup-page-signup-complete-title'>
								Bekræft din nye profil
							</h1>
							<p className='signup-page-signup-complete-text'>
								Bekræftigelsesemail sendt til{' '}
								<span className='signup-page-signup-complete-email'>
									{email}
								</span>
								, hvori du skal bekræfte oprettelsen før du kan
								logge ind.
							</p>
							<Link
								to='/login'
								className='signup-page-signup-complete-button'
							>
								Log ind
							</Link>
						</div>
					)}
				</div>
			</section>
		</PublicStructure>
	);
};

export default SignupPage;
