import React from 'react';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const Addon = (props) => {
	const { addon } = props;

	// let history = useHistory();

	const handleRoute = () => {
		// history.push(`/addon/${addon.id}`);
	};

	return (
		<div className='addon-item'>
			<div>
				<p>{addon.name}</p>
			</div>
			<div>
				<p>{addon.description}</p>
			</div>
			<div>
				<p>{addon.price}</p>
			</div>
			<div>
				<p>{addon.perDay ? 'Per dag' : '1-gangs'}</p>
			</div>
			<div>
				<Link to={`/organization/addon/${addon.id}`}>
					<button className='rounded-button' onClick={handleRoute}>
						Edit
					</button>
				</Link>
			</div>
		</div>
	);
};

export default Addon;
