import React, { useState } from 'react';
import axios from 'axios';
import useAuth from '../../hooks/useAuth';
import Popup from './popup';
import useToaster from '../toaster/useToaster';

function CreateAddons(props) {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [perDay, setPerDay] = useState(null);
	const [price, setPrice] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const createAddon = async () => {
		if (name !== '' && description !== '' && perDay !== null && price > 0) {
			setIsLoading(true);
			try {
				const response = await axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}/addons`,
					headers: {
						authorization: `Bearer ${auth?.jwt}`,
						'Content-Type': 'application/json',
					},
					data: {
						name: name,
						description: description,
						price: price,
						perDay: perDay,
					},
				});

				if (response.status === 200) {
					addToaster({
						title: 'Tilkøb oprettet',
						text: 'Tilkøbet er blevet oprettet',
						type: 'success',
						link: `/organization/addon/${response?.data?.id}`,
						linkText: `Se tilkøb`,
					});

					props.addAddon(response?.data);
					props.toggle();
				} else {
					addToaster({
						title: response.status,
						text: response.message,
						type: 'error',
					});

					setIsLoading(false);
				}
			} catch (error) {
				addToaster({
					title: error.name,
					text: error.message,
					type: 'error',
				});

				setIsLoading(false);
			}
		} else {
			addToaster({
				title: 'Forkert udfyldt',
				text: 'Der er et felt som ikke er udfyldt korrekt',
				type: 'warning',
			});
		}
	};

	return (
		<Popup
			toggle={props.toggle}
			name='Nyt Tilkøb'
			action={() => createAddon(name, description, perDay, price)}
			actionRunning={isLoading}
		>
			<div className='create-addons-container'>
				<form className='create-addons-form'>
					<div className='vertical-form'>
						<div className='form-field'>
							<label className='required'>Navn</label>
							<input
								type='text'
								name='name'
								autoComplete='off'
								className='form-field-input-text'
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className='form-field'>
							<label className='required'>Beskrivelse</label>
							<input
								type='text'
								name='name'
								autoComplete='off'
								className='form-field-input-text'
								onChange={(e) => setDescription(e.target.value)}
							/>
						</div>
						<div className='form-field'>
							<label className='required'>Betaling</label>
							<div className='form-field-input-container'>
								<input
									type='radio'
									name='payment-type'
									id='radio1'
									value='false'
									onChange={() => setPerDay(false)}
								/>
								<label htmlFor='radio1'>1-gangs beløb</label>
								<br />
								<input
									type='radio'
									name='payment-type'
									id='radio2'
									value='true'
									onChange={() => setPerDay(true)}
								/>
								<label htmlFor='radio2'>Per dag</label>
								<br />
							</div>
						</div>
						<div className='form-field'>
							<label className='required'>Pris</label>
							<input
								type='number'
								name='price'
								autoComplete='off'
								className='form-field-input-text'
								onChange={(e) => setPrice(e.target.value)}
							/>
						</div>
					</div>
				</form>
			</div>
		</Popup>
	);
}

export default CreateAddons;
