import React, { Component } from 'react';
import Autocamper from './autocamper';

class Autocampers extends Component {
	render() {
		const { autocampers } = this.props;

		if (autocampers.length === 0) {
			return <p>Ingen autocamper</p>;
		}

		return (
			<div className='autocampers-container'>
				{autocampers.map(autocamper => (
					<Autocamper autocamper={autocamper} key={autocamper.id} />
				))}
			</div>
		);
	}
}

export default Autocampers;
