import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import utility from '../../../utils/utility';
import { ErrorHandling } from '../../error/ErrorHandling';
import Loading from '../../loading/Loading';
import LoadingIcon from '../../loading/LoadingIcon';
import useToaster from '../../toaster/useToaster';
import './styles.css';

const paymentStatus = {
	unpaid: 'Ikke betalt',
	partly: 'Delvist betalt',
	paid: 'Betalt',
};

const UserPage = () => {
	const { auth } = useAuth();
	const { id } = useParams();
	const { addToaster } = useToaster();

	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [bookings, setBookings] = useState(null);
	const [bookingsIsLoading, setBookingsIsLoading] = useState(true);
	const [bookingsError, setBookingsError] = useState(null);

	useEffect(() => {
		let isMounted = true;

		/**
		 * Fetches a user.
		 */
		const fetchUser = async () => {
			setIsLoading(true);

			try {
				const response = await axios.get(`users/${id}`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
				});

				if (response?.status === 200) {
					if (isMounted) setUser(response?.data);
				}
			} catch (error) {
				setError(error.status);
				addToaster({
					title: `Fetching User`,
					text: 'Error fetching user',
					type: 'error',
				});
			} finally {
				if (isMounted) setIsLoading(false);
			}
		};

		fetchUser();

		return () => {
			isMounted = false;
		};
	}, [id, auth, addToaster]);

	useEffect(() => {
		let isMounted = true;

		const fetchBookings = async () => {
			setBookingsIsLoading(true);
			try {
				const response = await axios.get(`users/${id}/bookings`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
				});

				if (response?.status === 200) {
					let bookings = response.data.map((booking) => {
						let newBooking = { ...booking };
						newBooking.start = new Date(parseInt(newBooking.start));
						newBooking.end = new Date(parseInt(newBooking.end));
						return newBooking;
					});

					if (isMounted) {
						setBookings(bookings);
					}
				}
			} catch (error) {
				const err = error.toJSON();
				setBookingsError(err.status);
			} finally {
				if (isMounted) setBookingsIsLoading(false);
			}
		};

		fetchBookings();

		return () => {
			isMounted = false;
		};
	}, [id, user, auth]);

	return isLoading ? (
		<Loading />
	) : error !== null ? (
		<ErrorHandling status={error} />
	) : (
		<div className='user-page-layout'>
			<div className='container container-content user-page-details-container'>
				<h1>Bruger</h1>
				<p>
					Brugernavn:{' '}
					<span className='user-page-user-value'>
						{user.username}
					</span>
				</p>
				<p>
					Navn:{' '}
					<span className='user-page-user-value'>
						{user.firstname} {user.lastname}
					</span>
				</p>
				<p>
					Email:{' '}
					<span className='user-page-user-value'>{user.email}</span>
				</p>
				<p>
					Telefonnummer:{' '}
					<span className='user-page-user-value'>
						{user.phoneNumber}
					</span>
				</p>
			</div>
			<div className='container container-content user-page-toggles-outer-container'>
				<div className='user-page-toggles-container'>
					<div className='user-page-toggle-item'>
						<p>Bruger Bekræftet?</p>
						{user.confirmed === true ? (
							<span className='user-page-toggle user-page-toggle-positive'>
								Bekræftet
							</span>
						) : (
							<span className='user-page-toggle user-page-toggle-negative'>
								Ikke Bekræftet
							</span>
						)}
					</div>
					<div className='user-page-toggle-item'>
						<p>Bruger Blokeret?</p>
						{user.blocked === true ? (
							<span className='user-page-toggle user-page-toggle-negative'>
								Blokeret
							</span>
						) : (
							<span className='user-page-toggle user-page-toggle-positive'>
								Ikke blokeret
							</span>
						)}
					</div>
				</div>
			</div>
			{user?.organization && (
				<div className='container container-content user-page-organization-container'>
					<h1>Organization</h1>
					<p>
						Navn:{' '}
						<span className='user-page-user-value'>
							{user?.organization?.name}
						</span>
					</p>
					<p>
						Role:{' '}
						<span className='user-page-user-value'>
							{user?.role?.name}
						</span>
					</p>
				</div>
			)}
			<div className='container container-content user-page-bookings-container'>
				<h1>Bookinger</h1>
				<div className='user-page-bookings-list'>
					{bookingsIsLoading ? (
						<LoadingIcon />
					) : bookingsError ? (
						<p className='error-text'>
							Noget gik galt... {bookingsError}
						</p>
					) : bookings.length === 0 ? (
						<p>[Ingen bookinger]</p>
					) : (
						bookings.map((booking) => {
							return (
								<div className='user-page-booking-item'>
									<Link to={`/booking/${booking?.id}`}>
										{utility.getIdFormat(
											booking['booking_payment']?.id
										)}
									</Link>
									<p>
										{utility.getClock(booking?.start)}{' '}
										{utility.getDate(booking?.start)}
									</p>
									<p>
										{utility.getClock(booking?.end)}{' '}
										{utility.getDate(booking?.end)}
									</p>
									<p>
										{
											paymentStatus[
												booking?.booking_payment?.status
											]
										}
									</p>
								</div>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

export default UserPage;
