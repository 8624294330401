import { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import { get } from 'lodash';
import useAuth from '../../../hooks/useAuth';

import validTimes from './times.json';
import weeksData from './weeks.json';
import Calender from './Calender';
import Addon from './Addon';
import Loading from '../../loading/Loading';
import LoadButton from '../../loading/LoadButton';

import './styles.css';
import CreateAddons from '../../popup/createaddons';
import useToaster from '../../toaster/useToaster';
import EditAddress from '../../popup/EditAddress';
import EditBookingMessage from '../../popup/EditBookingMessage';

export const Organization = (props) => {
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const [organization, setOrganization] = useState(null);
	const [weeks, setWeeks] = useState(null);
	const [selectedWeeks, setSelectedWeeks] = useState(0);
	const [addonsPopup, setAddonsPopup] = useState(false);
	const [editSwapMargin, setEditSwapMargin] = useState(false);
	const [newSwapMargin, setNewSwapMargin] = useState({});
	const [swapMargin, setSwapMargin] = useState(null);
	const [updatingMargin, setUpdatingMargin] = useState(null);
	const [accountDetails, setAccountDetails] = useState(null);
	const [editAccount, setEditAccount] = useState(false);
	const [newAccountNumber, setNewAccountNumber] = useState(undefined);
	const [newRegistrationNumber, setNewRegistrationNumber] =
		useState(undefined);
	const [updatingAccount, setUpdatingAccount] = useState(false);
	const [calenderDisplayValue, setCalenderDisplayValue] = useState(true);
	const [editAddress, setEditAddress] = useState(false);
	const [editBookingMessage, setEditBookingMessage] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const fetchOrganization = async () => {
			if (auth?.user?.organization?.id) {
				try {
					const response = await axios.get(
						`/organizations/${auth?.user?.organization?.id}`,
						{
							headers: {
								Authorization: `Bearer ${auth?.jwt}`,
							},
							signal: controller.signal,
						}
					);

					if (response?.status === 200 && response?.data) {
						if (isMounted) {
							setOrganization(response.data);
							setSwapMargin(response.data.swapMargin);

							const orderedDays = get(weeksData, ['weeks'], {});

							for (const day of response.data.calender_days) {
								orderedDays[
									weekNumberIndex(day.weekNumber)
								].innerWeek[
									innerWeekNumberIndex(day.weekNumber)
								].days[day.weekday] = setupCalenderDay(day);
							}

							setWeeks(orderedDays);
						}
					} else {
						addToaster({
							title: 'Fetching Organization',
							text: 'Error fethcing organization',
							type: 'error',
						});
					}
				} catch (error) {
					addToaster({
						title: 'Fetching Organization',
						text: 'Error fethcing organization',
						type: 'error',
					});
				}
			}
		};

		const fetchAccount = async () => {
			try {
				const response = await axios.get(
					`/organizations/${auth?.user?.organization?.id}/account`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);

				if (response?.status === 200) {
					if (isMounted) setAccountDetails(response?.data);
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Organization Account',
					text: 'Noget gik galt...',
					type: 'error',
				});
			}
		};

		fetchOrganization();
		fetchAccount();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [props, auth, addToaster]);

	/**
	 * Setups up the object calender day with the correct values.
	 */
	const setupCalenderDay = (day) => {
		day.update = false;
		day.newValue = null;
		day.deliveryDay = day.swapday === true ? true : false;
		day.newStartDelivery = get(validTimes, ['defaultDeliveryStart'], {});
		day.newEndDelivery = get(validTimes, ['defaultDeliveryEnd'], {});
		day.newStartPickup = get(validTimes, ['defaultPickupStart'], {});
		day.newEndPickup = get(validTimes, ['defaultPickupEnd'], {});
		day.isUpdating = false;

		return day;
	};

	const weekNumberIndex = (number) => {
		return Math.ceil(number / 5) - 1;
	};

	const innerWeekNumberIndex = (number) => {
		return (number - 1) % 5;
	};

	const updateValue = async (day) => {
		if (typeof day.newValue === 'number') {
			try {
				toggleUpdating(day, true);

				const response = await axios.put(
					`/calender-days/value/${day.id}`,
					JSON.stringify({ value: day.newValue }),
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response?.status === 200 && response?.data) {
					const newDay = response.data;
					updateCalenderDay(day, newDay);
					day = newDay;

					addToaster({
						title: 'Updating Day',
						text: 'Successfully updated day',
						type: 'success',
					});
				} else {
					addToaster({
						title: 'Updating Day',
						text: 'Error updating day',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Updating Day',
					text: 'Error updating day',
					type: 'error',
				});
			}
		}

		toggleUpdating(day, false);
	};

	const updateTime = async (day) => {
		let data = {};
		let formError = false;

		const times = get(validTimes, ['times'], {});

		if (day.deliveryDay) {
			// Checking if the format is correct and the start times are before end times.
			if (
				times.includes(day.newStartDelivery) &&
				times.includes(day.newEndDelivery) &&
				times.includes(day.newStartPickup) &&
				times.includes(day.newEndPickup) &&
				times.indexOf(day.newStartDelivery) <
					times.indexOf(day.newEndDelivery) &&
				times.indexOf(day.newStartPickup) <
					times.indexOf(day.newEndPickup)
			) {
				data.swapday = true;
				data.deliveryStart = day.newStartDelivery + ':00.000';
				data.deliveryEnd = day.newEndDelivery + ':00.000';
				data.pickupStart = day.newStartPickup + ':00.000';
				data.pickupEnd = day.newEndPickup + ':00.000';
			} else {
				formError = true;
			}
		} else {
			data.swapday = false;
			data.deliveryStart = null;
			data.deliveryEnd = null;
			data.pickupStart = null;
			data.pickupEnd = null;
		}

		if (!formError) {
			toggleUpdate(day, true);
			try {
				const response = await axios.put(
					`/calender-days/time/${day.id}`,
					JSON.stringify(data),
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response?.status === 200 && response?.data) {
					const newDay = response.data;
					updateCalenderDay(day, newDay);
					day = newDay;
					addToaster({
						title: 'Updating Day',
						text: 'Successfully updated day',
						type: 'success',
					});
				} else {
					addToaster({
						title: 'Updating Day',
						text: 'Error updating day',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Updating Day',
					text: 'Error updating day',
					type: 'error',
				});
			}
			toggleUpdate(day, false);
		} else {
			addToaster({
				title: 'Updating day',
				text: 'Invalid times selected',
				type: 'warning',
			});
		}
	};

	/**
	 * Changes the weeks to display.
	 *
	 * @param {number} index
	 */
	const showWeekInterval = (index) => {
		setSelectedWeeks(index);
	};

	const toggleUpdate = (day, display) => {
		const weekNumIndex = weekNumberIndex(day.weekNumber);
		const inWeekNumIndex = innerWeekNumberIndex(day.weekNumber);
		const dayOfWeek = day.weekday;
		const updatedWeeks = [...weeks];
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[dayOfWeek] = {
			...day,
		};
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
			dayOfWeek
		].update = display;
		setWeeks(updatedWeeks);
	};

	const toggleUpdating = (day, isUpdating) => {
		const weekNumIndex = weekNumberIndex(day.weekNumber);
		const inWeekNumIndex = innerWeekNumberIndex(day.weekNumber);
		const dayOfWeek = day.weekday;
		const updatedWeeks = [...weeks];
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[dayOfWeek] = {
			...day,
		};
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
			dayOfWeek
		].isUpdating = isUpdating;

		setWeeks(updatedWeeks);
	};

	const updateNewValue = (day, value) => {
		const weekNumIndex = weekNumberIndex(day.weekNumber);
		const inWeekNumIndex = innerWeekNumberIndex(day.weekNumber);
		const dayOfWeek = day.weekday;
		const updatedWeeks = [...weeks];
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[dayOfWeek] = {
			...day,
		};
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
			dayOfWeek
		].newValue = value;

		setWeeks(updatedWeeks);
	};

	const updateDeliveryDay = (day, value) => {
		const weekNumIndex = weekNumberIndex(day.weekNumber);
		const inWeekNumIndex = innerWeekNumberIndex(day.weekNumber);
		const dayOfWeek = day.weekday;
		const updatedWeeks = [...weeks];
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[dayOfWeek] = {
			...day,
		};
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
			dayOfWeek
		].deliveryDay = value;

		setWeeks(updatedWeeks);
	};

	/**
	 * Updating the new time value of the day.
	 *
	 * @param {object} day Day to be updated.
	 * @param {string} type what time needs to be updated.
	 * @param {string} time the new time.
	 */
	const updateNewTime = (day, isStart, isPickup, time) => {
		const weekNumIndex = weekNumberIndex(day.weekNumber);
		const inWeekNumIndex = innerWeekNumberIndex(day.weekNumber);
		const dayOfWeek = day.weekday;
		const updatedWeeks = [...weeks];
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[dayOfWeek] = {
			...day,
		};

		if (isStart && !isPickup) {
			updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
				dayOfWeek
			].newStartDelivery = time;
		} else if (!isStart && !isPickup) {
			updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
				dayOfWeek
			].newEndDelivery = time;
		} else if (isStart && isPickup) {
			updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
				dayOfWeek
			].newStartPickup = time;
		} else if (!isStart && isPickup) {
			updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[
				dayOfWeek
			].newEndPickup = time;
		}

		setWeeks(updatedWeeks);
	};

	/**
	 * Replaces the day with the new day information.
	 *
	 * @param {object} day
	 * @param {object} newDay
	 */
	const updateCalenderDay = (day, newDay) => {
		newDay = setupCalenderDay(newDay);

		const weekNumIndex = weekNumberIndex(day.weekNumber);
		const inWeekNumIndex = innerWeekNumberIndex(day.weekNumber);
		const dayOfWeek = day.weekday;
		const updatedWeeks = [...weeks];
		updatedWeeks[weekNumIndex].innerWeek[inWeekNumIndex].days[dayOfWeek] = {
			...newDay,
		};

		setWeeks(updatedWeeks);
	};

	const toggleAddonsPopup = () => {
		setAddonsPopup(!addonsPopup);
	};

	const updateAddress = (address) => {
		let newOrganization = { ...organization };
		newOrganization.address = address;
		setOrganization(newOrganization);
	};

	const updateSwapMargin = async () => {
		if (newSwapMargin && Number.isInteger(newSwapMargin)) {
			setUpdatingMargin(true);

			try {
				const response = await axios.put(
					'/swap-margin',
					JSON.stringify({ swapMargin: newSwapMargin }),
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response.status === 200) {
					addToaster({
						title: 'Opdater booking margen',
						text: 'Opdateret booking margen.',
						type: 'success',
					});
					setSwapMargin(response?.data?.swapMargin);
				}
			} catch (error) {
				addToaster({
					title: 'Opdater booking margen',
					text: 'Noget gik galt i updatering af booking margen.',
					type: 'error',
				});
			}

			setUpdatingMargin(false);
			setEditSwapMargin(false);
		} else {
			addToaster({
				title: 'Opdater booking margen',
				text: 'Angiv booking margen',
				type: 'warning',
			});
		}
	};

	const updateAccount = async () => {
		if (
			newAccountNumber !== undefined &&
			newRegistrationNumber !== undefined
		) {
			setUpdatingAccount(true);

			try {
				const response = await axios.put(
					`/organizations/${auth?.user?.organization?.id}/account`,
					{
						accountNumber: newAccountNumber,
						registrationNumber: newRegistrationNumber,
					},
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);
				if (response?.status === 200) {
					setAccountDetails({
						accountNumber: newAccountNumber,
						registrationNumber: newRegistrationNumber,
					});
					setEditAccount(false);
					addToaster({
						title: 'Opdater konto',
						text: 'Konto blev opdateret.',
						type: 'success',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Opdater konto',
					text: 'Noget gik galt...',
					type: 'error',
				});
			}

			setUpdatingAccount(false);
		} else {
			addToaster({
				title: 'Opdater konto',
				text: 'Angiv både Kontonr. og Reg.nr.',
				type: 'warning',
			});
		}
	};

	const updateBookingMessage = (message) => {
		let newOrganization = { ...organization };
		newOrganization.bookingMessage = message;
		setOrganization(newOrganization);
	};

	const addNewAddon = (addon) => {
		let newOrganization = { ...organization };
		newOrganization.addons.push(addon);
		setOrganization(newOrganization);
	};

	return organization === null || weeks === null ? (
		<div className='vertical-layout'>
			<Loading />
		</div>
	) : (
		<>
			<div className='vertical-layout'>
				<div className='container'>
					<h1 className='header-title'>{organization.name}</h1>
				</div>
				<div className='container calender-header-container'>
					<h1 className='header-title'>Organisationskalender</h1>
					<button
						className='rounded-button'
						onClick={() =>
							setCalenderDisplayValue(!calenderDisplayValue)
						}
					>
						Skift visning
					</button>
				</div>
				<Calender
					weeks={weeks}
					selectedWeeks={selectedWeeks}
					updateValue={updateValue}
					updateTime={updateTime}
					showWeekInterval={showWeekInterval}
					toggleUpdate={toggleUpdate}
					updateNewValue={updateNewValue}
					updateNewTime={updateNewTime}
					updateDeliveryDay={updateDeliveryDay}
					showValue={calenderDisplayValue}
				/>
				<div className='container swap-margin-container'>
					{editSwapMargin ? (
						<>
							<h1 className='header-title'>Booking Margen:</h1>
							<div>
								<input
									type='number'
									onChange={(e) =>
										setNewSwapMargin(
											parseInt(e.target.value)
										)
									}
								></input>
								<LoadButton
									classes='rounded-button update-margin-button'
									action={updateSwapMargin}
									isLoading={updatingMargin}
									text='Opdater'
									color='white'
								/>
							</div>
						</>
					) : (
						<>
							<h1 className='header-title'>
								Booking Margen: {swapMargin} timer
							</h1>
							<div>
								<button
									className='rounded-button'
									onClick={() => {
										setEditSwapMargin(true);
									}}
								>
									Rediger
								</button>
							</div>
						</>
					)}
				</div>
				<div className='container account-container'>
					<div className='account-header-container'>
						<h1>Konto</h1>
						{editAccount ? (
							<LoadButton
								classes='rounded-button'
								action={updateAccount}
								isLoading={updatingAccount}
								text='Opdater'
								color='white'
							/>
						) : (
							<button
								className='rounded-button'
								onClick={() => setEditAccount(true)}
							>
								Rediger
							</button>
						)}
					</div>
					<div className='account-body'>
						{editAccount ? (
							<>
								<div className='account-item'>
									<label htmlFor='registration-number'>
										Reg.nr.
									</label>
									<input
										type='number'
										id='registration-number'
										placeholder='Registreringsnummer'
										value={newRegistrationNumber}
										onChange={(e) =>
											setNewRegistrationNumber(
												e.target.value === ''
													? undefined
													: parseInt(e.target.value)
											)
										}
									/>
								</div>
								<div className='account-item'>
									<label htmlFor='account-number'>
										Kontonr.
									</label>
									<input
										type='number'
										id='account-number'
										placeholder='Kontonummer'
										value={newAccountNumber}
										onChange={(e) =>
											setNewAccountNumber(
												e.target.value === ''
													? undefined
													: parseInt(e.target.value)
											)
										}
									/>
								</div>
							</>
						) : (
							<>
								<div className='account-item'>
									<p>Reg.nr.:</p>
									<span>
										{accountDetails?.registrationNumber
											? accountDetails?.registrationNumber
											: '⚠️MANGLER!⚠️'}
									</span>
								</div>
								<div className='account-item'>
									<p>Kontonr.:</p>
									<span>
										{accountDetails?.accountNumber
											? accountDetails?.accountNumber
											: '⚠️MANGLER!⚠️'}
									</span>
								</div>
							</>
						)}
					</div>
				</div>
				<div className='container address-container'>
					<div className='address-header-container'>
						<h1>Address</h1>
						<button
							className='rounded-button'
							onClick={() => setEditAddress(true)}
						>
							Rediger
						</button>
					</div>
					<div>
						{organization?.address !== null ? (
							<p>
								{organization?.address?.street}{' '}
								{organization?.address?.streetNumber},{' '}
								{organization?.address?.postalCode}{' '}
								{organization?.address?.city}
							</p>
						) : (
							<p>⚠️Mangler address!⚠️</p>
						)}
					</div>
				</div>
				<div className='container booking-message-container'>
					<div className='booking-message-header-container'>
						<h1>Booking Besked</h1>
						<button
							className='rounded-button'
							onClick={() => setEditBookingMessage(true)}
						>
							Rediger
						</button>
					</div>
					<div>
						<p style={{ whiteSpace: 'pre-wrap' }}>
							{organization?.bookingMessage !== null
								? organization?.bookingMessage
								: '⚠️Mangler Besked!⚠️'}
						</p>
					</div>
				</div>
				<div className='container addons-container'>
					<div className='addons-title-container'>
						<h1 className='header-title'>Tilkøb</h1>
						<button
							className='circle-button'
							onClick={toggleAddonsPopup}
						>
							+
						</button>
					</div>
					{organization?.addons?.map((addon, i) => {
						return <Addon addon={addon} key={i} />;
					})}
				</div>
			</div>
			{addonsPopup && (
				<CreateAddons
					toggle={toggleAddonsPopup}
					addAddon={addNewAddon}
				/>
			)}
			{editAddress && (
				<EditAddress
					toggle={() => setEditAddress(false)}
					id={organization?.id}
					updateAddress={updateAddress}
				/>
			)}
			{editBookingMessage && (
				<EditBookingMessage
					toggle={() => setEditBookingMessage(false)}
					id={organization?.id}
					updateBookingMessage={updateBookingMessage}
				/>
			)}
		</>
	);
};
