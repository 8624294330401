import axios from './../../../api/axios';
import { useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import LoadButton from '../../loading/LoadButton';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const NewOrganization = () => {
	const [name, setName] = useState('');
	const [nameError, setNameError] = useState(null);
	const [nameHelp, setNameHelp] = useState(false);
	const [swapMargin, setSwapMargin] = useState('');
	const [swapMarginError, setSwapMarginError] = useState(null);
	const [swapMarginHelp, setSwapMarginHelp] = useState(false);
	const [deposit, setDeposit] = useState('');
	const [depositError, setDepositError] = useState(null);
	const [depositHelp, setDepositHelp] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [creationComplete, setCreationComplete] = useState(false);

	const { auth, setAuth } = useAuth();
	const navigate = useNavigate();

	const handleCreateOrganzation = async () => {
		setIsLoading(true);
		setNameError(null);
		setSwapMarginError(null);
		setError(null);
		setDepositError(null);

		if (name.length < 5)
			setNameError('Navnet skal bestå af mindst 5 tegn.');
		if (!Number.isInteger(swapMargin) || swapMargin <= 0)
			setSwapMarginError('Skal være et heltal større end 0.');
		if (!Number.isInteger(deposit) || deposit < 0)
			setDepositError('Skal være et tal større end eller ligmed 0.');

		if (
			nameError === null &&
			swapMarginError === null &&
			depositError === null
		) {
			try {
				const response = await axios.post(
					'/organizations',
					JSON.stringify({
						name: name,
						swapMargin: swapMargin,
						depositPrice: deposit,
					}),
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response?.status === 200) {
					setCreationComplete(true);
				}
			} catch (error) {
				const err = error.toJSON();
				if (err.status === 400) {
					setNameError('Navnet skal være unikt');
				} else {
					setError(`Noget gik galt... ${err.status}!`);
				}
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
		}
	};

	const completeRedirectHandle = () => {
		setAuth({});
		navigate('/');
	};

	return (
		<>
			<div className='new-organization-layout-container'>
				<div className='new-organization-input-container'>
					<div className='new-organization-inner-input-container'>
						<h1 className='new-organization-title'>
							Opret en ny organisation
						</h1>
						<div className='new-organization-input-form'>
							<div className='new-organization-input-item'>
								<div className='new-organization-input-item-header'>
									<label
										htmlFor='name'
										className='new-organization-input-label'
									>
										Organisationens Navn
									</label>
									<div className='new-organization-input-help-container'>
										<span
											className='new-organzation-input-help'
											onMouseEnter={() =>
												setNameHelp(true)
											}
											onMouseLeave={() =>
												setNameHelp(false)
											}
										>
											?
										</span>
										<div
											className='new-organization-input-help-text-container'
											style={{
												display:
													nameHelp === true
														? 'block'
														: 'none',
											}}
										>
											<p>
												Din organizations navn som alle
												dine autocamperne komme til at
												stå under. Det kan være dit eget
												navn eller noget du kommer op
												med.
											</p>
										</div>
									</div>
								</div>
								<input
									type='text'
									id='name'
									autoComplete='off'
									onChange={(e) => setName(e.target.value)}
									value={name}
									required
									placeholder='Navn'
									className='new-organization-input-field'
								/>
								{nameError !== null && (
									<div className='new-organization-input-error'>
										<p className='new-organization-input-error-text'>
											{nameError}
										</p>
									</div>
								)}
							</div>
							<div className='new-organization-input-item'>
								<div className='new-organization-input-item-header'>
									<label
										htmlFor='swapMargin'
										className='new-organization-input-label'
									>
										Swap Margin
									</label>
									<div className='new-organization-input-help-container'>
										<span
											className='new-organzation-input-help'
											onMouseEnter={() =>
												setSwapMarginHelp(true)
											}
											onMouseLeave={() =>
												setSwapMarginHelp(false)
											}
										>
											?
										</span>
										<div
											className='new-organization-input-help-text-container'
											style={{
												display:
													swapMarginHelp === true
														? 'block'
														: 'none',
											}}
										>
											<p>
												Hvor mange timer der skal gå før
												en booking stopper, til en ny
												skal kunne begynde. Den her tid
												sikre at der er mulighed for at
												rengører autocamperen m.m.,
												inden den næste lejer kommer
												(kan blive ændret senere).
											</p>
										</div>
									</div>
								</div>
								<input
									type='number'
									id='swapMargin'
									autoComplete='off'
									onChange={(e) => {
										setSwapMargin(
											isNaN(parseInt(e.target.value))
												? ''
												: parseInt(e.target.value)
										);
									}}
									value={swapMargin}
									required
									placeholder='Swap Margin'
									className='new-organization-input-field'
								/>
								{swapMarginError !== null && (
									<div className='new-organization-input-error'>
										<p className='new-organization-input-error-text'>
											{swapMarginError}
										</p>
									</div>
								)}
							</div>
							<div className='new-organization-input-item'>
								<div className='new-organization-input-item-header'>
									<label
										htmlFor='deposit'
										className='new-organization-input-label'
									>
										Depositum
									</label>
									<div className='new-organization-input-help-container'>
										<span
											className='new-organzation-input-help'
											onMouseEnter={() =>
												setDepositHelp(true)
											}
											onMouseLeave={() =>
												setDepositHelp(false)
											}
										>
											?
										</span>
										<div
											className='new-organization-input-help-text-container'
											style={{
												display:
													depositHelp === true
														? 'block'
														: 'none',
											}}
										>
											<p>
												Depositummet for alle bookinger
												på alle dine autocampere i DKK
												(kan blive ændret senere).
											</p>
										</div>
									</div>
								</div>
								<input
									type='number'
									id='deposit'
									autoComplete='off'
									onChange={(e) =>
										setDeposit(
											isNaN(parseInt(e.target.value))
												? ''
												: parseInt(e.target.value)
										)
									}
									value={deposit}
									required
									placeholder='Depositum'
									className='new-organization-input-field'
								/>
								{depositError !== null && (
									<div className='new-organization-input-error'>
										<p className='new-organization-input-error-text'>
											{depositError}
										</p>
									</div>
								)}
							</div>
							<div>
								<LoadButton
									classes='new-organization-create-button'
									text='Opret'
									isLoading={isLoading}
									color='white'
									action={handleCreateOrganzation}
								/>
								{error !== null && (
									<p className='new-organization-error-text'>
										{error}
									</p>
								)}
							</div>
							<div>
								<a
									href='https://andreasudlejning.dk'
									className='new-organization-go-back-link'
								>
									Gå tilbage
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className='new-organization-information-container'>
					<div className='new-organzation-information-inner-container'>
						<p className='new-organization-information-title'>
							Opret en organisation i dag, og begynd at udleje
							dine autocampere
						</p>
						<div className='new-organization-information-items-container'>
							<div className='new-organization-information-item'>
								<img
									src='./dollar.svg'
									alt='money'
									className='new-organization-information-item-icon'
								/>
								<p className='new-organization-information-item-text'>
									Tjen penge på udlejninger af dine
									autocampere.
								</p>
							</div>
							<div className='new-organization-information-item'>
								<img
									src='./community.svg'
									alt='community'
									className='new-organization-information-item-icon'
								/>
								<p className='new-organization-information-item-text'>
									Et fælles sted hvor man kan finde
									autocampere til udlejning.
								</p>
							</div>
							<div className='new-organization-information-item'>
								<img
									src='./administration.svg'
									alt='administration'
									className='new-organization-information-item-icon'
								/>
								<p className='new-organization-information-item-text'>
									Nem administration af booking på dine
									autocampere.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{creationComplete && (
				<div className='new-organization-complete-background'>
					<div className='new-organization-complete-container'>
						<h1 className='new-organization-complete-title'>
							Organisation Oprettet
						</h1>
						<p>
							Din organisation er blevet oprette, og din profil er
							opdateret. Det betyder du skal logge ind igen for at
							få adgang til din organisation.
						</p>
						<button
							className='new-organization-button'
							onClick={completeRedirectHandle}
						>
							Okay
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default NewOrganization;
