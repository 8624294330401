const IncomeRowItem = ({ organizationIncome, onClick }) => {
	const { organization, income, incomeRate, paid } = organizationIncome;

	return (
		<div className='incomes-page-income-item'>
			<div className='incomes-page-income-item-detail'>
				<p>{organization?.name}</p>
			</div>
			<div className='incomes-page-income-item-detail'>
				<p>{income.toFixed(2)} DKK</p>
			</div>
			<div className='incomes-page-income-item-detail'>
				<p>{incomeRate * 100}%</p>
			</div>
			<div className='incomes-page-income-item-detail'>
				<p>{(income * incomeRate).toFixed(2)} DKK</p>
			</div>
			<div className='incomes-page-income-item-detail'>
				{paid === true ? (
					<p>Ja</p>
				) : (
					<button
						className='home-calender-header-button'
						onClick={onClick}
					>
						Betal
					</button>
				)}
			</div>
		</div>
	);
};

export default IncomeRowItem;
