import React from 'react';

class AutocamperTag extends React.Component {
	render() {
		const { addToSelectedTags, index } = this.props;

		return (
			<div
				className='autocamper-tag'
				onClick={() => {
					addToSelectedTags(index);
				}}
			>
				<span className='autocamper-tag-name'>
					{this.props.tag.name}
				</span>
			</div>
		);
	}
}

export default AutocamperTag;
