import { useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

export const UpdateAddon = (props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [newName, setNewName] = useState(props.addon.name);
	const [newDescription, setNewDescription] = useState(
		props.addon.description
	);
	const [newPrice, setNewPrice] = useState(props.addon.price);
	const [newPerDay, setNewPerDay] = useState(props.addon.perDay);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const updateAddon = async () => {
		const data = {};
		if (newName !== props.addon.name) data.name = newName;
		if (newDescription !== props.addon.description)
			data.description = newDescription;
		if (newPrice !== props.addon.price && newPrice !== '' && newPrice >= 0)
			data.price = newPrice;
		if (newPerDay !== props.addon.perDay) data.perDay = newPerDay;

		if (data?.name || data?.description || data?.price || data?.perDay) {
			try {
				setIsLoading(true);

				const response = await axios.put(
					`/addons/${props?.addon?.id}`,
					JSON.stringify(data),
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				setIsLoading(false);
				if (response?.status === 200) {
					addToaster({
						title: 'Updating addon',
						text: 'Addon was updated',
						type: 'success',
					});
					props.updateAddon(response?.data);
					props.toggle();
				} else {
					addToaster({
						title: 'Updating addon',
						text: 'Something went wrong updating the addon',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Updating addon',
					text: 'Something went wrong updating the addon',
					type: 'error',
				});
				setIsLoading(false);
			}
		} else {
			addToaster({
				title: 'Updating addon',
				text: 'There was no updates',
				type: 'info',
			});
		}
	};

	return (
		<Popup
			toggle={props.toggle}
			name='Opdatere addon'
			action={updateAddon}
			actionRunning={isLoading}
			buttonText='Opdater'
		>
			<section className='vertical-form'>
				<div>
					<h1>Navn</h1>
					<input
						type='text'
						value={newName}
						onChange={(e) => setNewName(e.target.value)}
					/>
				</div>
				<div>
					<h1>Description</h1>
					<input
						type='text'
						value={newDescription}
						onChange={(e) => setNewDescription(e.target.value)}
					/>
				</div>
				<div>
					<h1>Pris</h1>
					<input
						type='number'
						value={newPrice}
						onChange={(e) => setNewPrice(e.target.value)}
					/>
				</div>
				<div>
					<h1>Betaling</h1>
					<select
						onChange={(e) => setNewPerDay(e.target.value)}
						value={newPerDay}
					>
						<option value={false}>1-gangs</option>
						<option value={true}>Per dag</option>
					</select>
				</div>
			</section>
		</Popup>
	);
};
