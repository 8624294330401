import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../../api/axios';
import Loading from '../../loading/Loading';

import './styles.css';
import { PopularAutocamper } from './PopularAutocamper';
import EditPopular from '../../popup/editpopular';
import CustomChart from './CustomChart';
import utility from '../../../utils/utility';
import useAuth from '../../../hooks/useAuth';
import { useDebounce } from 'use-debounce';
import MissingPayments from './MissingPayments';
import BarChart from './BarChart';
import useToaster from '../../toaster/useToaster';

export const GlobalPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [popular, setPopular] = useState([]);
	const [displayEdit, setDisplayEdit] = useState(false);
	const [isMounted, setIsMounted] = useState(true);
	const [userCounts, setUserCounts] = useState([]);
	const [userCountDates, setUserCountDates] = useState({
		start: null,
		end: null,
	});
	const [debounceUserCountDates] = useDebounce(userCountDates, 1000);
	const [userCountsIsLoading, setUserCountsIsLoading] = useState(false);
	const [missingPayments, setMissingPayments] = useState(null);
	const [missingIsLoading, setMissingIsLoading] = useState(false);
	const [income] = useState([
		{ month: '1/22', income: 2000 },
		{ month: '2/22', income: 3200 },
		{ month: '3/22', income: 1800 },
		{ month: '4/22', income: 4500 },
		{ month: '5/22', income: 4550 },
		{ month: '6/22', income: 8420 },
		{ month: '7/22', income: 3800 },
	]);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const fetchMissingPayments = useCallback(
		async (controller) => {
			try {
				setMissingIsLoading(true);

				const response = await axios('/booking-payment-missings', {
					signal: controller.signal,
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
				});

				if (response?.status === 200) {
					if (isMounted) {
						setMissingPayments(response.data);
					}
				}
				setMissingIsLoading(false);
			} catch (error) {
				addToaster({
					title: 'Fetching Missing Payments',
					text: 'Error fetching missing payments',
					type: 'error',
				});
			}
		},
		[isMounted, auth, addToaster]
	);

	const fetchUserCount = useCallback(
		async (dates, controller) => {
			if (dates.start !== null) {
				try {
					setUserCountsIsLoading(true);
					let url = `/user-counts?from=${utility.YYYYMMDD(
						dates.start
					)}`;
					if (dates.end !== null)
						url += `&to=${utility.YYYYMMDD(dates.end)}`;
					const response = await axios.get(url, {
						signal: controller.signal,
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					});

					setUserCountsIsLoading(false);
					if (response.status === 200) {
						if (isMounted) {
							setUserCounts(
								response.data.map((data) => {
									return {
										id: data.id,
										users: data.users,
										date: new Date(data.date),
									};
								})
							);
						}
					}
				} catch (error) {
					addToaster({
						title: 'Fetching User Count',
						text: 'Error fetching user count',
						type: 'error',
					});
				}
			}
		},
		[isMounted, auth, addToaster]
	);

	const fetchPopular = useCallback(
		async (controller) => {
			try {
				const response = await axios.get('/popular-autocampers', {
					signal: controller.signal,
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
				});

				if (response.status === 200) {
					if (isMounted) {
						setPopular(response.data);
					}
				} else {
					addToaster({
						title: 'Fetching Popular Autocampers',
						text: 'Error fetching popular autocampers',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Popular Autocampers',
					text: 'Error fetching popular autocampers',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		},
		[auth, addToaster, isMounted]
	);

	useEffect(() => {
		const controller = new AbortController();
		fetchUserCount(debounceUserCountDates, controller);
		return () => {
			controller.abort();
		};
	}, [debounceUserCountDates, fetchUserCount]);

	useEffect(() => {
		const today = new Date();
		setUserCountDates({
			start: new Date(
				Date.UTC(
					today.getFullYear(),
					today.getMonth(),
					today.getDate() - 7
				)
			),
			end: today,
		});

		const controller = new AbortController();
		fetchPopular(controller);
		fetchMissingPayments(controller);
		return () => {
			setIsMounted(false);
			controller.abort();
		};
	}, [fetchMissingPayments, fetchPopular]);

	return isLoading ? (
		<Loading />
	) : (
		<>
			<article className='global-layout-container'>
				<section className='global-statistic-layout-container'>
					<CustomChart
						name='Antal Brugere'
						data={userCounts}
						from={userCountDates.start}
						setFrom={(x) =>
							setUserCountDates({
								start: x,
								end: userCountDates.end,
							})
						}
						to={userCountDates.end}
						setTo={(x) =>
							setUserCountDates({
								start: userCountDates.start,
								end: x,
							})
						}
						x='date'
						y='users'
						label='Users'
						isLoading={userCountsIsLoading}
					/>
					<BarChart
						name={'Månedlig Indkomst'}
						isLoading={false}
						data={income}
					/>
					<MissingPayments
						missingPayments={missingPayments}
						isLoading={missingIsLoading}
					/>
				</section>
				<section className='global-container popular-autocampers-container'>
					<div className='popular-autocamper-title-container'>
						<h1 className='popular-autocamper-title'>
							Populærer Autocampere
						</h1>
						<button
							className='popular-autocamper-edit-button'
							onClick={() => setDisplayEdit(true)}
						>
							Rediger
						</button>
					</div>
					<div className='global-popular-autocampers-container'>
						<div className='global-popular-autocampers-inner-container'>
							{popular.map((autocamper, i) => {
								return (
									<PopularAutocamper
										key={i}
										autocamper={autocamper.autocamper}
									/>
								);
							})}
						</div>
					</div>
				</section>
			</article>
			{displayEdit && (
				<EditPopular
					updatePopularList={fetchPopular}
					toggle={() => setDisplayEdit(false)}
				/>
			)}
		</>
	);
};
