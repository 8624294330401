import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import utility from '../../../utils/utility';
import ActiveTag from './ActiveTag';
import { ErrorHandling } from '../../error/ErrorHandling';
import Loading from '../../loading/Loading';
import UpdateAutocamper from '../../popup/UpdateAutocamper';
import useToaster from '../../toaster/useToaster';

import './styles.css';

const AutocamperPage = () => {
	const { auth } = useAuth();
	const { id } = useParams();
	const { addToaster } = useToaster();

	const [autocamper, setAutocamper] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [errorCode, setErrorCode] = useState(null);
	const [imageIndex, setImageIndex] = useState(0);
	const [displayUpdate, setDisplayUpdate] = useState(false);

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const fetchAutocamper = async () => {
			try {
				const response = await axios.get(`autocampers/${id}/admin`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response?.status === 200 && response?.data) {
					if (isMounted) setAutocamper(response.data);
				} else {
					setErrorCode(response.status);
					addToaster({
						title: 'Fetching Autocamper',
						text: 'Error fetching autocamper',
						type: 'error',
					});
				}
			} catch (error) {
				const err = error.toJSON();
				setErrorCode(err.status);
				addToaster({
					title: `Fetching Autocamper: ${err.status}`,
					text: 'Error fetching autocamper',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		};

		fetchAutocamper();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [id, auth, addToaster]);

	return isLoading ? (
		<Loading />
	) : errorCode !== null ? (
		<ErrorHandling status={errorCode} />
	) : (
		<>
			<div className='autocamper-page-layout'>
				<div className='container autocamper-container autocamper-text-container'>
					<div>
						<div className='autocamper-page-autocamper-title-container'>
							<h1 className='autocamper-page-autocamper-title'>
								{autocamper?.name}
							</h1>
							<ActiveTag active={autocamper.active} />
						</div>
						<h2>
							{autocamper?.year
								? autocamper.year
								: 'Ingen årgang'}
						</h2>
						<p className='autocamper-page-autocamper-description'>
							{autocamper?.description &&
							autocamper.description.length > 0
								? autocamper.description
								: 'Ingen beskrivelse'}
						</p>
					</div>
					<div>
						<button
							onClick={() => setDisplayUpdate(true)}
							className='autocamper-update-button'
						>
							Opdater Autocamper
						</button>
					</div>
				</div>
				<div className='container autocamper-container autocamper-price-container'>
					<div>
						<p className='autocamper-price-title'>Pris</p>
						<p className='autocamper-price-text'>
							{autocamper?.price?.toFixed(2)} DKK
						</p>
					</div>
					<div>
						<p className='autocamper-price-title'>Startpris</p>
						<p className='autocamper-price-text'>
							{autocamper?.startPrice?.toFixed(2)} DKK
						</p>
					</div>
				</div>
				<div className='container autocamper-container autocamper-image-container'>
					<div className='autocamper-page-images-container'>
						<div>
							<h1>Avatar</h1>
							<div className='autocamper-page-image-container'>
								<img
									src={utility.getAutocamperImage(
										autocamper?.avatar[0]
									)}
									className='autocamper-page-image'
									alt='avatar of the autocamper'
								/>
							</div>
						</div>
						<div>
							<h1>
								Billeder:{' '}
								{autocamper?.images !== null
									? autocamper.images.length
									: 0}
							</h1>
							<div className='autocamper-page-image-container'>
								{autocamper?.images.length === 0 ? (
									<p>Ingen billeder</p>
								) : (
									<>
										<button
											onClick={() => {
												let prevIndex = imageIndex - 1;
												if (prevIndex < 0)
													prevIndex =
														autocamper.images
															.length - 1;

												setImageIndex(prevIndex);
											}}
											className='autocamper-page-image-direction-button'
										>
											<img
												src='/arrow-left.svg'
												alt='arrow right'
												className='autocamper-page-image-direction-icon'
											/>
										</button>
										<img
											src={utility.getAutocamperImage(
												autocamper?.images[imageIndex]
											)}
											className='autocamper-page-image'
											alt='avatar of the autocamper'
										/>
										<button
											onClick={() => {
												let nextIndex = imageIndex + 1;
												if (
													nextIndex >=
													autocamper.images.length
												)
													nextIndex = 0;
												setImageIndex(nextIndex);
											}}
											className='autocamper-page-image-direction-button'
										>
											<img
												src='/arrow-left.svg'
												alt='arrow right'
												className='autocamper-page-image-direction-icon autocamper-page-image-direction-icon-right'
											/>
										</button>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className='container autocamper-container autocamper-addons-container'>
					<h1>Tilkøb</h1>

					{autocamper?.addons.length === 0 ? (
						<p>Ingen tilkøb</p>
					) : (
						<>
							<div className='autocamper-addon-item autocamper-addon-item-header'>
								<p>ID</p>
								<p>Navn</p>
								<p>Pris</p>
								<p>Per dag?</p>
							</div>
							{autocamper?.addons.map((addon, i) => {
								return (
									<div
										className='autocamper-addon-item'
										key={i}
									>
										<div>
											<Link
												to={`/organization/addon/${addon?.id}`}
												className='autocamper-addon-text'
											>
												{addon?.id}
											</Link>
										</div>
										<p>{addon?.name}</p>
										<p>{addon?.price.toFixed(2)} DKK</p>
										<p>
											{addon?.perDay === true
												? 'Per dag'
												: 'Enkelt beløb'}
										</p>
									</div>
								);
							})}
						</>
					)}
				</div>
				<div className='container autocamper-container autocamper-tags-container'>
					<h1>Tags</h1>
					<div className='autocamper-tags-list'>
						{autocamper?.autocamper_tags.length === 0 ? (
							<p>Ingen tags</p>
						) : (
							autocamper.autocamper_tags.map((tag, i) => {
								return (
									<div
										className='autocamper-tag-item'
										key={i}
									>
										<p>{tag?.name}</p>
										{tag?.value && <p>{tag?.value}</p>}
									</div>
								);
							})
						)}
					</div>
				</div>
			</div>
			{displayUpdate === true && (
				<UpdateAutocamper
					toggle={() => setDisplayUpdate(false)}
					autocamper={autocamper}
					setAutocamper={setAutocamper}
				/>
			)}
		</>
	);
};

export default AutocamperPage;
