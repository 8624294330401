const LoadingIcon = ({ className }) => {
	let loadingClasses = 'loading';

	if (className !== undefined) {
		loadingClasses += ' ' + className;
	}
	return (
		<img
			src='/loading-green.svg'
			alt='loading'
			className={loadingClasses}
		/>
	);
};

export default LoadingIcon;
