import { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const PublicStructure = ({ children, headerBackground }) => {
	const [displayMobileDropdown, setDisplayMobileDropdown] = useState(false);

	const headerClass =
		headerBackground === 'green'
			? 'public-header-account-container-green'
			: 'public-header-account-container-white';
	const burgerIcon =
		headerBackground === 'green' ? './burger.svg' : './burger-black.svg';

	return (
		<div className='public-body'>
			<header className='public-header'>
				<div className='public-header-link-container'>
					<Link to='/' className='public-header-logo-wrapper'>
						<img
							src='./small-logo.png'
							className='public-header-logo'
						/>
					</Link>
					<a
						href='https://andreasudlejning.dk'
						className='public-header-link'
					>
						Andreas Udlejning
					</a>
					<Link to='#' className='public-header-link'>
						Hjælp
					</Link>
				</div>
				<div
					className={'public-header-account-container ' + headerClass}
				>
					<div className='public-header-burger-button-wrapper'>
						<button
							className='public-header-burger-button'
							onClick={() =>
								setDisplayMobileDropdown(!displayMobileDropdown)
							}
						>
							<img
								src={burgerIcon}
								className='public-header-burger-icon'
							/>
						</button>
						{displayMobileDropdown && (
							<header className='public-header-mobile-dropdown-container'>
								<Link
									to='/login'
									className='public-header-mobile-dropdown-link'
								>
									Log ind
								</Link>
								<Link
									to='/signup'
									className='public-header-mobile-dropdown-link'
								>
									Opret
								</Link>
								<a
									href='https://andreasudlejning.dk'
									className='public-header-mobile-dropdown-link'
								>
									Andreas Udlejning
								</a>
								{/* <Link
									to='#'
									className='public-header-mobile-dropdown-link'
								>
									Information
								</Link>
								<Link
									to='#'
									className='public-header-mobile-dropdown-link'
								>
									Hjælp
								</Link> */}
							</header>
						)}
					</div>
					<Link
						to='/signup'
						className='public-header-link public-header-link-create'
					>
						Opret
					</Link>
					<Link
						to='/login'
						className='public-header-link public-header-link-login'
					>
						Log ind
					</Link>
				</div>
			</header>

			{children}
		</div>
	);
};

export default PublicStructure;
