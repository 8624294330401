import React, { useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

const BookingDeposit = ({ toggle, id, depositAmount, setRefund }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [lessorFund, setLessorFund] = useState(0);
	const [userFund, setUserFund] = useState(depositAmount);
	const [depositDescription, setDepositDescription] = useState('');

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const createDepositRefund = async () => {
		setIsLoading(true);
		try {
			const response = await axios.post(
				`/deposit-refunds/${id}`,
				{
					lessorFund: lessorFund,
					userFund: userFund,
					description: depositDescription,
				},
				{
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
				}
			);

			if (response?.status === 200) {
				addToaster({
					title: 'Opret depositum',
					text: 'Opret depositumrefundering for booking',
					type: 'success',
				});
				setRefund(response?.data);
				toggle();
			}
		} catch (error) {
			addToaster({
				title: 'Opret depositum',
				text: 'Opret depositumrefundering for booking',
				type: 'error',
			});
			setIsLoading(false);
		}
	};

	const handleDepositAmount = (price, isLessorFund) => {
		const thisPrice = price < depositAmount ? price : depositAmount;
		const otherPrice = depositAmount - thisPrice;
		if (isLessorFund) {
			setLessorFund(thisPrice);
			setUserFund(otherPrice);
		} else {
			setUserFund(thisPrice);
			setLessorFund(otherPrice);
		}
	};

	return (
		<Popup
			toggle={toggle}
			name='Depositumrefundering'
			actionRunning={isLoading}
			action={createDepositRefund}
			buttonText='Opret'
		>
			<section className='vertical-form'>
				<div className='deposit-refund-create-field-item'>
					<h1>Depositummet</h1>
					<p>
						I 'udlejerens andel' skrives mængden af depositummet som
						skal gå til udlejeren selv (Lejerens andel bliver
						udregnet automatisk). Beløbet kan ikke overgå
						depositummet.
					</p>
					<div>
						<label htmlFor='deposit-lessor'>
							Udlejerens andel (DKK):{' '}
						</label>
						<input
							type='number'
							id='deposit-lessor'
							placeholder='Udlejeren'
							value={lessorFund}
							onChange={(e) =>
								handleDepositAmount(
									parseFloat(e.target.value),
									true
								)
							}
						/>
					</div>
					<div>
						<label htmlFor='deposit-user'>
							Lejerens andel (DKK):{' '}
						</label>
						<input
							type='number'
							id='deposit-user'
							placeholder='Lejeren'
							value={userFund}
							onChange={(e) =>
								handleDepositAmount(
									parseFloat(e.target.value),
									false
								)
							}
						/>
					</div>
				</div>
				<div className='deposit-refund-create-field-item'>
					<h1>Depositumsbeskrivelse</h1>
					<p>
						En beskrivelse af hvorfor depositumsprisen er som den
						er. Beskrivelsen bliver sendt som en forklaring til
						lejeren for depositummet.
					</p>
					<textarea
						className='create-note-text-area'
						placeholder='Depositummets beskrivelse'
						onChange={(e) => setDepositDescription(e.target.value)}
					/>
				</div>
			</section>
		</Popup>
	);
};

export default BookingDeposit;
