import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ allowedRoles }) => {
	const { auth } = useAuth();
	const location = useLocation();

	return allowedRoles?.includes(auth?.user?.role?.type) ? ( // User is allowed
		<Outlet />
	) : auth?.user?.confirmed === true ? ( // User is unauthorized to location
		<Navigate to='/unauthorized' state={{ from: location }} replace />
	) : (
		// User isn't logged in.
		<Navigate to='/login' state={{ from: location }} replace />
	);
};

export default RequireAuth;
