import { useEffect, useState } from 'react';
import Loading from '../loading/Loading';
import { ErrorHandling } from '../error/ErrorHandling';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { get } from 'lodash';
import autocamperTags from './tags.json';
import fileOperator from '../../utils/fileoperator';
import useToaster from '../toaster/useToaster';
import LoadingIcon from '../loading/LoadingIcon';
import { Carousel } from 'react-responsive-carousel';

const UpdateAutocamperField = ({
	show,
	setShow,
	name,
	placeholder,
	type,
	prevValue,
	value,
	setValue,
	autocamper,
}) => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [errorCode, setErrorCode] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const fetchData = async () => {
			const fetchTypes = ['addons'];
			const getTypes = ['tags'];

			if (fetchTypes.includes(type)) {
				try {
					let url = '/addons';
					const response = await axios.get(url, {
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
						signal: controller.signal,
					});

					if (response?.status === 200)
						if (isMounted) setData(response.data);
				} catch (error) {
					if (error?.message !== 'canceled') {
						const err = error.toJSON();
						setErrorCode(err.status);
					} else {
						addToaster({
							title: 'Fetching addons',
							text: 'Error fetching addons',
							type: 'error',
						});
					}
				}
			} else if (getTypes.includes(type)) {
				const values = get(autocamperTags, ['tags']);
				if (isMounted) setData(values);
			}

			if (isMounted) setIsLoading(false);
		};

		fetchData();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [type, autocamper, auth, addToaster]);

	const setImagesEvent = async (e, oneImage = true) => {
		setErrorCode(null);
		if (e.target.files && e.target.files[0]) {
			const fileCount = e.target.files.length;
			const files = e.target.files;
			let images = [];

			let invalidFileCount = 0;

			function readFile(index) {
				if (index < fileCount) {
					let file = files[index];
					fileOperator.resizeInputFile(
						file,
						(dataUrl) => {
							file.url = dataUrl;
							images.push(file);
							readFile(index + 1);
						},
						() => {
							invalidFileCount++;
						}
					);
				} else {
					setIsLoading(false);
					setValue(images);
					if (invalidFileCount > 0) {
						addToaster({
							title: 'Upload Files',
							text: `${invalidFileCount} file(s) were invalid, and therefore not included. Might bot have been an image.`,
							type: 'error',
						});
						setErrorMessage(
							`${invalidFileCount} filer er ugyldige. Må maximal være 2MB per fil `
						);
					}
				}
			}

			if (fileCount <= fileOperator.getMaxNumberOfFiles()) {
				setIsLoading(true);
				readFile(0);
			} else {
				addToaster({
					title: 'Upload Files',
					text: `The maximum number of files is ${fileOperator.getMaxNumberOfFiles()}`,
					type: 'warning',
				});
				setErrorMessage(
					`${invalidFileCount} filer er ugyldige. Må maximal være 2MB per fil `
				);
			}
		}
		/*
		if (e.target.files && e.target.files[0]) {
			const fileCount = e.target.files.length;
			const files = e.target.files;
			let images = [];

			let invalidFileCount = 0;

			const readFile = (index) => {
				if (index < fileCount) {
					let file = files[index];
					if (fileOperator.validateFile(file)) {
						const reader = new FileReader();
						reader.onload = (e) => {
							file.url = e.target.result;
							images.push(file);
							readFile(index + 1);
						};
						reader.readAsDataURL(e.target.files[index]);
					} else {
						invalidFileCount++;
						readFile(index + 1);
					}
				}
			};

			if (fileCount <= fileOperator.getMaxNumberOfFiles()) {
				readFile(0);
				setValue(images);
				if (invalidFileCount > 0)
					setErrorMessage(
						`${invalidFileCount} filer er ugyldige. Må maximal være 2MB per fil `
					);
			} else {
				setErrorMessage(
					`For mange filer. Maximum antal filer er ${fileOperator.getMaxNumberOfFiles()}.`
				);
			}
		}*/
	};

	return (
		<div className='update-autocamper-field-item'>
			<div className='update-autocamper-field-header'>
				<h1>{name}</h1>
				<input
					type='checkbox'
					checked={show}
					value={show}
					onChange={(e) => {
						setShow(!show);
					}}
				/>
			</div>
			{show === true && (
				<div className='update-autocamper-field-body'>
					<div className='update-autocamper-field-current'>
						<p className='update-autocamper-field-current-text'>
							Nuværende:{' '}
						</p>
						{type === 'tags' ? (
							<div className='update-autocamper-current-tags-list'>
								{prevValue.length === 0 ? (
									<p>Ingen tags</p>
								) : (
									prevValue.map((tag, i) => {
										return (
											<div
												className='update-autocamper-tag-item autocamper-tag-item'
												key={i}
											>
												<p>{tag?.name}</p>
												{tag?.value && (
													<p>{tag.value}</p>
												)}
											</div>
										);
									})
								)}
							</div>
						) : type === 'addons' ? (
							<div className='update-autocamper-addon-list'>
								{prevValue.length === 0 ? (
									<p>Ingen tilkøb</p>
								) : (
									prevValue.map((addon, i) => {
										return (
											<div
												className='update-autocamper-addon-item'
												key={i}
											>
												<p>{addon?.name}</p>
												<p>
													{addon?.price.toFixed(2)}{' '}
													DKK
												</p>
												<p>
													{addon?.perDay === true
														? 'Per dag'
														: 'Enkelt beløb'}
												</p>
											</div>
										);
									})
								)}
							</div>
						) : type === 'file' ? (
							<span>File</span>
						) : type === 'files' ? (
							<span>Files</span>
						) : (
							<span>{prevValue}</span>
						)}
					</div>
					<div className='update-autocamper-field-new'>
						{isLoading ? (
							<div className='update-autocamper-field-loading'>
								<LoadingIcon className='update-autocamper-field-loading-icon' />
							</div>
						) : errorCode !== null ? (
							<ErrorHandling status={errorCode} />
						) : type === 'tags' ? (
							<>
								<p>Vælg alle tilkøb som den skal have.</p>
								<div className='update-autocamper-current-tags-list'>
									{data.map((tag, i) => {
										return value.some((v) => {
											return v.name === tag.name;
										}) ? (
											<div
												className='update-autocamper-tag-item autocamper-tag-item'
												key={i}
											>
												<p>{tag?.name}</p>
												{tag.type === 'enum' && (
													<select
														onChange={(e) =>
															setValue(
																tag,
																e.target.value
															)
														}
													>
														{tag.values.map(
															(value, i) => {
																return (
																	<option
																		value={
																			value
																		}
																		key={i}
																	>
																		{value}
																	</option>
																);
															}
														)}
													</select>
												)}
												{tag.type === 'number' && (
													<input
														type='number'
														placeholder={tag?.unit}
														required={true}
														onChange={(e) =>
															setValue(
																tag,
																parseFloat(
																	e.target
																		.value
																)
															)
														}
													/>
												)}
												<button
													onClick={() =>
														setValue(tag)
													}
												>
													X
												</button>
											</div>
										) : (
											<div
												className='update-autocamper-tag-item autocamper-tag-item update-autocamper-tag-item-unselected'
												key={i}
												onClick={() => setValue(tag)}
											>
												<p>{tag?.name}</p>
											</div>
										);
									})}
								</div>
							</>
						) : type === 'addons' ? (
							<>
								<p>Vælg alle tilkøb som den skal have.</p>
								<div className='update-autocamper-addon-list'>
									{data.map((addon, i) => {
										return (
											<div
												className={
													value.includes(addon)
														? 'update-autocamper-addon-item'
														: 'update-autocamper-addon-item-unselected'
												}
												key={i}
												onClick={() => setValue(addon)}
											>
												<p>{addon?.name}</p>
												<p>
													{addon?.price.toFixed(2)}{' '}
													DKK
												</p>
												<p>
													{addon?.perDay === true
														? 'Per dag'
														: 'Enkelt beløb'}
												</p>
											</div>
										);
									})}
								</div>
							</>
						) : type === 'file' ? (
							<>
								<input
									type='file'
									name='images'
									accept='image/*'
									onChange={(e) => setImagesEvent(e)}
								/>
								{value !== null ? (
									<img
										className='autocamper-profile-image'
										src={value[0]?.url}
										alt='Autocamper Avatar'
									/>
								) : (
									errorMessage !== null && (
										<p>{errorMessage}</p>
									)
								)}
							</>
						) : type === 'files' ? (
							<>
								<input
									type='file'
									name='images'
									multiple
									accept='image/*'
									onChange={(e) => setImagesEvent(e, false)}
								/>
								{value !== null ? (
									<Carousel
										dynamicHeight={true}
										showThumbs={false}
										infiniteLoop={true}
									>
										{value.map((image, i) => {
											return (
												<img
													key={i}
													className='autocamper-upload-image'
													src={image.url}
													alt='Autocamper Avatar'
												/>
											);
										})}
									</Carousel>
								) : (
									errorMessage !== null && (
										<p>{errorMessage}</p>
									)
								)}
							</>
						) : type === 'textarea' ? (
							<textarea
								placeholder={placeholder}
								value={value}
								onChange={(e) => setValue(e.target.value)}
							/>
						) : type === 'boolean' ? (
							<>
								<p>{name}?</p>
								<input
									type='checkbox'
									value={value}
									checked={value}
									onChange={(e) => {
										setValue(e.target.value !== 'true');
									}}
								/>
							</>
						) : (
							<input
								type={type}
								value={value}
								onChange={(e) => setValue(e.target.value)}
								placeholder={placeholder}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default UpdateAutocamperField;
