import React from 'react';

const SignupInput = ({
	name,
	type,
	value,
	setValue,
	errorText,
	placeholder,
}) => {
	return (
		<div className='signup-page-signup-input-container'>
			<label htmlFor={'signup-input-' + name}>{name}</label>
			<input
				className='signup-page-input'
				id={'signup-input-' + name}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>
			{errorText && (
				<span className='signup-page-input-error'>{errorText}</span>
			)}
		</div>
	);
};

export default SignupInput;
