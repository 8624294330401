import React from 'react';

const SignupProgressItem = ({ title, progress, currentProgress }) => {
	let className = 'signup-page-signup-progress';
	if (currentProgress === progress) {
		className += ' signup-page-signup-progress-current';
	} else if (progress < currentProgress) {
		className += ' signup-page-signup-progress-complete';
	}

	return (
		<div className='signup-page-signup-progress-item'>
			<div className={className}>
				<span>{progress + 1}</span>
			</div>
			<p className='signup-page-signup-progress-title'>{title}</p>
		</div>
	);
};

export default SignupProgressItem;
