import React from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryTheme } from 'victory';
import utility from '../../../utils/utility';

const CustomChart = ({
	name,
	data,
	from,
	setFrom,
	to,
	setTo,
	x,
	y,
	label,
	isLoading,
}) => {
	return (
		<div className='global-container global-statistic-container global-users-container'>
			<h1 className='global-statistic-title'>{name}</h1>
			<VictoryChart
				theme={VictoryTheme.material}
				width={450}
				height={200}
				padding={{
					top: 0,
					right: 20,
					bottom: 30,
					left: 70,
				}}
			>
				<VictoryArea
					data={isLoading ? [] : data}
					x={x}
					y={y}
					style={{ data: { fill: '#2d7163' } }}
				/>
				<VictoryAxis
					tickFormat={(x) => {
						const date = new Date(x);
						return `${date.getUTCDate()}/${
							date.getUTCMonth() + 1
						}-${date.getUTCFullYear()}`;
					}}
				/>
				<VictoryAxis
					dependentAxis
					tickFormat={(x) => `${x} ${label}`}
				/>
			</VictoryChart>
			<div className='global-statistic-selection-container'>
				<div className='global-statistic-selection'>
					<label>Fra</label>
					<input
						type='date'
						onChange={(e) => {
							const newDate = new Date(e.target.value);
							let today = new Date();
							today = new Date(
								today.getUTCFullYear(),
								today.getUTCMonth(),
								today.getUTCDate()
							);

							if (
								newDate.getTime() < today.getTime() &&
								(to === null ||
									newDate.getTime() < to.getTime())
							) {
								setFrom(newDate);
							}
						}}
						value={from === null ? '' : utility.YYYYMMDD(from)}
					/>
				</div>
				<div className='global-statistic-selection'>
					<label>Til</label>
					<input
						type='date'
						onChange={(e) => {
							const newDate = new Date(e.target.value);
							if (
								from === null ||
								newDate.getTime() > from.getTime()
							) {
								setTo(newDate);
							}
						}}
						value={to === null ? '' : utility.YYYYMMDD(to)}
					/>
				</div>
			</div>
		</div>
	);
};

export default CustomChart;
