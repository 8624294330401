import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import Loading from '../../loading/Loading';
import { useState, useEffect } from 'react';
import { DeleteObject } from '../../popup/DeleteObject';
import { ErrorHandling } from '../../error/ErrorHandling';
import { UpdateAddon } from '../../popup/UpdateAddon';
import useToaster from '../../toaster/useToaster';

const AddonPage = (props) => {
	const navigate = useNavigate();
	const { auth } = useAuth();
	const { id } = useParams();
	const { addToaster } = useToaster();

	const [addon, setAddon] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [displayDelete, setDisplayDelete] = useState(false);
	const [displayUpdate, setDisplayUpdate] = useState(false);
	const [deleteIsLoading, setDeleteIsLoading] = useState(false);
	const [errorCode, setErrorCode] = useState(null);

	useEffect(() => {
		var isMounted = true;
		const controller = new AbortController();

		const fetchAddon = async () => {
			try {
				const response = await axios.get(`/addons/${id}`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response?.status === 200 && response?.data) {
					if (isMounted) setAddon(response.data);
				} else {
					setErrorCode(response.status);
					addToaster({
						title: 'Fetching Addon',
						text: 'Error fetching addon',
						type: 'error',
					});
				}
			} catch (error) {
				const err = error.toJSON();
				setErrorCode(err.status);
				addToaster({
					title: 'Fetching Addon',
					text: 'Error fetching addon',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		};

		fetchAddon();

		return () => {
			isMounted = false;
		};
	}, [props, id, auth, addToaster]);

	const deleteAddon = async () => {
		setDeleteIsLoading(true);

		try {
			const response = await axios.delete(`/addons/${id}`, {
				headers: {
					Authorization: `Bearer ${auth?.jwt}`,
				},
			});

			if (response?.status === 200) {
				addToaster({
					title: 'Delete Addon',
					text: 'Addon was successfully deleted',
					type: 'success',
				});
				navigate(-1, { replace: true });
			} else {
				addToaster({
					title: 'Delete Addon',
					text: 'Error deleting addon',
					type: 'error',
				});
			}
		} catch (error) {
			addToaster({
				title: 'Delete Addon',
				text: 'Error deleting addon',
				type: 'error',
			});
		} finally {
			setDeleteIsLoading(false);
		}
	};

	return isLoading ? (
		<Loading />
	) : !addon?.id ? (
		<ErrorHandling status={errorCode} />
	) : (
		<>
			<div className='vertical-layout'>
				<div className='container addon-option-container'>
					<button
						className='addon-update-button'
						onClick={() => setDisplayUpdate(true)}
					>
						Opdater
					</button>
					<button
						className='addon-delete-button'
						onClick={() => setDisplayDelete(true)}
					>
						Slet
					</button>
				</div>
				<div className='container'>
					<h1 className='header-title'>Navn: {addon.name}</h1>
				</div>
				<div className='container'>
					<h1 className='header-title'>
						Beskrivelse: {addon.description}
					</h1>
				</div>
				<div className='container'>
					<h1 className='header-title'>
						Pris: {parseFloat(addon.price).toFixed(2)} kr
					</h1>
				</div>
				<div className='container'>
					<h1 className='header-title'>
						Betaling: {addon.perDay ? 'Per dag' : '1-gangs'}
					</h1>
				</div>
			</div>
			{displayDelete && (
				<DeleteObject
					toggle={() => setDisplayDelete(false)}
					name='Slet Addon'
					action={() => deleteAddon()}
					isLoading={deleteIsLoading}
				/>
			)}
			{displayUpdate && (
				<UpdateAddon
					toggle={() => setDisplayUpdate(false)}
					addon={addon}
					updateAddon={setAddon}
				/>
			)}
		</>
	);
};

export default AddonPage;
