import { Link } from 'react-router-dom';
import utility from '../../../utils/utility';

const DepositRow = ({ refund, clickHandler }) => {
	return (
		<div className='deposit-row-item'>
			<div className='deposit-row-column-id deposit-row-column'>
				<Link
					className='home-link-text'
					to={`/booking/${refund?.booking_payment?.calender_object}`}
				>
					{utility.getIdFormat(refund?.booking_payment?.id)}
				</Link>
			</div>
			{refund?.lessorFund !== null ? (
				<>
					<div className='deposit-row-column-lessor deposit-row-column'>
						<p>
							Udlejer: {refund?.lessorFund.toFixed(2)} DKK{' '}
							{refund?.lessorPaid ? (
								<span className='deposit-row-deposit-paid'>
									Betalt
								</span>
							) : (
								<span className='deposit-row-deposit-not-paid'>
									Ikke Betalt
								</span>
							)}
						</p>
					</div>
					<div className='deposit-row-column-user deposit-row-column'>
						<p>
							Lejer: {refund?.userFund.toFixed(2)} DKK{' '}
							{refund?.userPaid ? (
								<span className='deposit-row-deposit-paid'>
									Betalt
								</span>
							) : (
								<span className='deposit-row-deposit-not-paid'>
									Ikke Betalt
								</span>
							)}
						</p>
					</div>
					<div className='deposit-row-column-action deposit-row-column'>
						<button
							className='deposit-row-edit-button'
							onClick={() => clickHandler()}
						>
							Rediger
						</button>
					</div>
				</>
			) : (
				<div className='deposit-row-column'>
					<span className='deposit-row-deposit-not-created'>
						Ikke oprettet endnu.
					</span>
				</div>
			)}
		</div>
	);
};

export default DepositRow;
