import React from 'react';
import axios from 'axios';
import useAuth from '../../../../hooks/useAuth';
import CalenderItem from './calenderitem';
import CalenderObject from './calenderobject';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './styles.css';
import utility from '../../../../utils/utility';
import Loading from '../../../loading/Loading';
import useToaster from '../../../toaster/useToaster';

const Calender = (props) => {
	const { auth } = useAuth();
	const { id } = useParams();
	const { addToaster } = useToaster();

	const [month, setMonth] = useState(() => {
		const today = new Date();
		return new Date(today.getFullYear(), today.getMonth());
	});
	const [firstDayOnCalender, setFirstDayOnCalender] = useState(null);
	const [lastDayOnCalender, setLastDayOnCalender] = useState(null);
	const [calender, setCalender] = useState(null);

	useEffect(() => {
		/*
		 * Redo to get the GET parameters instead.
		 */
		if (
			props.hasOwnProperty('month') &&
			Number.isInteger(props.year) &&
			props.hasOwnProperty('year') &&
			Number.isInteger(props.year)
		) {
			setMonth(new Date(props.month, props.year));
		}
	}, [props]);

	useEffect(() => {
		const updateCalender = async () => {
			const calender = {
				calenderDays: [],
				calenderObjects: [],
			};

			const selectedMonth = month.getMonth();
			const selectedYear = month.getFullYear();

			const calenderDays = [];

			const daysInMonth = new Date(
				selectedYear,
				selectedMonth + 1,
				0
			).getDate();

			const lastDayPreviousMonth = new Date(
				selectedYear,
				selectedMonth,
				0
			);
			const extraDaysStart = lastDayPreviousMonth.getDay();
			const daysLastMonth = lastDayPreviousMonth.getDate();

			// Setting the first day on the calender as the first day of the month.
			// If there is any starting days on the calender before the month it will be overwritten later.
			setFirstDayOnCalender(month);

			for (let i = 0; i < extraDaysStart; i++) {
				const date = new Date(
					selectedYear,
					selectedMonth - 1,
					daysLastMonth - extraDaysStart + i + 1
				);

				calenderDays.push({
					date: date,
					type: 'out',
				});

				// Setting the first day on the calender.
				if (i === 0) {
					setFirstDayOnCalender(date);
				}
			}

			for (let i = 1; i <= daysInMonth; i++) {
				const date = new Date(selectedYear, selectedMonth, i);
				calenderDays.push({
					date: date,
					type: 'in',
				});

				if (i === daysInMonth) setLastDayOnCalender(date);
			}

			const extraDaysEnd = 6 - ((daysInMonth + extraDaysStart - 1) % 7);
			for (let i = 1; i <= extraDaysEnd; i++) {
				const date = new Date(selectedYear, selectedMonth + 1, i);
				calenderDays.push({
					date: date,
					type: 'out',
				});

				if (i === extraDaysEnd) setLastDayOnCalender(date);
			}

			// Setting calender days.
			calender.calenderDays = calenderDays;

			// Get calender month.
			try {
				const response = await axios.get(
					process.env.REACT_APP_API_URL +
						'/calender-objects/' +
						id +
						`/monthly?year=${selectedYear}&month=${
							selectedMonth + 1
						}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);

				if (response.status === 200) {
					// Changes the start and end fields to Date objects.
					calender.calenderObjects = response.data.map(
						(calenderObject) => {
							calenderObject.start =
								utility.createDateFromResponse(
									calenderObject.start
								);
							calenderObject.end = utility.createDateFromResponse(
								calenderObject.end
							);

							return calenderObject;
						}
					);
				} else {
					addToaster({
						title: `Fetching Autocamper's Calender Objects`,
						text: 'Error calender objects',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: `Fetching Autocamper's Calender Objects`,
					text: 'Error calender objects',
					type: 'error',
				});
			}

			setCalender(calender);
		};

		updateCalender();
	}, [month, id, props, auth, addToaster]);

	if (!calender) {
		return <Loading />;
	}
	return (
		<div className='calender'>
			<div className='calender-header'>
				<div>
					<button
						className='calender-header-button-backwards'
						onClick={() => {
							setCalender(null);
							setMonth(
								new Date(
									month.getFullYear(),
									month.getMonth() - 1
								)
							);
						}}
					>
						{'<'}
					</button>
				</div>
				<div>
					<h1>
						{month.toLocaleString('dk-DK', { month: 'long' })} -{' '}
						{month.getFullYear()}
					</h1>
				</div>
				<div>
					<button
						className='calender-header-button-forwards'
						onClick={() => {
							setCalender(null);
							setMonth(
								new Date(
									month.getFullYear(),
									month.getMonth() + 1
								)
							);
						}}
					>
						{'>'}
					</button>
				</div>
			</div>
			<div className='calender-weekdays'>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>mandag</p>
				</div>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>tirsdag</p>
				</div>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>onsdag</p>
				</div>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>torsdag</p>
				</div>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>fredag</p>
				</div>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>lørdag</p>
				</div>
				<div className='calender-weekday-item'>
					<p className='calender-weekday'>søndag</p>
				</div>
			</div>
			<div className='calender-container'>
				<div className='calender-items-container'>
					{calender.calenderDays.map((item, i) => {
						return (
							<CalenderItem
								item={item}
								key={i}
								clickHandler={props.createCO}
							/>
						);
					})}
				</div>
				<div>
					{calender.calenderObjects.map((object, i) => {
						return (
							<CalenderObject
								object={object}
								key={i}
								month={month}
								firstDay={firstDayOnCalender}
								lastDay={lastDayOnCalender}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Calender;
