import emailRegex from 'email-regex';

const validateSignup = {
	validateEmail(email) {
		return emailRegex({ exact: true }).test(email);
	},

	validateUsername(username) {
		const regex = new RegExp(
			"^[0-9a-zA-ZàáâäãåąæčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
		);
		return (
			username.length <= 32 &&
			username.length >= 6 &&
			regex.test(username)
		);
	},

	validateFirstname(firstname) {
		const regex = new RegExp(
			"^[a-zA-ZàáâäãåąæčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
		);
		return firstname.length <= 32 && regex.test(firstname);
	},

	validateLastname(lastname) {
		const regex = new RegExp(
			"^[a-zA-ZàáâäãåąæčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
		);
		return lastname.length <= 32 && regex.test(lastname);
	},

	validatePassword(password, passwordRepeat) {
		return password.length >= 7 && password === passwordRepeat;
	},

	validateStreet(street) {
		const regex = new RegExp(
			"^[a-zA-ZàáâäãåąæčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
		);
		return street.length > 0 && regex.test(street);
	},

	validateStreetNumber(streetNumber) {
		const regex = new RegExp(
			"^[1-9][0-9a-zA-ZàáâäãåąæčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$"
		);
		return streetNumber.length > 0 && regex.test(streetNumber);
	},

	validatePostalCode(postalCode) {
		return parseInt(postalCode) >= 1000 && parseInt(postalCode) < 10000;
	},

	validateCity(city) {
		const regex = new RegExp(
			"^[a-zA-ZàáâäãåąæčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$"
		);
		return city.length > 0 && regex.test(city);
	},

	validatePhoneNumber(phoneNumber) {
		return String(parseInt(phoneNumber)).length === 8;
	},

	validateBirthdate(birthdate) {
		const regex = new RegExp(
			'^(?:0[1-9]|[12][0-9]|3[01])-(?:0[1-9]|1[012])-(?:19[0-9][0-9]|20[01][0-9]|2020)$'
		);

		// Check that age is at least 18.
		const today = new Date();
		const bd = birthdate.split('-');
		const birthDate = new Date(
			Date.UTC(parseInt(bd[0]) + 18, parseInt(bd[1]) - 1, parseInt(bd[2]))
		);

		return regex.test(birthdate) && birthDate.getTime() < today.getTime();
	},
};

export default validateSignup;
