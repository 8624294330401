import { useEffect, useState } from 'react';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import LoadingIcon from '../../loading/LoadingIcon';
import useToaster from '../../toaster/useToaster';
import './styles.css';

const EmailPage = () => {
	const [email, setEmail] = useState(null);
	const [emailSent, setEmailSent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	useEffect(() => {
		var isMounted = true;
		const controller = new AbortController();

		const sendEmail = async () => {
			if (email?.endpoint) {
				setEmailSent(false);
				setIsLoading(true);
				try {
					const response = await axios.post(
						email.endpoint,
						{},
						{
							headers: {
								authorization: `Bearer ${auth?.jwt}`,
								'Content-Type': 'application/json',
							},
							signal: controller.signal,
						}
					);

					if (response?.status === 200 && response?.data) {
						if (isMounted) {
							setEmailSent(true);
						}
					}
				} catch (error) {
					addToaster({
						title: 'Fetching email',
						text: 'Error fetching the email',
						type: 'error',
					});
				}
			}
			setIsLoading(false);
		};

		sendEmail();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [email, auth, addToaster]);

	return (
		<div className='vertical-layout page-layout'>
			<div className='container emails-header'>
				<button
					className='primary-button'
					onClick={() =>
						setEmail({
							title: 'Opret Booking',
							endpoint: '/email/booking-created',
						})
					}
				>
					Opret Booking
				</button>
				<button
					className='primary-button'
					onClick={() =>
						setEmail({
							title: 'Booking Starter',
							endpoint: '/email/booking-starting',
						})
					}
				>
					Booking Starter
				</button>
				<button
					className='primary-button'
					onClick={() =>
						setEmail({
							title: 'Lejer Depositumrefundering',
							endpoint: '/email/user-deposit',
						})
					}
				>
					Lejer Depositumrefundering
				</button>
				<button
					className='primary-button'
					onClick={() =>
						setEmail({
							title: 'Udlejer Depositumrefundering',
							endpoint: '/email/lessor-deposit',
						})
					}
				>
					Udlejer Depositumrefundering
				</button>
			</div>
			<div className='display-email-container container'>
				<div className='display-email-header'>
					{isLoading === true ? (
						<LoadingIcon />
					) : (
						<p className='email-title'>
							{emailSent
								? 'Email sendt.'
								: 'Send email til dig selv.'}
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmailPage;
