import React, { Component } from 'react';
import LoadingIcon from './LoadingIcon';

import './styles.css';

class Loading extends Component {
	render() {
		return (
			<span className='loading-container'>
				<LoadingIcon />
			</span>
		);
	}
}

export default Loading;
