import React, { useState } from 'react';
import utility from '../../../utils/utility';
import CancelBooking from '../../popup/cancelbooking';
import CreateNote from '../../popup/CreateNote';
import NotesContainer from './NotesContainer';

const MaintenancePage = (props) => {
	const [displayDelete, setDisplayDelete] = useState(false);

	const { hasStarted } = props;
	const booking = props.calenderObject;

	return (
		<>
			<article className='booking-page-layout'>
				<div className='booking-maintenance-header-layout'>
					<section className='booking-page-container booking-maintenance-autocamper-container'>
						<img
							src={utility.getAutocamperImage(
								booking.autocamper.avatar[0]
							)}
							alt='Autocamper Avatar'
							className='booking-maintenance-avatar'
						/>
						<h1 className='booking-maintenance-autocamper-name'>
							{booking.autocamper.name}
						</h1>
					</section>
					<section className='booking-page-container booking-maintenance-detail-container'>
						<div className=''>
							<h1 className='booking-maintenance-title'>
								Maintence
							</h1>
							<p>
								Start:{' '}
								<span>
									{utility.getClock(booking.start)}{' '}
									{utility.getDate(booking.start)}
								</span>
							</p>
							<p>
								Slut:{' '}
								<span>
									{utility.getClock(booking.start)}{' '}
									{utility.getDate(booking.start)}
								</span>
							</p>
							<p>
								Oprettet:{' '}
								<span>
									{utility.getClock(booking.created_at)}{' '}
									{utility.getDate(booking.created_at)}
								</span>
							</p>
						</div>
						<div>
							<button
								className='booking-maintenance-delete-button'
								onClick={() => setDisplayDelete(true)}
							>
								Slet
							</button>
						</div>
					</section>
					<section className='booking-maintenance-notes-container'>
						<NotesContainer
							notes={props.notes}
							notesError={props.notesError}
							createNote={() => props.setDisplayCreateNote(true)}
						/>
					</section>
				</div>
			</article>
			{displayDelete && (
				<CancelBooking
					toggle={() => setDisplayDelete(false)}
					id={booking.id}
					autocamper={booking.autocamper.id}
				/>
			)}
			{props.displayCreateNote && (
				<CreateNote
					toggle={() => props.setDisplayCreateNote(false)}
					id={booking?.id}
					addNote={props.addNote}
				/>
			)}
		</>
	);
};

export default MaintenancePage;
