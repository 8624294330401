import { useEffect, useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

const ConfirmDeposit = ({ toggle, refund, updateRefund }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [lessorPaid, setLessorPaid] = useState(false);
	const [userPaid, setUserPaid] = useState(false);
	const [organizationAccount, setOrganizationAccount] = useState(null);
	const [accountIsLoading, setAccountIsLoading] = useState(true);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const updateDepositRefund = async () => {
		setIsLoading(true);
		const id = refund?.id;
		let data = {};

		if (refund?.lessorPaid !== true && lessorPaid === true)
			data.lessorPaid = true;
		if (refund?.userPaid !== true && userPaid === true)
			data.userPaid = true;

		try {
			const response = await axios.put(`/deposit-refunds/${id}`, data, {
				headers: {
					Authorization: `Bearer ${auth?.jwt}`,
				},
			});
			if (response?.status === 200) {
				addToaster({
					title: 'Opdater depositumrefundering',
					text: 'Depositumrefundering blev opdateret',
					type: 'success',
				});
				updateRefund(response?.data);
				toggle();
			}
		} catch (error) {
			addToaster({
				title: 'Opdater depositumrefundering',
				text: 'Nok gik galt i opdatering af depositumsrefunderingen',
				type: 'error',
			});
			setIsLoading(false);
		}
	};

	useEffect(() => {
		let isMounted = true;

		const fetchAccount = async () => {
			try {
				const response = await axios.get(
					`/organizations/${refund?.organization?.id}/account`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);

				if (response?.status === 200) {
					if (isMounted) {
						setOrganizationAccount(response?.data);
					}
				}
			} catch (error) {
				addToaster({
					title: 'GET Error',
					text: 'Noget gik galt i hentning af konto detaljerne',
					type: 'error',
				});
			} finally {
				setAccountIsLoading(false);
			}
		};

		fetchAccount();
		return () => {
			isMounted = false;
		};
	}, [refund, addToaster, auth]);

	return (
		<Popup
			toggle={toggle}
			name='Depositumrefundering'
			actionRunning={isLoading}
			action={updateDepositRefund}
			buttonText='Opret'
		>
			<div className='popup-deposit-refund-layout'>
				<div>
					<h1>Beskrivelse</h1>
					<p>{refund?.depositDescription}</p>
				</div>
				<div className='popup-deposit-refund-paid-container'>
					<div>
						<h2>Udlejer udbetaling</h2>
						{refund?.lessorPaid === false ? (
							<div>
								<div className='popup-deposit-refund-details-container'>
									<p>
										Beløb:{' '}
										<span>
											{refund?.lessorFund.toFixed(2)} DKK
										</span>
									</p>
								</div>
								<div>
									<label htmlFor='popup-deposit-lessor-paid'>
										Er blevet betalt?
									</label>
									<input
										type='checkbox'
										id='popup-deposit-lessor-paid'
										value={lessorPaid}
										checked={lessorPaid}
										onChange={(e) =>
											setLessorPaid(
												e.target.value !== 'true'
											)
										}
									/>
								</div>
								<div>
									{accountIsLoading ? (
										<p>Loading...</p>
									) : (
										<>
											<p>
												Registreringsnummer:{' '}
												<label className='popup-deposit-account-detail'>
													{organizationAccount?.registrationNumber
														? organizationAccount.registrationNumber
														: 'Mangler registreringsnummer!'}
												</label>
											</p>
											<p>
												Kontonummer:{' '}
												<label className='popup-deposit-account-detail'>
													{organizationAccount?.accountNumber
														? organizationAccount.accountNumber
														: 'Mangler kontonummer!'}
												</label>
											</p>
										</>
									)}
								</div>
							</div>
						) : (
							<p>Udlejeren er blevet udbetalt.</p>
						)}
					</div>
					<div>
						<h2>Lejer udbetaling</h2>
						{refund?.userPaid === false ? (
							<div>
								<div className='popup-deposit-refund-details-container'>
									<p>
										Beløb:{' '}
										<span>
											{refund?.userFund.toFixed(2)} DKK
										</span>
									</p>
								</div>
								<div>
									<label htmlFor='popup-deposit-lessor-paid'>
										Er blevet betalt?
									</label>
									<input
										type='checkbox'
										id='popup-deposit-lessor-paid'
										value={userPaid}
										checked={userPaid}
										onChange={(e) =>
											setUserPaid(
												e.target.value !== 'true'
											)
										}
									/>
								</div>
								<div>
									<p>
										Registreringsnummer:{' '}
										<label className='popup-deposit-account-detail'>
											{refund?.booking_payment
												?.userRegistrationNumber
												? refund.booking_payment
														.userRegistrationNumber
												: 'Mangler registreringsnummer!'}
										</label>
									</p>
									<p>
										Kontonummer:{' '}
										<label className='popup-deposit-account-detail'>
											{refund?.booking_payment
												?.userAccountNumber
												? refund.booking_payment
														.userAccountNumber
												: 'Mangler kontonummer!'}
										</label>
									</p>
								</div>
							</div>
						) : (
							<p>Lejeren er blevet udbetalt.</p>
						)}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ConfirmDeposit;
