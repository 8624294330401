import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import useAuth from '../../../hooks/useAuth';
import useToaster from '../../toaster/useToaster';
import axios from '../../../api/axios';
import IncomeRowItem from './IncomeRowItem';
import LoadingIcon from '../../loading/LoadingIcon';
import PayIncome from '../../popup/PayIncome';

const IncomesPage = () => {
	const [month, setMonth] = useState(null);
	const [organizationIncomes, setOrganizationIncomes] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [incomeIndex, setIncomeIndex] = useState(null);

	const { addToaster } = useToaster();
	const { auth } = useAuth();

	const debouncedMonth = useMemo(() => {
		const fetchHandler = async () => {
			fetchIncomes();
		};

		const fetchIncomes = async () => {
			try {
				const response = await axios.get(
					`/organization-incomes/?month=${month.getTime()}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);

				if (response?.status === 200) {
					setOrganizationIncomes(response?.data);
				}
			} catch (error) {
				addToaster({
					title: "Fetching organizations' income",
					text: 'Something went wrong when fetching the income',
					type: 'error',
				});
			}
			setIsLoading(false);
		};

		return debounce(fetchHandler, 1000);
	}, [auth, month, addToaster]);

	const debouncedMonthHandler = () => {
		resetState();
		return debouncedMonth();
	};

	useEffect(() => {
		const today = new Date();
		const startMonth = new Date(
			Date.UTC(today.getFullYear(), today.getMonth(), 1)
		);
		setMonth(startMonth);
	}, []);

	useEffect(() => {
		debouncedMonth();

		return () => {
			debouncedMonth.cancel();
		};
	}, [debouncedMonth]);

	const resetState = () => {
		setIsLoading(true);
		setOrganizationIncomes(null);
	};

	const handleBackButton = () => {
		setMonth(
			(prevMonth) =>
				new Date(
					Date.UTC(prevMonth.getFullYear(), prevMonth.getMonth() - 1)
				)
		);
		debouncedMonthHandler();
	};

	const handleForwardButton = () => {
		setMonth((prevMonth) => {
			const today = new Date();
			const newMonth = new Date(
				Date.UTC(prevMonth.getFullYear(), prevMonth.getMonth() + 1)
			);

			return newMonth < today ? newMonth : prevMonth;
		});
		debouncedMonthHandler();
	};

	const updateIncome = (newIncome) => {
		let newIncomes = [...organizationIncomes];
		newIncomes[incomeIndex] = newIncome;
		setOrganizationIncomes(newIncomes);
	};

	return (
		<>
			<div className='incomes-page-layout-container'>
				<section className='income-page-main-header container'>
					<button
						className='home-calender-header-button'
						onClick={() => handleBackButton()}
					>
						Tilbage
					</button>
					<h1>
						{month !== null &&
							month.getUTCMonth() +
								1 +
								'/' +
								month.getUTCFullYear()}
					</h1>
					<button
						className='home-calender-header-button'
						onClick={() => handleForwardButton()}
					>
						Frem
					</button>
				</section>
				<section className='incomes-page-body container'>
					{isLoading ? (
						<div className='incomes-page-body-loading'>
							<LoadingIcon className='incomes-page-body-loading-icon' />
						</div>
					) : organizationIncomes === null ? (
						<div className='incomes-page-body-text-container'>
							<p>Noget gik galt</p>
						</div>
					) : organizationIncomes.length === 0 ? (
						<div className='incomes-page-body-text-container'>
							<p>Ingen resultater</p>
						</div>
					) : (
						<div className='incomes-page-body-content'>
							<div className='incomes-page-income-item'>
								<div className='incomes-page-income-item-detail'>
									<h1 className='incomes-page-income-item-title'>
										Organisation
									</h1>
								</div>
								<div className='incomes-page-income-item-detail'>
									<h1 className='incomes-page-income-item-title'>
										Total Indkomst
									</h1>
								</div>
								<div className='incomes-page-income-item-detail'>
									<h1 className='incomes-page-income-item-title'>
										Rate
									</h1>
								</div>
								<div className='incomes-page-income-item-detail'>
									<h1 className='incomes-page-income-item-title'>
										Udlejers Andel
									</h1>
								</div>
								<div className='incomes-page-income-item-detail'>
									<h1 className='incomes-page-income-item-title'>
										Betalt?
									</h1>
								</div>
							</div>
							{organizationIncomes.map((income, i) => (
								<IncomeRowItem
									organizationIncome={income}
									key={i}
									onClick={() => setIncomeIndex(i)}
								/>
							))}
						</div>
					)}
				</section>
			</div>
			{incomeIndex !== null && (
				<PayIncome
					income={organizationIncomes[incomeIndex]}
					updateIncome={(newIncome) => updateIncome(newIncome)}
					toggle={() => setIncomeIndex(null)}
				/>
			)}
		</>
	);
};

export default IncomesPage;
