const utility = {
	/**
	 * Returns the biggest avatar image.
	 *
	 * @param {object} autocamper
	 */
	getAutocamperImage(autocamper) {
		let image = '';

		if (autocamper?.formats?.large) {
			image += autocamper?.formats?.large?.url;
		} else if (autocamper?.formats?.medium) {
			image += autocamper?.formats?.medium?.url;
		} else if (autocamper?.formats?.small) {
			image += autocamper?.formats?.small?.url;
		} else {
			image += autocamper?.formats?.thumbnail?.url;
		}

		return image;
	},

	/**
	 * Returns the week number of the date.
	 *
	 * @param {Date} d
	 */
	getWeekNumber(d) {
		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
		var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
		return weekNo;
	},

	getWeekday(d) {
		return (d.getDay() + 6) % 7;
	},

	onSameDay(d1, d2) {
		return (
			d1.getUTCFullYear() === d2.getUTCFullYear() &&
			d1.getUTCMonth() === d2.getUTCMonth() &&
			d1.getUTCDate() === d2.getUTCDate()
		);
	},

	/**
	 * Returns the clock of date. Precondiation that d is a valid Date.
	 *
	 * @param {Date} d
	 */
	getClock(d) {
		const hour =
			d.getUTCHours() < 10
				? '0' + d.getUTCHours().toString()
				: d.getUTCHours();
		const minutes =
			Math.floor(d.getUTCMinutes() / 30) * 30 === 30 ? '30' : '00';

		return hour + ':' + minutes;
	},

	getClockExact(d) {
		return `${d.getHours()}:${d.getMinutes()}`;
	},

	getDate(d) {
		return d.getDate() + '/' + (d.getMonth() + 1) + '-' + d.getFullYear();
	},

	createDateFromResponse(string) {
		const date = new Date(parseInt(string));
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes()
		);
	},

	latestPaymentDateOfRate(created_at, booking_start, rate, payment_number) {
		if (rate === 1) {
			const paymentDate = new Date(
				created_at.getFullYear(),
				created_at.getMonth(),
				created_at.getDate() + 2
			);
			return paymentDate.getTime() > booking_start.getTime()
				? new Date(
						booking_start.getFullYear(),
						booking_start.getMonth(),
						booking_start.getDate()
				  )
				: paymentDate;
		} else {
			if (payment_number === 1) {
				const paymentDate = new Date(
					created_at.getFullYear(),
					created_at.getMonth(),
					created_at.getDate() + 2
				);
				return paymentDate.getTime() > booking_start.getTime()
					? new Date(
							booking_start.getFullYear(),
							booking_start.getMonth(),
							booking_start.getDate()
					  )
					: paymentDate;
			} else {
				return new Date(
					booking_start.getFullYear(),
					booking_start.getMonth(),
					booking_start.getDate() - 30
				);
			}
		}
	},

	getIdFormat(id) {
		var s = '00000000' + id;
		return '#' + s.substr(s.length - 8);
	},

	YYYYMMDD(date) {
		const yyyy = date.getUTCFullYear();
		let mm = date.getUTCMonth() + 1;
		let dd = date.getUTCDate();

		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		return `${yyyy}-${mm}-${dd}`;
	},
};

export default utility;
