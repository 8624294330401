import React from 'react';
import { useNavigate } from 'react-router-dom';
import utility from '../../../utils/utility';

export const PopularAutocamper = (props) => {
	const navigate = useNavigate();

	return (
		<div
			className='popular-autocamper-container'
			onClick={() => navigate(`/autocamper/${props?.autocamper?.id}`)}
		>
			<img
				src={utility.getAutocamperImage(props?.autocamper?.avatar[0])}
				alt='Autocamper Avatar'
				className='popular-autocamper-avatar'
			/>
			<p className='popular-autocamper-name'>{props?.autocamper?.name}</p>
			<p className='popular-autocamper-organization-name'>
				{props?.autocamper?.organization?.name}
			</p>
		</div>
	);
};
