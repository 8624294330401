import { useState } from 'react';
import axios from '../../api/axios';
import Popup from './popup';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useToaster from '../toaster/useToaster';

export const UpdateBooking = (props) => {
	const [verify, setVerify] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();
	const navigate = useNavigate();

	const newStatus = (status, rate) => {
		return rate === 2 && status === 'unpaid' ? 'partly' : 'paid';
	};

	const verifyText = (status, rate) => {
		return rate === 2 && status === 'unpaid' ? 'delvist betalt' : 'betalt';
	};

	const updateBooking = async () => {
		if (
			verify.toLocaleLowerCase() === verifyText(props.status, props.rate)
		) {
			setIsLoading(true);
			try {
				const response = await axios.put(
					`/booking/${props.id}/status`,
					{
						status: newStatus(props.status, props.rate),
					},
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				setIsLoading(false);

				if (response?.status === 200) {
					addToaster({
						title: 'Updating Booking Status',
						text: 'Succesfully updated booking status',
						type: 'success',
					});
					navigate(`/global`);
				} else {
					addToaster({
						title: 'Updating Booking Status',
						text: 'Error updated booking status',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Updating Booking Status',
					text: 'Error updated booking status',
					type: 'error',
				});
			}
		} else {
			addToaster({
				title: 'Updating Booking Status',
				text: 'Please enter the verification text',
				type: 'warning',
			});
		}
		setIsLoading(false);
	};

	var statusText = '';
	if (props.status === 'unpaid') {
		statusText = 'Ikke betalt';
	} else if (props.status === 'partly') {
		statusText = 'Delvist betalt';
	} else {
		statusText = 'Betalt';
	}

	return props.status === 'unpaid' || props.status === 'partly' ? (
		<Popup
			toggle={props.toggle}
			name='Opdater booking'
			action={updateBooking}
			actionRunning={isLoading}
			buttonText='Opdater'
		>
			<div className='create-calender-container'>
				<div className='vertical-form'>
					<div className='update-booking-status-container'>
						<div>
							<p>Nuværende Status:</p>
							<h1>{statusText}</h1>
						</div>
						<div>
							<p>Skriv nedstående status for at bekræfte:</p>
							<input
								type='text'
								placeholder={verifyText(
									props.status,
									props.rate
								)}
								onChange={(e) => setVerify(e.target.value)}
								value={verify}
								className='booking-update-input'
							/>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	) : (
		<p>Error</p>
	);
};

export default UpdateBooking;
