import axios from '../../../api/axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import BookingPage from './BookingPage';
import MaintenancePage from './MaintenancePage';
import ReservationPage from './ReservationPage';
import utility from '../../../utils/utility';
import Loading from '../../loading/Loading';
import useToaster from '../../toaster/useToaster';

const CalenderObjectPage = (props) => {
	const [calenderObject, setCalenderObject] = useState({});
	const [notes, setNotes] = useState(null);
	const [notesError, setNotesError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [displayCreateNote, setDisplayCreateNote] = useState(false);
	const { id } = useParams();
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const now = new Date();
	const isAdmin = auth?.user?.role?.type === 'admin';

	useEffect(() => {
		let isMounted = true;
		let controller = new AbortController();

		const fetchCalenderObject = async (id) => {
			try {
				const response = await axios.get(`/booking/${id}`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response?.status === 200) {
					if (isMounted) {
						response.data.start = utility.createDateFromResponse(
							response.data.start
						);
						response.data.end = utility.createDateFromResponse(
							response.data.end
						);
						response.data.created_at = new Date(
							response.data.created_at
						);

						setCalenderObject(response.data);
					}
				} else {
					addToaster({
						title: 'Fetching Calender Object',
						text: 'Error fetching calender object',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Calender Object',
					text: 'Error fetching calender object',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		};

		const fetchNotes = async () => {
			try {
				const response = await axios.get(
					`/notes/calender-object/${id}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
						signal: controller.signal,
					}
				);

				if (response?.status === 200) {
					const notes = response.data.map((note) => {
						let result = { ...note };
						result.created_at = new Date(result.created_at);
						return result;
					});
					setNotes(
						notes.sort((a, b) => {
							return (
								a.created_at.getTime() - b.created_at.getTime()
							);
						})
					);
				}
			} catch (error) {
				setNotesError(error?.message);
				addToaster({
					title: 'Fetching Notes',
					text: 'Error fetching notes',
					type: 'error',
				});
			}
		};

		fetchCalenderObject(id);
		fetchNotes(id);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [id, props, auth, addToaster]);

	const addNote = (note) => {
		const newNotes = [...notes];
		newNotes.push(note);
		setNotes(newNotes);
	};

	const startUnix = calenderObject?.start
		? new Date(
				Date.UTC(
					calenderObject?.start.getFullYear(),
					calenderObject?.start.getMonth(),
					calenderObject?.start.getDate(),
					calenderObject?.start.getHours(),
					calenderObject?.start.getMinutes()
				)
		  )
		: null;

	const getComponent = (object) => {
		const type = object?.calender_object_type?.type;
		if (type === 'booking') {
			return (
				<BookingPage
					calenderObject={object}
					setCalenderObject={setCalenderObject}
					notes={notes}
					notesError={notesError}
					addNote={addNote}
					displayCreateNote={displayCreateNote}
					setDisplayCreateNote={setDisplayCreateNote}
					isAdmin={isAdmin}
					hasStarted={!(startUnix !== null && now < startUnix)}
				/>
			);
		} else if (type === 'maintenance') {
			return (
				<MaintenancePage
					calenderObject={object}
					notes={notes}
					notesError={notesError}
					addNote={addNote}
					displayCreateNote={displayCreateNote}
					setDisplayCreateNote={setDisplayCreateNote}
					isAdmin={isAdmin}
					hasStarted={!(startUnix !== null && now < startUnix)}
				/>
			);
		} else if (type === 'reservation') {
			return (
				<ReservationPage
					calenderObject={object}
					notes={notes}
					notesError={notesError}
					addNote={addNote}
					displayCreateNote={displayCreateNote}
					setDisplayCreateNote={setDisplayCreateNote}
					isAdmin={isAdmin}
					hasStarted={!(startUnix !== null && now < startUnix)}
				/>
			);
		}
	};

	return isLoading ? (
		<Loading />
	) : !calenderObject?.id ? (
		<p>Error loading calender object</p>
	) : (
		getComponent(calenderObject)
	);
};

export default CalenderObjectPage;
