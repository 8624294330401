import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../../api/axios';
import useAuth from '../../../hooks/useAuth';
import utility from '../../../utils/utility';
import Loading from '../../loading/Loading';
import useToaster from '../../toaster/useToaster';

import './styles.css';

export const Home = () => {
	const [calenderObjectsStart, setCalenderObjectsStart] = useState(null);
	const [calenderObjectsEnd, setCalenderObjectsEnd] = useState(null);
	const [isLoadingCalenderObjects, setIsLoadingCalenderObjects] =
		useState(true);
	const [isLoadingDeposit, setIsLoadingDeposit] = useState(true);
	const [selectedWeek, setSelectedWeek] = useState(new Date());
	const [deposits, setDeposits] = useState(null);
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	useEffect(() => {
		const controller = new AbortController();
		let isMounted = true;

		const fetchStatistics = async (controller) => {
			setIsLoadingDeposit(true);
			try {
				let response = await axios.get('/deposit-refunds', {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response?.status === 200) {
					const deposits = response.data.map((deposit) => {
						deposit.booking_payment.calender_object.start =
							new Date(
								parseInt(
									deposit.booking_payment.calender_object
										.start
								)
							);
						deposit.booking_payment.calender_object.end = new Date(
							parseInt(
								deposit.booking_payment.calender_object.end
							)
						);
						return deposit;
					});
					if (isMounted) setDeposits(deposits);
				} else {
					addToaster({
						title: 'Fetching Deposit Refunds',
						text: response.message,
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Deposit Refunds',
					text: error.message,
					type: 'error',
				});
			}
			setIsLoadingDeposit(false);
		};

		fetchStatistics(controller);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [auth, addToaster]);

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const fetchWeeklyCalenderObjects = async () => {
			setIsLoadingCalenderObjects(true);
			try {
				const response = await axios.get(
					`/weekly/calender-objects?date=${selectedWeek.getTime()}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
						signal: controller.signal,
					}
				);

				if (response?.status === 200) {
					if (isMounted) {
						const objectsStart = response?.data?.start.map(
							(object) => {
								const result = { ...object };
								result.start = utility.createDateFromResponse(
									object.start
								);
								return result;
							}
						);
						setCalenderObjectsStart(
							objectsStart.sort((a, b) => {
								return a.start.getTime() - b.start.getTime();
							})
						);
						const objectsEnd = response?.data?.end.map((object) => {
							const result = { ...object };
							result.end = utility.createDateFromResponse(
								object.end
							);
							return result;
						});
						setCalenderObjectsEnd(
							objectsEnd.sort((a, b) => {
								return a.end.getTime() - b.end.getTime();
							})
						);
					}
				} else {
					addToaster({
						title: response.status,
						text: response.message,
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Weekly Calender Objects',
					text: error.message,
					type: 'error',
				});
			} finally {
				setIsLoadingCalenderObjects(false);
			}
		};

		fetchWeeklyCalenderObjects();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [selectedWeek, auth, addToaster]);

	return (
		<div className='home-layout-container'>
			<div className='home-container'>
				<h1 className='home-title'>Depositum</h1>
				<p className='home-subtitle'>
					Bookinger som skal have oprettet deres depositumrefundering.
					Gå til bookingen og under depositum.
				</p>
				{isLoadingDeposit ? (
					<div className='home-calender-objects-centering2'>
						<Loading />
					</div>
				) : (
					<div className='home-calender-deposit-container'>
						<div className='home-calender-deposit-list'>
							{deposits.length === 0 ? (
								<div className='home-calender-deposit-complete-container'>
									<p>Mangler ingen 👍</p>
								</div>
							) : (
								<>
									<div className='home-calender-deposit-item'>
										<p className='calender-object-item-title'>
											Booking ID
										</p>
										<p className='calender-object-item-title'>
											Start
										</p>
										<p className='calender-object-item-title'>
											Slut
										</p>
									</div>
									{deposits.map((deposit, i) => {
										return (
											<div
												className='home-calender-deposit-item'
												key={i}
											>
												<Link
													className='home-link-text'
													to={`/booking/${deposit.booking_payment.calender_object.id}`}
												>
													{utility.getIdFormat(
														deposit.booking_payment
															.id
													)}
												</Link>
												<p>
													{utility.getClock(
														deposit.booking_payment
															.calender_object
															.start
													)}{' '}
													{utility.getDate(
														deposit.booking_payment
															.calender_object
															.start
													)}
												</p>
												<p>
													{utility.getClock(
														deposit.booking_payment
															.calender_object.end
													)}{' '}
													{utility.getDate(
														deposit.booking_payment
															.calender_object.end
													)}
												</p>
											</div>
										);
									})}
								</>
							)}
						</div>
					</div>
				)}
			</div>
			<div className='home-calender-objects-container home-container'>
				<div className='home-calender-objects'>
					<div className='home-calender-header'>
						<div className='home-calender-header-buttons-container'>
							<button
								onClick={() => {
									setSelectedWeek(
										new Date(
											selectedWeek.getTime() - 604800000
										)
									);
								}}
								className='home-calender-header-button'
							>
								Tilbage
							</button>
							<span>
								Uge {utility.getWeekNumber(selectedWeek)} -{' '}
								{selectedWeek.getFullYear()}
							</span>
							<button
								onClick={() => {
									setSelectedWeek(
										new Date(
											selectedWeek.getTime() + 604800000
										)
									);
								}}
								className='home-calender-header-button'
							>
								Frem
							</button>
						</div>
					</div>
					<h1 className='home-title'>Afhentninger</h1>
					{isLoadingCalenderObjects !== false ? (
						<div className='home-calender-objects-centering'>
							<Loading />
						</div>
					) : calenderObjectsStart === null ? (
						<div className='home-calender-objects-centering'>
							<p>Noget gik galt... ⛔</p>
						</div>
					) : calenderObjectsStart.length === 0 ? (
						<div className='home-calender-objects-centering'>
							<p>Ingen afhentninger i denne uge 👍</p>
						</div>
					) : (
						<div className='home-calender-objects-list'>
							<div className='calender-object-item'>
								<p className='calender-object-item-title'>
									Booking ID
								</p>
								<p className='calender-object-item-title'>
									Tidspunkt
								</p>
								<p className='calender-object-item-title'>
									Autocamper
								</p>
								<p className='calender-object-item-title'>
									Bruger
								</p>

								<p className='calender-object-item-title'>
									Type
								</p>
							</div>
							{calenderObjectsStart.map((calenderObject, i) => {
								return (
									<div
										className='calender-object-item'
										key={i}
									>
										<p>
											<Link
												className='home-link-text'
												to={`/booking/${calenderObject?.id}`}
											>
												{calenderObject
													?.calender_object_type
													?.type === 'booking'
													? utility.getIdFormat(
															calenderObject
																?.booking_payment
																?.id
													  )
													: calenderObject?.id}
											</Link>
										</p>
										<p>
											{`${utility.getClock(
												calenderObject?.start
											)} ${calenderObject?.start.getDate()}/${
												calenderObject?.start.getMonth() +
												1
											}`}
										</p>
										<p>
											{calenderObject?.autocamper?.name}
										</p>
										<p>{calenderObject?.users?.username}</p>
										<p>
											{
												calenderObject
													?.calender_object_type?.type
											}
										</p>
									</div>
								);
							})}
						</div>
					)}
				</div>
				<div className='home-calender-objects'>
					<h1 className='home-title'>Afleveringer</h1>
					{isLoadingCalenderObjects !== false ? (
						<div className='home-calender-objects-centering'>
							<Loading />
						</div>
					) : calenderObjectsEnd === null ? (
						<div className='home-calender-objects-centering'>
							<p>Noget gik galt... ⛔</p>
						</div>
					) : calenderObjectsEnd.length === 0 ? (
						<div className='home-calender-objects-centering'>
							<p>Ingen afleveringer denne uge 👍</p>
						</div>
					) : (
						<div className='home-calender-objects-list'>
							<div className='calender-object-item'>
								<p className='calender-object-item-title'>
									Booking ID
								</p>
								<p className='calender-object-item-title'>
									Tidspunkt
								</p>
								<p>Autocamper</p>
								<p className='calender-object-item-title'>
									Bruger
								</p>
								<p className='calender-object-item-title'>
									Type
								</p>
							</div>
							{calenderObjectsEnd.map((calenderObject, i) => {
								return (
									<div
										className='calender-object-item'
										key={i}
									>
										<p>
											<Link
												className='home-link-text'
												to={`/booking/${calenderObject?.id}`}
											>
												{calenderObject
													?.calender_object_type
													?.type === 'booking'
													? utility.getIdFormat(
															calenderObject
																?.booking_payment
																?.id
													  )
													: calenderObject?.id}
											</Link>
										</p>
										<p>
											{`${utility.getClock(
												calenderObject?.end
											)} ${calenderObject?.end.getDate()}/${
												calenderObject?.end.getMonth() +
												1
											}`}
										</p>
										<p>
											{calenderObject?.autocamper?.name}
										</p>
										<p>{calenderObject?.users?.username}</p>
										<p>
											{
												calenderObject
													?.calender_object_type?.type
											}
										</p>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
