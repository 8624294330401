import { Link } from 'react-router-dom';

export const SidebarItem = (props) => {
	const { link, icon, alt, name } = props.sideBarItem;

	return (
		<Link to={link} className='sidebar-item'>
			<div className='sidebar-item-container'>
				<img src={icon} alt={alt} className='sidebar-item-icon' />
				<span className='sidebar-item-name'>{name}</span>
			</div>
		</Link>
	);
};

export default SidebarItem;
