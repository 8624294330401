import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/pages/home/Home';
import Login from './components/pages/login/Login';
import RequireAuth from './components/RequireAuth';
import { Organization } from './components/pages/organization/Organization';
import { DefaultStructure } from './components/structures/portal/DefaultStructure';
import AutocampersPage from './components/pages/autocamper/AutocampersPage';
import { AutocamperCalenderPage } from './components/pages/autocamper/AutocamperCalenderPage';
import AddonPage from './components/pages/organization/AddonPage';
import { GlobalPage } from './components/pages/global/GlobalPage';
import CalenderObjectPage from './components/pages/booking/CalenderObjectPage';
import EmailPage from './components/pages/email/EmailPage';
import { ErrorHandling } from './components/error/ErrorHandling';
import AutocamperPage from './components/pages/autocamper/AutocamperPage';
import UsersPage from './components/pages/user/UsersPage';
import UserPage from './components/pages/user/UserPage';
import NewOrganization from './components/pages/newOrganization/NewOrganization';
import IncomePage from './components/pages/income/IncomePage';
import DepositPage from './components/pages/deposit/DepositPage';
import IncomesPage from './components/pages/income/IncomesPage';
import LandingPage from './components/public/landing/LandingPage';
import SignupPage from './components/public/signup/SignupPage';

const ROLES = {
	authenticated: 'authenticated',
	lessor: 'lessor',
	admin: 'admin',
};

function App() {
	return (
		<Routes>
			<Route path='/' element={<Layout />}>
				{/* Public routes */}
				<Route path='' element={<LandingPage />} />
				<Route path='signup' element={<SignupPage />} />
				<Route path='login' element={<Login />} />
				<Route path='contact' element={<p>Contact</p>} />

				{/* Authenticated routes */}
				<Route
					element={
						<RequireAuth allowedRoles={[ROLES.authenticated]} />
					}
				>
					<Route path='/new' element={<NewOrganization />} />
				</Route>

				{/* Protected routes */}
				<Route
					element={
						<RequireAuth
							allowedRoles={[ROLES.lessor, ROLES.admin]}
						/>
					}
				>
					<Route
						path='/dashboard'
						element={<DefaultStructure children={<Home />} />}
					/>
					{/* Routes for organization */}
					<Route path='/organization'>
						<Route
							path='/organization'
							element={
								<DefaultStructure children={<Organization />} />
							}
						/>
						<Route
							path='/organization/addon/:id'
							element={
								<DefaultStructure children={<AddonPage />} />
							}
						/>
					</Route>

					{/* Routes for autocamper */}
					<Route path='/autocamper'>
						<Route
							path='/autocamper'
							element={
								<DefaultStructure
									children={<AutocampersPage />}
								/>
							}
						/>
						<Route
							path='/autocamper/:id'
							element={
								<DefaultStructure
									children={<AutocamperPage />}
								/>
							}
						/>
						<Route
							path='/autocamper/:id/calender'
							element={
								<DefaultStructure
									children={<AutocamperCalenderPage />}
								/>
							}
						/>
					</Route>

					{/* Route for booking */}
					<Route
						path='/booking/:id'
						element={
							<DefaultStructure
								children={<CalenderObjectPage />}
							/>
						}
					/>

					{/* Income booking */}
					<Route
						path='/income'
						element={<DefaultStructure children={<IncomePage />} />}
					/>
				</Route>
				<Route element={<RequireAuth allowedRoles={[ROLES.admin]} />}>
					<Route
						path='/global'
						element={<DefaultStructure children={<GlobalPage />} />}
					/>
					<Route
						path='/email'
						element={<DefaultStructure children={<EmailPage />} />}
					/>
					<Route
						path='/deposit'
						element={
							<DefaultStructure children={<DepositPage />} />
						}
					/>
					<Route
						path='/incomes'
						element={
							<DefaultStructure children={<IncomesPage />} />
						}
					/>
					{/* Routes for users */}
					<Route path='/user'>
						<Route
							path='/user'
							element={
								<DefaultStructure children={<UsersPage />} />
							}
						/>
						<Route
							path='/user/:id'
							element={
								<DefaultStructure children={<UserPage />} />
							}
						/>
					</Route>
				</Route>

				{/* Catch all */}
				<Route path='*' element={<ErrorHandling status={404} />} />
			</Route>
		</Routes>
	);
}

export default App;
