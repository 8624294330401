import React, { useState, useEffect } from 'react';
import Popup from './popup';
import AutocamperTag from './autocampertag';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import axios from '../../api/axios';
import fileOperator from '../../utils/fileoperator';
import { get } from 'lodash';
import autocamperTags from './tags.json';
import Tag from './tag/tag';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Loading from '../loading/Loading';

export const CreateAutocamper = (props) => {
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const [avatarImage, setAvatarImage] = useState(null);
	const [processingAvatar, setProcessingAvatar] = useState(false);
	const [images, setImages] = useState([]);
	const [processingImages, setProcessingImages] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [addons, setAddons] = useState([]);
	const [price, setPrice] = useState(0);
	const [startPrice, setStartPrice] = useState(0);
	const [selectAddons, setSelectAddons] = useState([]);
	const [tags, setTags] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		var isMounted = true;
		const controller = new AbortController();

		const fetchAddons = async () => {
			try {
				const response = await axios.get(`/addons`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
						'Content-Type': 'application/json',
					},
				});
				if (response?.status === 200 && response?.data) {
					if (isMounted) {
						setAddons(response.data);
					}
				} else {
					addToaster({
						title: 'Fetching Addons',
						text: 'Error Fetching addons',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Addons',
					text: 'Error Fetching addons',
					type: 'error',
				});
			}
		};

		fetchAddons();

		const values = get(autocamperTags, ['tags']);
		setTags(values);

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [auth, addToaster]);

	const toggleSelectedAddons = (i, status) => {
		var updatedSelectAddons = [...selectAddons];
		if (status) {
			updatedSelectAddons.push(addons[i]);
			setSelectAddons(updatedSelectAddons);
		} else {
			updatedSelectAddons = updatedSelectAddons.filter((addon) => {
				return addon.id !== addons[i].id;
			});

			setSelectAddons(updatedSelectAddons);
		}
	};

	const addToSelectedTags = (i) => {
		const updatedTags = [...tags];
		const updatedSelectedTags = [...selectedTags];
		const tag = updatedTags[i];
		tag.value = null;

		updatedTags.splice(i, 1);
		updatedSelectedTags.push(tag);

		setTags(updatedTags);
		setSelectedTags(updatedSelectedTags);
	};

	const removeSelectedTag = (tag) => {
		const updatedTags = [...tags];
		const updatedSelectedTags = [...selectedTags];

		let i = 0;
		let removed = false;
		while (i < updatedSelectedTags.length && !removed) {
			if (updatedSelectedTags[i] === tag) {
				updatedSelectedTags.splice(i, 1);
				updatedTags.push(tag);
				removed = true;
			}
			i = i + 1;
		}

		setTags(updatedTags);
		setSelectedTags(updatedSelectedTags);
	};

	const updateTagValue = (tag, e) => {
		const value = e.target.value;
		var updatedSelectedTags = [...selectedTags];
		let i = 0;
		let found = false;
		while (i < updatedSelectedTags.length && !found) {
			if (updatedSelectedTags[i] === tag) {
				if (e.target.validity.valid === true) {
					updatedSelectedTags[i].value = value;
					setSelectedTags(updatedSelectedTags);
				} else {
					e.target.value = updatedSelectedTags[i].value;
				}
			}
			i = i + 1;
		}
	};

	// const resizeAndSetInputFile = (file, onComplete, onError) => {
	// 	let reader = new FileReader();
	// 	reader.readAsDataURL(file);
	// 	reader.name = file.name;
	// 	reader.size = file.size;
	// 	reader.onload = (readerEvent) => {
	// 		let img = new Image();
	// 		img.src = readerEvent.target.result;
	// 		img.size = readerEvent.size;
	// 		img.onload = (imageEvent) => {
	// 			let elem = document.createElement('canvas');

	// 			const MAX_SIZE = 1000;
	// 			let newWidth = imageEvent.target.width;
	// 			let newHeight = imageEvent.target.height;

	// 			if (newWidth > MAX_SIZE || newHeight > MAX_SIZE) {
	// 				const ratio = newHeight / newWidth;

	// 				if (newHeight > newWidth) {
	// 					newHeight = MAX_SIZE;
	// 					newWidth = newHeight / ratio;
	// 				} else {
	// 					newWidth = MAX_SIZE;
	// 					newHeight = newWidth * ratio;
	// 				}
	// 			}
	// 			elem.width = newWidth;
	// 			elem.height = newHeight;

	// 			let ctx = elem.getContext('2d');
	// 			ctx.drawImage(imageEvent.target, 0, 0, elem.width, elem.height);
	// 			onComplete(ctx.canvas.toDataURL('iamge/jpeg', 1));
	// 		};

	// 		img.onerror = () => {
	// 			onError();
	// 		};
	// 	};
	// }

	const setAvatarImageEvent = async (e) => {
		if (e.target.files && e.target.files[0]) {
			let avatar = e.target.files[0];

			setProcessingAvatar(true);
			fileOperator.resizeInputFile(
				avatar,
				(blob) => {
					const url = window.URL.createObjectURL(blob);
					setAvatarImage({ blob, url });
					setProcessingAvatar(false);
				},
				() => {
					addToaster({
						title: 'Upload File',
						text: 'Something went wrong while processing the file. Might not have been an image',
						type: 'error',
					});
				}
			);
		} else {
			addToaster({
				title: 'Upload File',
				text: 'Please select at least 1 file',
				type: 'warning',
			});
		}
	};

	const setImagesEvent = async (e) => {
		if (e.target.files && e.target.files[0]) {
			const fileCount = e.target.files.length;
			const files = e.target.files;
			let images = [];

			let invalidFileCount = 0;

			function readFile(index) {
				if (index < fileCount) {
					let file = files[index];
					fileOperator.resizeInputFile(
						file,
						(blob) => {
							const url = window.URL.createObjectURL(blob);
							images.push({ blob, url });
							readFile(index + 1);
						},
						() => {
							invalidFileCount++;
						}
					);
				} else {
					setProcessingImages(false);
					setImages(images);
					if (invalidFileCount > 0) {
						addToaster({
							title: 'Upload Files',
							text: `${invalidFileCount} file(s) were invalid, and therefore not included. Might bot have been an image.`,
							type: 'error',
						});
					}
				}
			}

			if (fileCount <= fileOperator.getMaxNumberOfFiles()) {
				setProcessingImages(true);
				readFile(0);
			} else {
				addToaster({
					title: 'Upload Files',
					text: `The maximum number of files is ${fileOperator.getMaxNumberOfFiles()}`,
					type: 'warning',
				});
			}
		}
	};

	const createAutocamper = async () => {
		const tagsValid = selectedTags.map((tag) => {
			if (tag.type === undefined) {
				return true;
			} else {
				if (tag.value === null || tag.value === '') {
					return false;
				} else {
					return true;
				}
			}
		});

		const addons = selectAddons.map((addon) => {
			return {
				id: addon.id,
			};
		});

		if (
			name !== '' &&
			price > 0 &&
			startPrice > 0 &&
			!tagsValid.includes(false) &&
			avatarImage !== null &&
			!processingAvatar &&
			!processingImages
		) {
			setIsLoading(true);

			// Map tags to proper fields.
			const tags = selectedTags.map((tag) => {
				return {
					name: tag.name,
					value: tag.value,
				};
			});

			try {
				const formData = new FormData();
				const data = {
					name: name,
					description: description,
					price: price,
					startPrice: startPrice,
					tags: tags,
					addons: addons,
				};

				const avatar = avatarImage;
				formData.append(`files.avatar`, avatar.blob, 'avatar.jpg');
				formData.append('data', JSON.stringify(data));
				formData.append('files.images', []);
				for (let i = 0; i < images.length; i++) {
					const img = images[i];
					formData.append(`files.images`, img.blob, `img-${i}.jpg`);
				}

				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/autocampers`,
					formData,
					{
						headers: {
							authorization: `Bearer ${auth?.jwt}`,
							'Content-type': 'multipart/form-data',
						},
					}
				);

				if (response.status === 200) {
					addToaster({
						title: 'Autocamper Created',
						text: 'The autocamper was successfully created',
						type: 'success',
						link: `/autocamper/${response?.data?.id}`,
						linkText: 'Gå til autocamperen',
					});
					props.toggle();
				} else {
					addToaster({
						title: 'Creating autocamper',
						text: 'Something went wrong creating the autocamper',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Creating autocamper',
					text: 'Something went wrong creating the autocamper',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			addToaster({
				title: 'Creating autocamper',
				text: 'Please fill out the required fields',
				type: 'warning',
			});
		}
	};

	return (
		<Popup
			actionRunning={isLoading}
			toggle={props.toggle}
			name='Ny Autocamper'
			action={createAutocamper}
		>
			<div className='create-autocamper-container'>
				<form className='create-autocamper-form'>
					<div className='vertical-form'>
						<div className='form-field'>
							<label className='required'>Navn</label>
							<div className='form-field-input-container'>
								<input
									type='text'
									name='name'
									autoComplete='off'
									className='form-field-input-text'
									onChange={(e) => {
										setName(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className='form-field'>
							<label>Beskrivelse</label>
							<div className='form-field-input-container'>
								<textarea
									name='description'
									className='form-field-input-textarea'
									rows='3'
									onChange={(e) => {
										setDescription(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className='form-field'>
							<label className='required'>Pris</label>
							<div className='form-field-input-container'>
								<input
									type='number'
									onWheel={(e) => e.target.blur()}
									name='price'
									className='form-field-input-text'
									onChange={(e) => {
										setPrice(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className='form-field'>
							<label className='required'>Startgebyr</label>
							<div className='form-field-input-container'>
								<input
									type='number'
									onWheel={(e) => e.target.blur()}
									name='startPrice'
									className='form-field-input-text'
									onChange={(e) => {
										setStartPrice(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className='form-field'>
							<label>Tilkøb</label>
							<div className='form-field-input-container'>
								{addons.map((addon, i) => {
									return (
										<React.Fragment key={i}>
											<input
												type='checkbox'
												id={i}
												onChange={(e) => {
													toggleSelectedAddons(
														i,
														e.target.checked
													);
												}}
											/>
											<label htmlFor={i}>
												{addon?.name}
											</label>
											<br />
										</React.Fragment>
									);
								})}
							</div>
						</div>
						<div className='form-field'>
							<label>Tags</label>
							<div className='select-autocamper-tags-container'>
								<div className='select-autocamper-tags-horizontal'>
									{tags
										.sort(function (a, b) {
											return a.name < b.name ? -1 : 1;
										})
										.map((tag, i) => {
											return (
												<AutocamperTag
													key={i}
													tag={tag}
													index={i}
													addToSelectedTags={
														addToSelectedTags
													}
												/>
											);
										})}
								</div>
							</div>
						</div>
						<div className='form-field'>
							<label>Valgte tags</label>
							<div className='form-field-input-container'>
								{selectedTags.map((tag, i) => {
									return (
										<Tag
											tag={tag}
											key={i}
											removeTag={removeSelectedTag}
											updateValue={updateTagValue}
										/>
									);
								})}
							</div>
						</div>
						<div className='form-field'>
							<label className='required'>Profilbillede</label>
							<div className='form-field-input-container'>
								<input
									type='file'
									name='profile-image'
									accept='image/*'
									onChange={(e) => setAvatarImageEvent(e)}
								/>
							</div>
						</div>
						<div className='form-field'>
							<label>Billeder</label>
							<div className='form-field-input-container'>
								<input
									type='file'
									name='images'
									multiple
									accept='image/*'
									onChange={setImagesEvent}
								/>
							</div>
						</div>
					</div>
				</form>
				<div>
					<div className='new-autocamper-images-container'>
						<div>
							<label>Profilbillede</label>
							<div className='autocamper-profile-image-container'>
								{processingAvatar ? (
									<Loading />
								) : avatarImage ? (
									<img
										className='autocamper-profile-image'
										src={avatarImage.url}
										alt='Autocamper Avatar'
									/>
								) : (
									<h1>Intet profilbillede valgt!</h1>
								)}
							</div>
						</div>
						<div>
							<label>Billeder</label>
							<div className='autocamper-images-container'>
								{processingImages ? (
									<Loading />
								) : images.length > 0 ? (
									<Carousel
										dynamicHeight={true}
										showThumbs={false}
										infiniteLoop={true}
									>
										{images.map((image, i) => {
											return (
												<img
													key={i}
													className='autocamper-upload-image'
													src={image.url}
													alt='Autocamper Avatar'
												/>
											);
										})}
									</Carousel>
								) : (
									<h1>Ingen billeder valgt!</h1>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default CreateAutocamper;
