import { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import Autocampers from '../../autocampers';
import CreateAutocamper from '../../popup/createautocamper';
import useAuth from '../../../hooks/useAuth';
import Loading from '../../loading/Loading';
import useToaster from '../../toaster/useToaster';

export const AutocampersPage = (props) => {
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const [autocampers, setAutocampers] = useState([]);
	const [createPopupStatus, setCreatePopupStatus] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		var isMounted = true;
		const controller = new AbortController();

		const fetchAutocampers = async () => {
			try {
				const response = await axios.get(
					`/autocampers/organization/${auth?.user?.organization?.id}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
						signal: controller.signal,
					}
				);

				if (response.status === 200 && response?.data) {
					if (isMounted) setAutocampers(response.data);
				} else {
					addToaster({
						title: 'Fetching Autocampers',
						text: 'Error fetching autocampers',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Autocampers',
					text: 'Error fetching autocampers',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		};

		fetchAutocampers();
		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [auth, addToaster]);

	return isLoading ? (
		<Loading />
	) : (
		<>
			<div className='vertical-layout page-layout'>
				<div className='autocamper-header container'>
					<h1 className='header-title'>AUTOCAMPER</h1>
					<button
						className='circle-button'
						onClick={() => setCreatePopupStatus(true)}
					>
						+
					</button>
				</div>
				<div className='container'>
					<Autocampers autocampers={autocampers} />
				</div>
			</div>
			{createPopupStatus ? (
				<CreateAutocamper toggle={() => setCreatePopupStatus(false)} />
			) : null}
		</>
	);
};

export default AutocampersPage;
