import './styles.css';

const LoadButton = ({ text, isLoading, classes, color, action }) => {
	const svg = `/loading-${color}.svg`;

	return (
		<button
			className={classes + ' loading-button'}
			onClick={action}
			disabled={isLoading}
		>
			{isLoading ? (
				<img src={svg} className='button-loading' alt='loading'></img>
			) : (
				text
			)}
		</button>
	);
};

export default LoadButton;
