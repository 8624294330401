import { Link } from 'react-router-dom';
import utility from '../../../utils/utility';
import Loading from '../../loading/Loading';

const MissingPayments = ({ missingPayments, isLoading }) => {
	return (
		<div className='global-container global-statistic-container global-payments-container'>
			<h1 className='global-statistic-title'>Manglende Betalinger</h1>

			{isLoading ? (
				<Loading />
			) : missingPayments === null ? (
				<div className='global-missing-payments-text-container'>
					<p className='global-missing-payments-text '>
						Noget gik galt...
					</p>
				</div>
			) : missingPayments.length === 0 ? (
				<div className='global-missing-payments-text-container'>
					<p className='global-missing-payments-text '>
						👍 Ingen manglende betalinger 👍
					</p>
				</div>
			) : (
				<div className='global-missing-payments-container'>
					<div className='missing-payment-item'>
						<p className='missing-payment-item-title'>Booking Id</p>
						<p className='missing-payment-item-title'>User</p>
						<p className='missing-payment-item-title'>Autocamper</p>
						<p className='missing-payment-item-title'>
							Organisation
						</p>
					</div>
					{missingPayments.map((payment, i) => {
						return (
							<div key={i} className='missing-payment-item'>
								<Link
									className='missing-payment-item-link'
									to={`/booking/${payment?.calender_object?.id}`}
								>
									{utility.getIdFormat(payment?.id)}
								</Link>
								<p>
									{payment?.calender_object?.users?.firstname}
								</p>
								<p>
									{payment?.calender_object?.autocamper?.name}
								</p>
								<p>
									{
										payment?.calender_object?.autocamper
											?.organization?.name
									}
								</p>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default MissingPayments;
