import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import Pagination from '../../pagination/Pagination';
import ConfirmDeposit from '../../popup/ConfirmDeposit';
import DepositRow from './DepositRow';
import './styles.css';

const DepositPage = () => {
	const [refunds, setRefunds] = useState(null);
	const [refundCount, setRefundCount] = useState(null);
	const [pageNumber, setPageNumber] = useState(0);
	const [sortBy, setSortBy] = useState('id');
	const [pageSize, setPageSize] = useState(10);
	const [order, setOrder] = useState('ASC');
	const [selectedIndex, setSelectedIndex] = useState(null);

	const [debounceRefundCount] = useDebounce(refundCount, 1000);

	const updateRefund = (index, refund) => {
		let newRefunds = [...refunds];
		newRefunds[index] = refund;
		setRefunds(newRefunds);
	};

	return (
		<>
			<Pagination
				url='/deposit-refunds/find'
				countUrl='/deposit-refunds/count'
				count={debounceRefundCount}
				setCount={setRefundCount}
				page={pageNumber}
				setPage={setPageNumber}
				pageSize={pageSize}
				setPageSize={setPageSize}
				setObjects={setRefunds}
				sortBy={sortBy}
				setSortBy={setSortBy}
				sortByList={[
					{ name: 'ID', value: 'id' },
					{ name: 'Lejer udbetalt', value: 'userPaid' },
					{ name: 'Udlejer udbetalt', value: 'lessorPaid' },
					{ name: 'Lejer depositum', value: 'userFund' },
					{ name: 'Udlejer depositum', value: 'lessorFund' },
				]}
				order={order}
				setOrder={setOrder}
			>
				{refunds !== null &&
					refunds.map((refund, i) => {
						return (
							<DepositRow
								refund={refund}
								key={i}
								clickHandler={() => setSelectedIndex(i)}
							/>
						);
					})}
			</Pagination>
			{selectedIndex !== null && (
				<ConfirmDeposit
					refund={refunds[selectedIndex]}
					toggle={() => setSelectedIndex(null)}
					updateRefund={(refund) =>
						updateRefund(selectedIndex, refund)
					}
				/>
			)}
		</>
	);
};

export default DepositPage;
