import { useRef, useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './styles.css';

import axios from '../../../api/axios';
import LoadButton from '../../loading/LoadButton';
import PublicStructure from '../../structures/public/PublicStructure';
const LOGIN_URL = '/auth/local';

const validRoles = ['lessor', 'admin'];

const Login = () => {
	const { setAuth } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || '/';

	const userRef = useRef();

	const [isLoading, setIsLoading] = useState(false);
	const [user, setUser] = useState('');
	const [pwd, setPwd] = useState('');
	const [errMsg, setErrMsg] = useState('');

	useEffect(() => {
		userRef.current.focus();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			const response = await axios.post(
				LOGIN_URL,
				JSON.stringify({ identifier: user, password: pwd }),
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);

			if (response?.status === 200 && response?.data) {
				setAuth(response?.data);

				const { user } = response.data;

				// Navigates back to the from page.
				if (validRoles.includes(user?.role?.type)) {
					if (from === '/') {
						navigate('/dashboard', { replace: true });
					} else {
						navigate(from, { replace: true });
					}
				} else {
					navigate('/new', { replace: true });
				}
			} else {
				setErrMsg('Error');
				setPwd('');
				setIsLoading(false);
			}
		} catch (err) {
			setErrMsg(
				'Brugernavn/Adgangskode forkert, eller du har ikke klikket på linket i bekræftigelsesemailen.'
			);
			setPwd('');
			setIsLoading(false);
		}
	};

	return (
		<PublicStructure color='white'>
			<article className='login-layout'>
				<section className='login-container'>
					<div className='login-information-container'>
						<h1 className='login-information-title'>Velkommen!</h1>
						<h2 className='login-information-subtext'>
							Du kan logge ind og se bookinger på alle dine
							autocampere.
						</h2>
					</div>
					<form
						onSubmit={handleSubmit}
						className='login-authenticate-container'
					>
						<h1 className='login-authenticate-title'>Log Ind</h1>
						<input
							type='text'
							id='username'
							ref={userRef}
							autoComplete='off'
							onChange={(e) => setUser(e.target.value)}
							value={user}
							required
							placeholder='Email eller brugernavn'
							className='login-authenticate-field'
						/>
						<input
							type='password'
							id='password'
							onChange={(e) => setPwd(e.target.value)}
							value={pwd}
							required
							placeholder='Adgangskode'
							className='login-authenticate-field'
						/>
						<LoadButton
							classes='login-authenticate-button'
							text='Log ind'
							isLoading={isLoading}
							color='white'
						/>
						<div className='login-authenticate-problem'>
							<div className='login-authenticate-problem-container'>
								<p>Nogle problemer?</p>
								<Link
									to='/help'
									className='login-authenticate-contact'
								>
									Hjælp!
								</Link>
							</div>
						</div>
						{errMsg !== '' && (
							<p className='login-error-message'>{errMsg}</p>
						)}
					</form>
				</section>
			</article>
		</PublicStructure>
	);
};

export default Login;
