import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';

const UserRow = ({ user }) => {
	return (
		<div className='user-row-item'>
			<div className='user-row-column-id user-row-column'>
				<Link to={`/user/${user?.id}`} className='user-row-text'>
					{user?.id}
				</Link>
			</div>
			<div className='user-row-column-name user-row-column'>
				<p className='user-row-text'>
					{user?.firstname} {user?.lastname}
				</p>
			</div>
			<div className='user-row-column-email user-row-column'>
				<p className='user-row-text'>{user?.email}</p>
			</div>
			<div className='user-row-column-confirmed user-row-column'>
				{user?.confirmed === true ? (
					<p className='user-row-text'>
						<span className='user-row-user-confirmed'>
							Bekræftet
						</span>
					</p>
				) : (
					<p className='user-row-text'>
						<span className='user-row-user-not-confirmed'>
							Ikke Bekræftet
						</span>
					</p>
				)}
			</div>
		</div>
	);
};

export default UserRow;
