import { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import utility from '../../../utils/utility';
import CancelBooking from '../../popup/cancelbooking';
import UpdateBooking from '../../popup/updatebooking';
import useAuth from '../../../hooks/useAuth';
import useToaster from '../../toaster/useToaster';
import './styles.css';
import NotesContainer from './NotesContainer';
import CreateNote from '../../popup/CreateNote';
import BookingDeposit from '../../popup/BookingDeposit';
import { Link } from 'react-router-dom';

const BookingPage = ({
	calenderObject,
	setCalenderObject,
	notes,
	notesError,
	addNote,
	displayCreateNote,
	setDisplayCreateNote,
	isAdmin,
	hasStarted,
}) => {
	const [today] = useState(new Date());
	const [nextPayment, setNextPayment] = useState(new Date());
	const [totalPrice, setTotalPrice] = useState(0);
	const [addonPrice, setAddonPrice] = useState(0);
	const [displayPopup, setDisplayPopup] = useState(false);
	const [displayCancel, setDisplayCancel] = useState(false);
	const [displayDeposit, setDisplayDeposit] = useState(false);
	const [emailCreatedSent, setEmailCreatedSent] = useState(false);
	const [emailCompleteSent, setEmailCompleteSent] = useState(false);
	const [emailPartOneSent, setEmailPartOneSent] = useState(false);
	const [emailPartTwoSent, setEmailPartTwoSent] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	useEffect(() => {
		function setupBooking(booking) {
			let tmpPrice = 0;
			booking.booking_payment.payment_addons.forEach((addon) => {
				tmpPrice += addon.price;
			});
			setAddonPrice(tmpPrice);
			setTotalPrice(
				booking.booking_payment.deposit +
					booking.booking_payment.startPrice +
					booking.booking_payment.autocamperPrice +
					booking.booking_payment.serviceFee +
					tmpPrice
			);
			const payment_number =
				booking.booking_payment.status === 'unpaid' ? 1 : 2;
			setNextPayment(
				utility.latestPaymentDateOfRate(
					booking.created_at,
					booking.start,
					booking.booking_payment.rate,
					payment_number
				)
			);
		}

		setupBooking(calenderObject);
	}, [calenderObject]);

	const sendCreatedEmail = async () => {
		setEmailCreatedSent(true);
		try {
			const response = await axios.post(
				`/email/booking/${calenderObject?.id}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
						'Content-Type': 'application/json',
					},
				}
			);

			if (response?.status === 200) {
				addToaster({
					title: 'Booking oprettet email',
					text: 'Emailen blev afsendt',
					type: 'success',
				});
			} else {
				addToaster({
					title: 'Booking oprettet email',
					text: 'Noget gik galt, og emailen blev ikke afsendt',
					type: 'error',
				});
				setEmailCreatedSent(false);
			}
		} catch (error) {
			addToaster({
				title: 'Booking oprettet email',
				text: 'Noget gik galt, og emailen blev ikke afsendt',
				type: 'error',
			});
			setEmailCreatedSent(false);
		}
	};

	const sendCompleteEmail = async () => {
		setEmailCompleteSent(true);
		try {
			const response = await axios.post(
				`/email/booking/${calenderObject?.id}/complete`,
				{},
				{
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
						'Content-Type': 'application/json',
					},
				}
			);

			if (response?.status === 200) {
				addToaster({
					title: 'Booking fuldt betalt email',
					text: 'Emailen blev afsendt',
					type: 'success',
				});
			} else {
				addToaster({
					title: 'Booking fuldt betalt email',
					text: 'Noget gik galt, og emailen blev ikke afsendt',
					type: 'error',
				});
				setEmailCompleteSent(false);
			}
		} catch (error) {
			addToaster({
				title: 'Booking fuldt betalt email',
				text: 'Noget gik galt, og emailen blev ikke afsendt',
				type: 'error',
			});
			setEmailCompleteSent(false);
		}
	};

	const sendPartOneEmail = async () => {
		setEmailPartOneSent(true);
		try {
			const response = await axios.post(
				`/email/booking/${calenderObject?.id}/partly`,
				{},
				{
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
						'Content-Type': 'application/json',
					},
				}
			);

			if (response?.status === 200) {
				addToaster({
					title: 'Booking første rate betalt email',
					text: 'Emailen blev afsendt',
					type: 'success',
				});
			} else {
				addToaster({
					title: 'Booking første rate betalt email',
					text: 'Noget gik galt, og emailen blev ikke afsendt',
					type: 'error',
				});
				setEmailPartOneSent(false);
			}
		} catch (error) {
			addToaster({
				title: 'Booking første rate betalt email',
				text: 'Noget gik galt, og emailen blev ikke afsendt',
				type: 'error',
			});
			setEmailPartOneSent(false);
		}
	};

	const sendPartTwoEmail = async () => {
		setEmailPartTwoSent(true);
		try {
			const response = await axios.post(
				`/email/booking/${calenderObject?.id}/complete`,
				{},
				{
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
						'Content-Type': 'application/json',
					},
				}
			);

			if (response?.status === 200) {
				addToaster({
					title: 'Booking anden rate betalt email',
					text: 'Emailen blev afsendt',
					type: 'success',
				});
			} else {
				addToaster({
					title: 'Booking anden rate betalt email',
					text: 'Noget gik galt, og emailen blev ikke afsendt',
					type: 'error',
				});
				setEmailPartTwoSent(false);
			}
		} catch (error) {
			addToaster({
				title: 'Booking anden rate betalt email',
				text: 'Noget gik galt, og emailen blev ikke afsendt',
				type: 'error',
			});
			setEmailPartTwoSent(false);
		}
	};

	const updateDepositRefund = (refund) => {
		let newCalenderObject = { ...calenderObject };
		newCalenderObject.booking_payment.deposit_refund = refund;
		setCalenderObject(newCalenderObject);
	};

	const yesterday = new Date(
		today.getFullYear(),
		today.getMonth(),
		today.getDate() - 1
	);

	const booking = calenderObject;

	return (
		<>
			<div className='booking-page-layout'>
				<div className='booking-page-grid-layout-first'>
					<div className='booking-page-container booking-page-outer-layout-autocamper'>
						<div className='booking-page-layout-autocamper'>
							<div className='booking-page-autocamper-detail-container'>
								<img
									src={utility.getAutocamperImage(
										booking?.autocamper?.avatar[0]
									)}
									alt='Autocamper Avatar'
									className='booking-page-autocamper-image'
								/>
								<h1>{booking?.autocamper?.name}</h1>
							</div>
							<div className='booking-page-autocamper-period'>
								<p className='booking-page-autocamper-time'>
									Start:{' '}
									<span>
										{utility.getClock(booking?.start)}{' '}
										{utility.getDate(booking?.start)}
									</span>
								</p>
								<p className='booking-page-autocamper-time'>
									Slut:{' '}
									<span>
										{utility.getClock(booking?.end)}{' '}
										{utility.getDate(booking?.end)}
									</span>
								</p>
							</div>
							<div className='booking-page-autocamper-period'>
								<p className='booking-page-autocamper-time'>
									Booking oprettet:{' '}
									<span>
										{utility.getClock(booking?.created_at)}{' '}
										{utility.getDate(booking?.created_at)}
									</span>
								</p>
							</div>
						</div>
						<div className='booking-page-autocamper-right-container'>
							<p className='booking-page-booking-id'>
								Booking Id:{' '}
								{utility.getIdFormat(
									booking?.booking_payment?.id
								)}
							</p>
							{isAdmin && !hasStarted && (
								<button
									className='booking-page-cancel-button'
									onClick={() => setDisplayCancel(true)}
								>
									Afmeld
								</button>
							)}
						</div>
					</div>
					<div className='booking-page-container booking-page-layout-user'>
						<h1>Lejer</h1>
						<div className='booking-page-user-detail-container'>
							<p>
								Navn:{' '}
								{booking?.users?.firstname +
									' ' +
									booking?.users?.lastname}
							</p>
							{auth?.user?.role?.type === 'admin' ? (
								<Link to={`/users/${booking?.users?.id}`}>
									Brugernavn: {booking?.users?.username}
								</Link>
							) : (
								<p>Brugernavn: {booking?.users?.username}</p>
							)}
							<p>Email: {booking?.users?.email}</p>
							<p>Telefon: {booking?.users?.phoneNumber}</p>
						</div>
					</div>
				</div>
				<div className='booking-page-grid-layout-second'>
					<div className='booking-page-container booking-page-layout-payment'>
						<h1>Beløb</h1>
						<div className='booking-page-payment-container'>
							<div className='booking-page-payment-row'>
								<p>Depositum</p>
								<p>
									{booking?.booking_payment?.deposit.toFixed(
										2
									)}{' '}
									DKK
								</p>
							</div>
							<div className='booking-page-payment-row'>
								<p>Startbeløb</p>
								<p>
									{booking?.booking_payment?.startPrice.toFixed(
										2
									)}{' '}
									DKK
								</p>
							</div>
							<div className='booking-page-payment-row'>
								<p>Leje</p>
								<p>
									{booking?.booking_payment?.autocamperPrice.toFixed(
										2
									)}{' '}
									DKK
								</p>
							</div>
							<div className='booking-page-payment-row'>
								<p>Servicegebyr</p>
								<p>
									{booking?.booking_payment?.serviceFee.toFixed(
										2
									)}{' '}
									DKK
								</p>
							</div>
							<div className='booking-page-payment-row'>
								<p>Tilkøb</p>
								<p>{addonPrice.toFixed(2)} DKK</p>
							</div>
							<div className='booking-page-payment-row booking-page-total-payment-row'>
								<p>Samlet</p>
								<p>{totalPrice.toFixed(2)} DKK</p>
							</div>
						</div>
					</div>
					<div className='booking-page-container booking-page-layout-status'>
						<div className='booking-page-status-payment-container'>
							<p>
								Rate:{' '}
								{booking?.booking_payment?.rate === 1
									? 'Hele beløb samlet'
									: 'Over 2 rater'}
							</p>
							<p>
								Betalingsmetode:{' '}
								{booking?.booking_payment?.paymentMethod}
							</p>
							<div className='booking-page-payment-status-container'>
								{booking?.booking_payment?.rate === 2 && (
									<>
										<div className='booking-page-payment-status-row'>
											<p>
												1. rate:{' '}
												{booking?.booking_payment?.deposit.toFixed(
													2
												)}{' '}
												DKK
											</p>
											{booking?.booking_payment
												?.status === 'unpaid' ? (
												<div className='booking-page-rate-status booking-page-rate-status-fail'>
													<img
														src='/cross.svg'
														alt='failed'
													/>
												</div>
											) : (
												<div className='booking-page-rate-status booking-page-rate-status-success'>
													<img
														src='/check.svg'
														alt='checkmark'
													/>
												</div>
											)}
										</div>
										<div className='booking-page-payment-status-row'>
											<p>
												2. rate:{' '}
												{(
													totalPrice -
													booking?.booking_payment
														?.deposit
												).toFixed(2)}{' '}
												DKK
											</p>
											{booking?.booking_payment
												?.status === 'paid' ? (
												<div className='booking-page-rate-status booking-page-rate-status-success'>
													<img
														src='/check.svg'
														alt='checkmark'
													/>
												</div>
											) : (
												<div className='booking-page-rate-status booking-page-rate-status-fail'>
													<img
														src='/cross.svg'
														alt='failed'
													/>
												</div>
											)}
										</div>
									</>
								)}
								{booking.booking_payment.rate === 1 && (
									<div className='booking-page-payment-status-row'>
										<p>
											1. rate: {totalPrice.toFixed(2)} DKK
										</p>
										{booking?.booking_payment?.status ===
										'paid' ? (
											<div className='booking-page-rate-status booking-page-rate-status-success'>
												<img
													src='/check.svg'
													alt='checkmark'
												/>
											</div>
										) : (
											<div className='booking-page-rate-status booking-page-rate-status-fail'>
												<img
													src='/cross.svg'
													alt='failed'
												/>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
						<div className='booking-page-status-update-container'>
							{booking?.booking_payment?.status === 'unpaid' && (
								<div className='booking-page-status-container booking-page-status-container-unpaid'>
									<p>Ikke betalt</p>
								</div>
							)}
							{booking?.booking_payment?.status === 'partly' && (
								<div className='booking-page-status-container booking-page-status-container-partly'>
									<p>Delvist betalt</p>
								</div>
							)}
							{booking?.booking_payment?.status === 'paid' && (
								<div className='booking-page-status-container booking-page-status-container-paid'>
									<p>Betalt</p>
								</div>
							)}
							{booking?.booking_payment?.status !== 'paid' && (
								<>
									<p>
										Næste betaling senest:{' '}
										<span className='latest-next-payment'>
											{nextPayment.getDate()}/
											{nextPayment.getMonth() + 1}-
											{nextPayment.getFullYear()}
										</span>
									</p>
									{yesterday.getTime() >= nextPayment && (
										<span className='latest-next-payment-warning'>
											DATO OVERSKREDET!
										</span>
									)}
									{isAdmin && (
										<button
											className='booking-page-status-update-button'
											onClick={() =>
												setDisplayPopup(true)
											}
										>
											Opdater
										</button>
									)}
								</>
							)}
						</div>
					</div>
				</div>
				<div className='booking-page-grid-layout-third'>
					<div className='booking-page-container booking-page-layout-addons'>
						<h1>Tilkøb</h1>
						<div className='booking-page-addon-container'>
							{booking?.booking_payment?.payment_addons.length ===
							0 ? (
								<p>Ingen tilkøb</p>
							) : (
								booking?.booking_payment?.payment_addons?.map(
									(addon, i) => {
										return (
											<div
												className='booking-page-addon-row'
												key={i}
											>
												<p className='booking-page-addon-name'>
													{addon?.addon?.name}
												</p>
												<p>
													{addon?.addon?.description}
												</p>
												<p>
													{addon?.price.toFixed(2)}{' '}
													DKK
												</p>
											</div>
										);
									}
								)
							)}
						</div>
					</div>
				</div>
				<div className='booking-page-grid-layout-fourth'>
					<NotesContainer
						notes={notes}
						notesError={notesError}
						createNote={() => setDisplayCreateNote(true)}
					/>
				</div>
				{booking?.booking_payment?.deposit_refund && (
					<div className='booking-page-grid-layout-sixth'>
						<div className='booking-page-container'>
							<h1>Depositum</h1>
							{booking?.booking_payment?.deposit_refund
								?.lessorFund === null ? (
								<div className='booking-page-deposit-ready-container'>
									<p>
										Booking færdig, opret
										depositumrefundering.
									</p>
									<button
										className='booking-page-deposit-button'
										onClick={() => setDisplayDeposit(true)}
									>
										Opret
									</button>
								</div>
							) : (
								<div className='booking-page-deposit-detail-container'>
									<div className='booking-page-deposit-detail-cell booking-page-deposit-detail-lessor'>
										<p>
											Lejer:{' '}
											<span className='booking-page-highlight-text'>
												{booking?.booking_payment?.deposit_refund?.lessorFund.toFixed(
													2
												)}{' '}
												DKK
											</span>
										</p>
									</div>
									<div className='booking-page-deposit-detail-cell booking-page-deposit-detail-user'>
										<p>
											Lejer:{' '}
											<span className='booking-page-highlight-text'>
												{booking?.booking_payment?.deposit_refund?.userFund.toFixed(
													2
												)}{' '}
												DKK
											</span>
										</p>
									</div>
									<div className='booking-page-deposit-detail-cell booking-page-deposit-detail-paid'>
										<p>
											Udbetalt:{' '}
											<span className='booking-page-highlight-text'>
												{booking?.booking_payment
													?.deposit_refund
													?.lessorPaid === true
													? 'Ja'
													: 'Nej'}{' '}
											</span>
										</p>
									</div>
									<div className='booking-page-deposit-detail-description'>
										<p>
											Beskrivelse:{' '}
											<span className='booking-page-highlight-text'>
												{booking?.booking_payment
													?.deposit_refund
													?.depositDescription
													? booking?.booking_payment
															?.deposit_refund
															?.depositDescription
													: 'Ingen Beskrivelse...'}
											</span>
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
				{isAdmin && (
					<div className='booking-page-grid-layout-fifth'>
						<div className='booking-page-container'>
							<h1>Gensend Email</h1>
							<div className='booking-page-layout-emails'>
								<button
									className='booking-page-email-button'
									disabled={emailCreatedSent}
									onClick={() => sendCreatedEmail()}
								>
									Gensend booking oprettet email
								</button>
								{booking?.booking_payment?.rate &&
									booking?.booking_payment?.status !==
										'unpaid' &&
									(booking?.booking_payment?.rate === 1 ? (
										<button
											className='booking-page-email-button'
											disabled={emailCompleteSent}
											onClick={() => sendCompleteEmail()}
										>
											Gensend modtaget fuld betaling email
										</button>
									) : (
										<>
											<button
												className='booking-page-email-button'
												disabled={emailPartOneSent}
												onClick={() =>
													sendPartOneEmail()
												}
											>
												Gensend modtaget depositum email
											</button>
											{booking?.booking_payment
												?.status !== 'unpaid' && (
												<button
													className='booking-page-email-button'
													disabled={emailPartTwoSent}
													onClick={() =>
														sendPartTwoEmail()
													}
												>
													Gensend modtaget resterende
													betaling email
												</button>
											)}
										</>
									))}
							</div>
						</div>
					</div>
				)}
			</div>
			{isAdmin && displayPopup && (
				<UpdateBooking
					toggle={() => setDisplayPopup(false)}
					id={booking?.id}
					status={booking?.booking_payment?.status}
					rate={booking?.booking_payment?.rate}
				/>
			)}
			{isAdmin && displayCancel && (
				<CancelBooking
					toggle={() => setDisplayCancel(false)}
					id={booking?.id}
					autocamperId={booking?.autocamper?.id}
				/>
			)}
			{displayCreateNote && (
				<CreateNote
					toggle={() => setDisplayCreateNote(false)}
					id={calenderObject?.id}
					addNote={addNote}
				/>
			)}
			{displayDeposit && (
				<BookingDeposit
					toggle={() => setDisplayDeposit(false)}
					id={calenderObject?.booking_payment?.deposit_refund?.id}
					depositAmount={calenderObject?.booking_payment?.deposit}
					setRefund={(refund) => updateDepositRefund(refund)}
				/>
			)}
		</>
	);
};

export default BookingPage;
