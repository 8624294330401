import { createContext, useCallback, useState } from 'react';

const ToasterContext = createContext({});

export const ToasterProvider = ({ children }) => {
	const [toasters, setToasters] = useState([]);
	const [displayToasters, setDisplayToasters] = useState(false);
	const [toasterNotify, setToasterNotify] = useState(false);

	const addToaster = useCallback((toaster) => {
		toaster.time = new Date();
		setToasters((prevToasters) => [...prevToasters, toaster]);
		setToasterNotify(true);
	}, []);

	const removeToaster = useCallback((index) => {
		setToasters((prevToasters) => {
			const newToasters = [...prevToasters];
			newToasters.splice(index, 1);
			if (newToasters.length === 0) setDisplayToasters(false);
			return newToasters;
		});
	}, []);

	const showToasters = () => {
		setDisplayToasters(true);
		setToasterNotify(false);
	};

	const hideToasters = () => {
		setDisplayToasters(false);
	};

	return (
		<ToasterContext.Provider
			value={{
				toasters,
				addToaster,
				removeToaster,
				toasterNotify,
				displayToasters,
				showToasters,
				hideToasters,
			}}
		>
			{children}
		</ToasterContext.Provider>
	);
};

export default ToasterContext;
