import utility from '../../utils/utility';
import { Link } from 'react-router-dom';

const ToasterItem = ({ toaster, remove }) => {
	return (
		<div
			className={`toaster-item ${
				toaster?.type ? `toaster-item-${toaster.type}` : ''
			}`}
		>
			<div className='toaster-details-container'>
				<h3 className='toaster-title'>{toaster?.title}</h3>
				<span className='toaster-time'>
					{`${utility.getClockExact(toaster?.time)} ${utility.getDate(
						toaster?.time
					)}`}
				</span>
				<p className='toaster-text'>{toaster?.text}</p>
				{toaster?.link && (
					<Link to={toaster.link} className='toaster-link'>
						{toaster?.linkText ? toaster.linkText : 'Link'}
					</Link>
				)}
			</div>
			<div
				className='toaster-closer'
				onClick={() => {
					remove();
				}}
			>
				<img src='/cross.svg' alt='Closer' />
			</div>
		</div>
	);
};

export default ToasterItem;
