import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import './styles.css';
import useAuth from '../../../hooks/useAuth';
import axios from '../../../api/axios';
import LoadingIcon from '../../loading/LoadingIcon';
import { Link } from 'react-router-dom';
import utility from '../../../utils/utility';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';

const IncomePage = () => {
	const [month, setMonth] = useState(null);
	const [statistics, setStatistics] = useState(0);
	const [rate, setRate] = useState(0.8);
	const [bookingCount, setBookingCount] = useState(0);
	const [revenue, setRevenue] = useState(null);
	const [income, setIncome] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [organizationIncome, setOrganizationIncome] = useState(null);
	const [incomeIsLoading, setIncomeIsLoading] = useState(true);

	const { auth } = useAuth();

	const startMonth = month !== null ? month : null;
	const lastDayInMonth =
		month !== null
			? new Date(Date.UTC(month.getFullYear(), month.getMonth() + 1, 0))
			: null;

	/**
	 * The debounce variable.
	 */
	const debouncedMonth = useMemo(() => {
		const fetchHandler = async () => {
			fetchStatistics();
			fetchOrganizationIncome();
		};

		/**
		 * Fetch the statistics.
		 */
		const fetchStatistics = async () => {
			const endMonth = new Date(
				Date.UTC(month.getFullYear(), month.getMonth() + 1, 1)
			);
			const lastDayInMonth = new Date(
				Date.UTC(month.getFullYear(), month.getMonth() + 1, 0)
			);

			try {
				const response = await axios.get(
					`/organization-statistics?start=${startMonth.getTime()}&end=${endMonth.getTime()}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);

				if (response?.status === 200) {
					setRate(0.8);
					if (response?.data.length > 0) {
						let revenue = 0;
						let income = 0;
						let bookingCount = 0;
						response?.data.forEach((stat) => {
							revenue += stat.income;
							income += stat.income;
							bookingCount += stat.bookingCount;
						});

						setIncome(income);
						setRevenue(revenue * rate);
						setBookingCount(bookingCount);
					} else {
						setError('No entries was retreived');
						setRevenue(0);
						setIncome(0);
						setBookingCount(0);
					}

					const daysInMonth = lastDayInMonth.getDate();
					let statistics = response?.data;
					while (statistics.length <= daysInMonth) {
						statistics.push({
							date: `--${statistics.length}`,
							income: 0,
						});
					}
					setStatistics(statistics);
				}
			} catch (error) {
				setError('Something when wrong...');
			}

			setIsLoading(false);
		};

		const fetchOrganizationIncome = async () => {
			try {
				const response = await axios.get(
					`/organization-income?month=${startMonth.getTime()}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
					}
				);
				if (response?.status === 200) {
					setOrganizationIncome(response?.data);
					setRate(response?.data?.incomeRate);
				}
			} catch (error) {}
			setIncomeIsLoading(false);
		};

		return debounce(fetchHandler, 1000);
	}, [month, auth, startMonth, rate]);

	/**
	 * Handler for the debounce.
	 */
	const debouncedMonthHandler = () => {
		resetState();
		return debouncedMonth();
	};

	useEffect(() => {
		const today = new Date();
		const startMonth = new Date(
			Date.UTC(today.getFullYear(), today.getMonth(), 1)
		);

		setMonth(startMonth);
	}, []);

	useEffect(() => {
		debouncedMonth();

		return () => {
			debouncedMonth.cancel();
		};
	}, [debouncedMonth]);

	/**
	 * Resets to the loading state before starting to fetch.
	 */
	const resetState = () => {
		setIsLoading(true);
		setError(null);
		setStatistics(null);
		setIncomeIsLoading(true);
		setOrganizationIncome(null);
	};

	/**
	 * Goes back a month.
	 */
	const handleBackButton = () => {
		setMonth(
			(prevMonth) =>
				new Date(
					Date.UTC(prevMonth.getFullYear(), prevMonth.getMonth() - 1)
				)
		);
		debouncedMonthHandler();
	};

	/**
	 * Goes forward a month.
	 */
	const handleForwardButton = () => {
		setMonth((prevMonth) => {
			const today = new Date();
			const newMonth = new Date(
				Date.UTC(prevMonth.getFullYear(), prevMonth.getMonth() + 1)
			);

			return newMonth < today ? newMonth : prevMonth;
		});
		debouncedMonthHandler();
	};

	return (
		<div className='income-page-layout'>
			<section className='container income-page-main-container'>
				<div className='income-page-main-header'>
					<button
						onClick={handleBackButton}
						className='home-calender-header-button'
					>
						Tilbage
					</button>
					<h1>
						{month !== null &&
							month.getUTCMonth() +
								1 +
								'/' +
								month.getUTCFullYear()}
					</h1>
					<button
						onClick={handleForwardButton}
						className='home-calender-header-button'
					>
						Frem
					</button>
				</div>
			</section>
			<section className='container income-page-graph-container income-page-container'>
				{isLoading ? (
					<div className='income-page-loading'>
						<LoadingIcon />
					</div>
				) : error !== null ? (
					error
				) : (
					<div className='income-page-container-content'>
						<h1>Graf</h1>
						<VictoryChart
							className='test'
							theme={VictoryTheme.material}
							width={500}
							height={300}
							padding={{
								left: 70,
								right: 20,
								top: 50,
								bottom: 60,
							}}
							minDomain={{ y: 0 }}
						>
							<VictoryBar
								x='date'
								y='income'
								data={statistics}
								style={{ data: { fill: '#2d7163' } }}
								cornerRadius={{ top: 5 }}
								barWidth={8}
							/>
							<VictoryAxis
								tickFormat={(x) => {
									const res = x.split('-');
									if (res[2].charAt(0) === '0') {
										return res[2].charAt(1);
									} else {
										return res[2];
									}
								}}
							/>
							<VictoryAxis
								dependentAxis
								tickFormat={(x) => {
									return x !== null
										? x >= 1000
											? `${x / 1000}K DKK`
											: `${x} DKK`
										: '';
								}}
							/>
						</VictoryChart>
					</div>
				)}
			</section>
			<section className='container income-page-bookings-container income-page-container'>
				{isLoading ? (
					<div className='income-page-loading'>
						<LoadingIcon />
					</div>
				) : error !== null ? (
					error
				) : (
					<div className='income-page-container-content'>
						<h1>Antal Bookinger</h1>
						<p>
							Perioden:{' '}
							<span className='income-page-highlight-text'>
								{startMonth.getDate()}/
								{startMonth.getMonth() + 1}/
								{startMonth.getFullYear()}
								{' - '}
								{lastDayInMonth.getDate()}/
								{lastDayInMonth.getMonth() + 1}/
								{lastDayInMonth.getFullYear()}
							</span>
						</p>
						<p>
							Bookinger:{' '}
							<span className='income-page-highlight-text'>
								{bookingCount}
							</span>
						</p>
					</div>
				)}
			</section>
			<section className='container income-page-revenue-container income-page-container'>
				{isLoading || incomeIsLoading ? (
					<div className='income-page-loading'>
						<LoadingIcon />
					</div>
				) : error !== null ? (
					error
				) : (
					<div className='income-page-container-content'>
						<h1>Indkomst genereret</h1>
						<p>
							Total:{' '}
							<span className='income-page-highlight-text'>
								{income.toFixed(2)} DKK
							</span>
						</p>
						<p>
							{organizationIncome === null
								? 'Estimeret Rate'
								: 'Rate'}
							:{' '}
							<span className='income-page-highlight-text'>
								{`${(rate * 100).toFixed(2)}%`}
							</span>
						</p>
						<p>
							{organizationIncome === null
								? 'Estimeret Profit'
								: 'Profit'}
							:{' '}
							<span className='income-page-highlight-text'>
								{revenue.toFixed(2)} DKK
							</span>
						</p>
					</div>
				)}
			</section>
			<section className='container income-page-download-container income-page-container'>
				<h1>Indkomstoversigt</h1>
				{incomeIsLoading ? (
					<div className='income-page-loading'>
						<LoadingIcon />
					</div>
				) : organizationIncome === null ? (
					<div className='income-page-download-content'>
						<p className='income-page-highlight-text'>
							Indkomsten bliver beregnet den 1. i måneden.
						</p>
					</div>
				) : (
					<>
						<div className='income-page-download-content'>
							<p>
								Fra:{' '}
								<span className='income-page-highlight-text'>
									{organizationIncome?.from}
								</span>
							</p>
							<p>
								Til og med:{' '}
								<span className='income-page-highlight-text'>
									{organizationIncome?.to}
								</span>
							</p>
							<p>
								Udbetalt:{' '}
								<span className='income-page-highlight-text'>
									{organizationIncome?.paid ? 'Ja' : 'Nej'}
								</span>
							</p>
						</div>
						<div className='income-page-bookings-list'>
							{organizationIncome?.booking_payments?.length ===
							0 ? (
								<p
									className='income-page-highlight-text'
									style={{ textAlign: 'center' }}
								>
									Ingen bookinger denne måned
								</p>
							) : (
								organizationIncome?.booking_payments.map(
									(bookingPayment, i) => {
										let totalPrice =
											bookingPayment?.startPrice +
											bookingPayment?.autocamperPrice;
										bookingPayment?.payment_addons.forEach(
											(addon) => {
												totalPrice += addon.price;
											}
										);

										const startDate =
											utility.createDateFromResponse(
												bookingPayment?.calender_object
													?.start
											);

										return (
											<div
												className='income-page-booking-item'
												key={i}
											>
												<p>
													Booking ID:{' '}
													<Link
														to={`/booking/${bookingPayment?.calender_object?.id}`}
														className='income-page-highlight-text'
													>
														{utility.getIdFormat(
															bookingPayment?.id
														)}
													</Link>
												</p>
												<p>
													Starttidspunkt:{' '}
													<span className='income-page-highlight-text'>
														{utility.getClock(
															startDate
														)}{' '}
														{utility.getDate(
															startDate
														)}
													</span>
												</p>
												<p>
													Profit:{' '}
													<span className='income-page-highlight-text'>
														{(
															totalPrice *
															organizationIncome?.incomeRate
														).toFixed(2)}{' '}
														DKK
													</span>
												</p>
												<p>
													Total:{' '}
													<span className='income-page-highlight-text'>
														{totalPrice.toFixed(2)}{' '}
														DKK
													</span>
												</p>
											</div>
										);
									}
								)
							)}
						</div>
					</>
				)}
			</section>
		</div>
	);
};

export default IncomePage;
