const maxFileSize = 2097152;
const validFileTypes = ['image/jpeg'];
const maxNumberOfFiles = 30;

const fileOperator = {
	validateFileSize(file) {
		return maxFileSize > file.size;
	},

	validateFileType(file) {
		return validFileTypes.includes(file.type);
	},

	validateFile(file) {
		return this.validateFileSize(file) && this.validateFileType(file);
	},

	getMaxNumberOfFiles() {
		return maxNumberOfFiles;
	},

	resizeInputFile(file, onComplete, onError) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.name = file.name;
		reader.size = file.size;
		reader.onload = (readerEvent) => {
			let img = new Image();
			img.src = readerEvent.target.result;
			img.size = readerEvent.size;
			img.onload = (imageEvent) => {
				let elem = document.createElement('canvas');

				const MAX_SIZE = 1920;
				let newWidth = imageEvent.target.width;
				let newHeight = imageEvent.target.height;

				if (newWidth > MAX_SIZE || newHeight > MAX_SIZE) {
					const ratio = newHeight / newWidth;

					if (newHeight > newWidth) {
						newHeight = MAX_SIZE;
						newWidth = newHeight / ratio;
					} else {
						newWidth = MAX_SIZE;
						newHeight = newWidth * ratio;
					}
				}
				elem.width = newWidth;
				elem.height = newHeight;

				let ctx = elem.getContext('2d');
				ctx.drawImage(imageEvent.target, 0, 0, elem.width, elem.height);
				ctx.canvas.toBlob((blob) => {
					onComplete(blob);
				}, 'image/jpeg');
			};

			img.onerror = () => {
				onError();
			};
		};
	},
};

export default fileOperator;
