import React from 'react';
import { Link } from 'react-router-dom';
import ActiveTag from './pages/autocamper/ActiveTag';

export default function Autocamper(props) {
	const { name, avatar, id, active } = props.autocamper;

	return (
		<div className='autocamper'>
			<img
				className='autocamper-image'
				src={avatar[0]?.formats?.thumbnail?.url}
				alt='Autocamper'
			/>
			<div className='autocamper-name-container'>
				<h2 className='autocamper-name'>{name}</h2>
				<ActiveTag active={active} />
			</div>
			<div className='autocamper-function-div'>
				<Link to={`/autocamper/${id}/calender`}>
					<button className='square-button'>
						<img src='calender.svg' alt='Booking' />
					</button>
				</Link>
				<Link to={`/autocamper/${id}`}>
					<button className='square-button'>
						<img src='settings.svg' alt='Edit' />
					</button>
				</Link>
			</div>
		</div>
	);
}
