import axios from '../../../api/axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Calender from './calender/calender';
import CreateCalenderObject from '../../popup/createcalenderobject';
import React from 'react';
import Loading from '../../loading/Loading';
import useToaster from '../../toaster/useToaster';

export const AutocamperCalenderPage = (props) => {
	const [autocamper, setAutocamper] = useState({});
	const [createCOPopupStatus, setCreateCOPopupStatus] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const { id } = useParams();
	const { auth } = useAuth();
	const { addToaster } = useToaster();

	useEffect(() => {
		var isMounted = true;
		const controller = new AbortController();

		async function fetchAutocamper() {
			try {
				const response = await axios.get(`autocampers/${id}/admin`, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response?.status === 200 && response?.data) {
					if (isMounted) {
						setAutocamper(response.data);
					}
				} else {
					addToaster({
						title: 'Fetching Autocamper',
						text: 'Error fetching autocamper',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Fetching Autocamper',
					text: 'Error fetching autocamper',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		}

		fetchAutocamper();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [props, id, auth, addToaster]);

	return isLoading ? (
		<Loading />
	) : !autocamper?.id ? (
		<p>404</p>
	) : (
		<>
			<div className='vertical-layout'>
				<div className='autocamper-header container'>
					<h1 className='header-title'>{autocamper.name}</h1>
					<button
						className='circle-button'
						onClick={() => {
							setCreateCOPopupStatus(new Date());
						}}
					>
						+
					</button>
				</div>
				<div className='container'>
					<Calender createCO={setCreateCOPopupStatus} />
				</div>
			</div>
			{createCOPopupStatus && (
				<CreateCalenderObject
					toggle={setCreateCOPopupStatus}
					date={createCOPopupStatus}
					id={id}
				/>
			)}
		</>
	);
};
