import { Link } from 'react-router-dom';
import PublicStructure from '../../structures/public/PublicStructure';
import './styles.css';

const LandingPage = () => {
	return (
		<PublicStructure headerBackground='green'>
			<section className='landing-page-hero-section'>
				<div className='public-first-section landing-page-hero-section-no-colored-background'></div>
				<div className='public-first-section landing-page-hero-section-colored-background'></div>
				<div className='landing-page-hero-section-content-container'>
					<div className='landing-page-hero-section-text-container'>
						<div className='landing-page-hero-section-text-wrapper'>
							<h1 className='landing-page-hero-section-title'>
								UDLEJ
							</h1>
							<h2 className='landing-page-hero-section-subtitle'>
								Tjen penge idag
							</h2>
							<p className='landing-page-hero-section-text'>
								Har du en autocamper og er den stående et par
								uger om året, så har du mulighed for at udleje
								den og tjene op imod 13000 DKK/uge. Det eneste
								der kræves er at du opretter din autocamper, og
								så kan lejere finde dig.
							</p>
							<Link
								to='/signup'
								className='landing-page-hero-section-create-button'
							>
								Opret nu
							</Link>
						</div>
					</div>
					<div className='landing-page-hero-section-illustration-container'>
						<img
							src='./illustration.png'
							className='landing-page-hero-section-illustration'
						/>
					</div>
				</div>
			</section>
		</PublicStructure>
	);
};

export default LandingPage;
