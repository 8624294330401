import React, { Component } from 'react';
import LoadButton from '../loading/LoadButton';
import './styles.css';

class Popup extends Component {
	state = {};

	handleClick = () => {
		this.props.toggle();
	};

	render() {
		const { name, action, actionRunning } = this.props;

		return (
			<div className='popup-background'>
				<div className='popup-window'>
					<div className='popup-header'>
						<p>{name}</p>
						{!actionRunning && (
							<span
								className='popup-closer'
								onClick={this.handleClick}
							>
								X
							</span>
						)}
					</div>
					<div className='popup-content'>{this.props.children}</div>
					<div className='popup-footer'>
						<LoadButton
							classes='popup-create-button'
							text={
								this.props.buttonText
									? this.props.buttonText
									: 'Opret'
							}
							isLoading={actionRunning}
							color='white'
							action={action}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Popup;
