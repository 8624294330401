import { useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';
import UpdateAutocamperField from './UpdateAutocamperField';

const UpdateAutocamper = ({ toggle, autocamper, setAutocamper }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [updateName, setUpdateName] = useState(false);
	const [newName, setNewName] = useState('');
	const [updateDescription, setUpdateDescription] = useState(false);
	const [newDescription, setNewDescription] = useState('');
	const [updatePrice, setUpdatePrice] = useState(false);
	const [newPrice, setNewPrice] = useState('');
	const [updateStartPrice, setUpdateStartPrice] = useState(false);
	const [newStartPrice, setNewStartPrice] = useState('');
	const [updateAvatar, setUpdateAvatar] = useState(false);
	const [newAvatar, setNewAvatar] = useState(null);
	const [updateImages, setUpdateImages] = useState(false);
	const [newImages, setNewImages] = useState(null);
	const [updateAddons, setUpdateAddons] = useState(false);
	const [newAddons, setNewAddons] = useState([]);
	const [updateTags, setUpdateTags] = useState(false);
	const [newTags, setNewTags] = useState([]);
	const [updateActive, setUpdateActive] = useState(false);
	const [newIsActive, setNewIsActive] = useState(false);

	const { addToaster } = useToaster();
	const { auth } = useAuth();

	const updateAutocamper = async () => {
		let data = {};
		if (updateName && newName.length > 0) data.name = newName;
		if (updateDescription && newDescription.length > 0)
			data.description = newDescription;
		if (updatePrice && newPrice > 0) data.price = parseFloat(newPrice);
		if (updateStartPrice && newStartPrice > 0)
			data.startPrice = parseFloat(newStartPrice);
		if (updateAddons) {
			data.addons = newAddons;
		}
		if (updateTags) {
			data.tags = newTags;
		}
		if (updateActive) {
			data.active = newIsActive;
		}

		setIsLoading(true);

		const formData = new FormData();
		formData.append('data', JSON.stringify(data));
		if (updateAvatar && newAvatar !== null) {
			formData.append(`files.avatar`, newAvatar[0], newAvatar[0].name);
		}
		if (updateImages && newImages !== null) {
			formData.append(`files.images`, []);
			for (let i = 0; i < newImages.length; i++) {
				const img = newImages[i];
				formData.append(`files.images`, img, img.name);
			}
		}

		try {
			const response = await axios.put(
				`/autocampers/${autocamper?.id}`,
				formData,
				{
					headers: {
						authorization: `Bearer ${auth?.jwt}`,
						'Content-type': 'multipart/form-data',
					},
				}
			);

			if (response.status === 200) {
				toggle();
				setAutocamper(response?.data);
				addToaster({
					title: 'Updating Autocamper',
					text: 'Successfully updating autocamper',
					type: 'success',
				});
			}
		} catch (error) {
			addToaster({
				title: 'Updating Autocamper',
				text: 'Something went wrong updating the autocamper',
				type: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	const toggleTag = (tag, newValue = null) => {
		const tags = [...newTags];
		const index = tags.indexOf(tags.find((t) => t.name === tag.name));
		if (index !== -1) {
			if (newValue !== null) {
				tags[index].value = newValue;
			} else {
				tags.splice(index, 1);
			}
		} else {
			tags.push({
				name: tag.name,
				value: tag?.type === 'enum' ? tag?.values[0] : null,
			});
		}
		setNewTags(tags);
	};

	const toggleAddon = (addon) => {
		const addons = [...newAddons];
		const index = addons.indexOf(addon);
		if (index !== -1) {
			addons.splice(index, 1);
		} else {
			addons.push(addon);
		}

		setNewAddons(addons);
	};

	return (
		<Popup
			actionRunning={isLoading}
			toggle={toggle}
			action={updateAutocamper}
			name='Opdater Autocamper'
			buttonText='Opdater'
		>
			<div className='update-autocamper-field-list'>
				<UpdateAutocamperField
					show={updateName}
					setShow={setUpdateName}
					name='Navn'
					placeholder={'Nyt Navn'}
					type='text'
					prevValue={autocamper?.name}
					value={newName}
					setValue={setNewName}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateDescription}
					setShow={setUpdateDescription}
					name='Beskrivelse'
					placeholder={'Ny Beskrivelse'}
					type='textarea'
					prevValue={autocamper?.description}
					value={newDescription}
					setValue={setNewDescription}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updatePrice}
					setShow={setUpdatePrice}
					name='Pris'
					placeholder={'Ny Pris'}
					type='number'
					prevValue={autocamper?.price + ' DKK'}
					value={newPrice}
					setValue={setNewPrice}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateStartPrice}
					setShow={setUpdateStartPrice}
					name='Startpris'
					placeholder={'Ny Startpris'}
					type='number'
					prevValue={autocamper?.startPrice + ' DKK'}
					value={newStartPrice}
					setValue={setNewStartPrice}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateAvatar}
					setShow={setUpdateAvatar}
					name='Avatar'
					type='file'
					prevValue={''}
					value={newAvatar}
					setValue={setNewAvatar}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateImages}
					setShow={setUpdateImages}
					name='Billeder'
					type='files'
					prevValue={''}
					value={newImages}
					setValue={setNewImages}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateAddons}
					setShow={setUpdateAddons}
					name='Tilkøb'
					type='addons'
					prevValue={autocamper.addons}
					value={newAddons}
					setValue={toggleAddon}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateTags}
					setShow={setUpdateTags}
					name='Tags'
					type='tags'
					prevValue={autocamper?.autocamper_tags}
					value={newTags}
					setValue={toggleTag}
					autocamper={autocamper}
				/>
				<UpdateAutocamperField
					show={updateActive}
					setShow={setUpdateActive}
					name='Aktiv'
					type='boolean'
					prevValue={autocamper.active}
					value={newIsActive}
					setValue={setNewIsActive}
					autocamper={autocamper}
				/>
			</div>
		</Popup>
	);
};

export default UpdateAutocamper;
