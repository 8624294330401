import utility from '../../../utils/utility';
import Loading from '../../loading/Loading';

const NotesContainer = ({ notes, notesError, createNote }) => {
	return (
		<div className='booking-page-container'>
			<div className='booking-page-notes-header'>
				<h1 className='booking-page-notes-title'>Noter</h1>
				<button className='circle-button' onClick={() => createNote()}>
					+
				</button>
			</div>
			<div className='booking-page-notes-container'>
				{notesError === null ? (
					notes === null ? (
						<Loading />
					) : notes?.length === 0 ? (
						<p>Ingen noter</p>
					) : (
						notes.map((note, i) => {
							return (
								<div key={i} className='booking-page-note-item'>
									<div className='booking-page-note-item-header'>
										<p className='booking-page-note-user'>
											{`${note?.user?.firstname} ${note?.user?.lastname}`}
										</p>
										<span className='booking-page-note-time'>
											{utility.getClock(note?.created_at)}{' '}
											{utility.getDate(note?.created_at)}
										</span>
									</div>
									<p className='booking-note-text'>
										{note?.note}
									</p>
								</div>
							);
						})
					)
				) : (
					<p className='booking-note-error'>{notesError}</p>
				)}
			</div>
		</div>
	);
};

export default NotesContainer;
