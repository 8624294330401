import React from 'react';
import CalenderDay from './Calenderday';

class Calender extends React.Component {
	render() {
		const {
			weeks,
			selectedWeeks,
			updateValue,
			updateTime,
			showWeekInterval,
			toggleUpdate,
			updateNewValue,
			updateNewTime,
			showValue,
			updateDeliveryDay,
		} = this.props;

		return (
			<React.Fragment>
				<div className='week-selection-container'>
					<span
						className='week-selector'
						style={{
							left: `calc(4rem + ((100% - 5rem) / 11) * ${selectedWeeks} + ((100% - 5rem) / 22 - 1.5rem))`,
						}}
					></span>
					<div className='week-container'>
						{weeks.map((week, i) => {
							return (
								<div className='week-item' key={week.name}>
									<div
										className='week-item-text-container'
										onClick={() => showWeekInterval(i)}
									>
										<span
											className={
												selectedWeeks === i
													? 'week-item-text week-item-text-selected'
													: 'week-item-text'
											}
										>
											{week.name}
										</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className='container days-container'>
					{weeks[selectedWeeks].innerWeek.map((week) => {
						return (
							<div className='week-row' key={week.number}>
								<div className='week-row-title-container'>
									<p>Uge {week.number}</p>
								</div>
								{week.days.map((day) => {
									return (
										<CalenderDay
											day={day}
											key={day.weekday}
											updateValue={updateValue}
											toggleUpdate={toggleUpdate}
											updateTime={updateTime}
											updateDeliveryDay={
												updateDeliveryDay
											}
											updateNewValue={updateNewValue}
											updateNewTime={updateNewTime}
											showValue={showValue}
										/>
									);
								})}
							</div>
						);
					})}
				</div>
			</React.Fragment>
		);
	}
}

export default Calender;
