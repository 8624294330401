import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import Loading from '../loading/Loading';
import useToaster from '../toaster/useToaster';

import './styles.css';

const Pagination = ({
	children,
	url,
	countUrl,
	count,
	setCount,
	params,
	pageSize,
	setPageSize,
	page,
	setPage,
	setObjects,
	sortBy,
	setSortBy,
	sortByList,
	order,
	setOrder,
	parameter,
	searchParameter,
	setSearchParameter,
}) => {
	const [fetchParameters, setFetchParameters] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [maxPageNumber, setMaxPageNumber] = useState(0);

	const [debounceParameters] = useDebounce(fetchParameters, 1000);

	const { addToaster } = useToaster();
	const { auth } = useAuth();

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const fetchCount = async () => {
			setErrorMessage(null);
			setIsLoading(true);
			const { searchParameter, parameter } = debounceParameters;

			let url = `${countUrl}?`;

			if (parameter !== undefined && searchParameter !== undefined)
				url += `${parameter}_contains=${searchParameter}`;

			try {
				const response = await axios.get(url, {
					headers: {
						Authorization: `Bearer ${auth?.jwt}`,
					},
					signal: controller.signal,
				});

				if (response?.status === 200) {
					if (isMounted) {
						setCount(response?.data);
						setMaxPageNumber(Math.ceil(response?.data / pageSize));
						fetchObjects(response?.data);
					}
				}
			} catch (error) {
				if (isMounted) {
					setIsLoading(false);
					if (error.message !== 'canceled') {
						setErrorMessage(error?.message);
						addToaster({
							title: `Fetching ${countUrl}`,
							text: 'Error fetching count',
							type: 'error',
						});
					}
				}
			}
		};

		const fetchObjects = async (count) => {
			if (count !== null) {
				const { searchParameter, parameter } = debounceParameters;

				let queryUrl = `${url}?_limit=${pageSize}&_start=${
					page * pageSize
				}&`;

				if (parameter !== undefined && searchParameter !== undefined)
					queryUrl += `${parameter}_contains=${searchParameter}&`;

				if (sortBy !== undefined && order !== undefined)
					queryUrl += `_sort=${sortBy}:${order}`;

				try {
					const response = await axios.get(queryUrl, {
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
						},
						signal: controller.signal,
					});

					if (response?.status === 200) {
						if (isMounted) setObjects(response.data);
					}
				} catch (error) {
					if (isMounted && error.message !== 'canceled') {
						setErrorMessage(error?.message);
						addToaster({
							title: `Fetching ${url}`,
							text: 'Error fetching objects',
							type: 'error',
						});
					}
				} finally {
					setIsLoading(false);
				}
			}
		};

		if (debounceParameters !== null) fetchCount(page, pageSize);

		return () => {
			controller.abort();
			isMounted = false;
		};
	}, [
		debounceParameters,
		setCount,
		setObjects,
		auth,
		page,
		url,
		countUrl,
		pageSize,
		sortBy,
		order,
		addToaster,
	]);

	useEffect(() => {
		setFetchParameters({
			parameter,
			searchParameter,
		});
	}, [parameter, searchParameter, setFetchParameters]);

	return (
		<div className='pagination-container'>
			{errorMessage !== null ? (
				<p>{errorMessage}</p>
			) : (
				<>
					<div className='pagination-header'>
						{parameter !== undefined &&
							searchParameter !== undefined &&
							setSearchParameter !== undefined && (
								<input
									type='text'
									placeholder={`Søg efter ${parameter}`}
									value={searchParameter}
									onChange={(e) =>
										setSearchParameter(e.target.value)
									}
								/>
							)}

						<select
							defaultValue={pageSize}
							onChange={(e) => {
								setPageSize(parseInt(e.target.value));
							}}
						>
							<option value={10}>10 elementer</option>
							<option value={25}>25 elementer</option>
							<option value={50}>50 elementer</option>
						</select>

						{sortBy !== undefined &&
							setSortBy !== undefined &&
							sortByList !== undefined && (
								<select
									onChange={(e) => setSortBy(e.target.value)}
									defaultValue={sortBy}
								>
									{sortByList &&
										Array.isArray(sortByList) &&
										sortByList.map((option, i) => {
											return (
												<option
													value={option?.value}
													key={i}
												>
													{option?.name}
												</option>
											);
										})}
								</select>
							)}
						{order !== undefined && setOrder !== undefined && (
							<select
								defaultValue={order}
								onChange={(e) => setOrder(e.target.value)}
							>
								<option value='ASC'>Stigende</option>
								<option value='DESC'>Faldende</option>
							</select>
						)}
					</div>
					<div className='pagination-children-container'>
						{isLoading === true ? <Loading /> : children}
					</div>
					<div className='pagination-footer'>
						{page <= 0 ? (
							<>
								<p className='pagination-footer-selected-page'>
									1
								</p>
								{page + 1 < maxPageNumber - 1 && (
									<button
										onClick={() => setPage(1)}
										className='pagination-footer-button-page'
									>
										2
									</button>
								)}
								{page + 1 < maxPageNumber && (
									<button
										onClick={() =>
											setPage(maxPageNumber - 1)
										}
										className='pagination-footer-button-page'
									>
										{maxPageNumber}
									</button>
								)}
							</>
						) : page >= maxPageNumber - 1 ? (
							<>
								<button
									onClick={() => setPage(0)}
									className='pagination-footer-button-page'
								>
									1
								</button>
								{page - 1 > 0 && (
									<button
										className='pagination-footer-button-page'
										onClick={() =>
											setPage(maxPageNumber - 2)
										}
									>
										{maxPageNumber - 1}
									</button>
								)}
								<p className='pagination-footer-selected-page'>
									{maxPageNumber}
								</p>
							</>
						) : (
							<>
								<button
									onClick={() => setPage(0)}
									className='pagination-footer-button-page'
								>
									1
								</button>
								{page > 1 && (
									<button
										onClick={() => setPage(page - 1)}
										className='pagination-footer-button-page'
									>
										{page}
									</button>
								)}
								<p className='pagination-footer-selected-page'>
									{page + 1}
								</p>
								{page < maxPageNumber - 2 && (
									<button
										onClick={() => setPage(page + 1)}
										className='pagination-footer-button-page'
									>
										{page + 2}
									</button>
								)}
								<button
									onClick={() => setPage(maxPageNumber - 1)}
									className='pagination-footer-button-page'
								>
									{maxPageNumber}
								</button>
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default Pagination;
