import React from 'react';

export default function CalenderItem(props) {
	const { date, type } = props.item;

	return (
		<div
			className={'calender-day-item calender-day-item-type-' + type}
			onClick={() => props.clickHandler(date)}
		>
			<span className='calender-day-item-day'>{date.getDate()}</span>
		</div>
	);
}
