import React from 'react';

const ActiveTag = ({ active }) => {
	return active === true ? (
		<div className='autocamper-active-tag-container autocamper-active-tag'>
			<p className='autocamper-active-tag-text'>AKTIV</p>
		</div>
	) : (
		<div className='autocamper-active-tag-container autocamper-inactive-tag'>
			<p className='autocamper-active-tag-text'>INAKTIV</p>
		</div>
	);
};

export default ActiveTag;
