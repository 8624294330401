import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import Loading from '../../loading/Loading';

const BarChart = ({ name, isLoading, data }) => {
	return (
		<div className='global-container global-statistic-container global-income-container'>
			<h1 className='global-statistic-title'>
				{name} (Data passer ikke)
			</h1>
			{isLoading ? (
				<Loading />
			) : (
				<VictoryChart
					theme={VictoryTheme.material}
					width={500}
					height={500}
					padding={{ left: 70, right: 20, top: 50, bottom: 60 }}
				>
					<VictoryBar
						x='month'
						y='income'
						data={data}
						style={{ data: { fill: '#2d7163' } }}
						cornerRadius={{ top: 5 }}
						barWidth={16}
					/>
					<VictoryAxis />
					<VictoryAxis
						dependentAxis
						tickFormat={(x) => `${x / 1000}K DKK`}
					/>
				</VictoryChart>
			)}
		</div>
	);
};

export default BarChart;
