import React from 'react';
import axios from '../../api/axios';
import Popup from './popup';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useToaster from '../../components/toaster/useToaster';

export const CancelBooking = (props) => {
	const [verify, setVerify] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { addToaster } = useToaster();
	const { auth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const from =
		location.state?.from?.pathname ||
		`/autocamper/${props.autocamper.id}/calender`;

	const cancelBooking = async () => {
		if (verify.toLocaleLowerCase() === 'slet') {
			setIsLoading(true);
			try {
				const response = await axios.delete(
					`calender-objects/${props?.id}`,
					{
						headers: {
							Authorization: `Bearer ${auth?.jwt}`,
							'Content-Type': 'application/json',
						},
					}
				);

				if (response?.status === 200 && response?.data) {
					addToaster({
						title: 'Booking Cancelled',
						text: 'Booking was cancelled',
						type: 'success',
					});
					navigate(from, { replace: true });
				} else {
					addToaster({
						title: 'Cancel Booking',
						text: 'Something went wrong cancelling the booking.',
						type: 'error',
					});
				}
			} catch (error) {
				addToaster({
					title: 'Cancel Booking',
					text: 'Something went wrong cancelling the booking.',
					type: 'error',
				});
			} finally {
				setIsLoading(false);
			}
		} else {
			addToaster({
				title: 'Booking Cancelled',
				text: 'Please enter the verification text; "slet".',
				type: 'warning',
			});
		}
	};

	return (
		<Popup
			toggle={props.toggle}
			name='Slet booking'
			action={cancelBooking}
			actionRunning={isLoading}
			buttonText='Slet'
		>
			<div className='create-calender-container'>
				<div className='vertical-form'>
					<div className='delete-booking-popup-container'>
						<p className='delete-booking-popup-text'>
							Er du sikker på du vil slette denne booking, det kan{' '}
							<span
								style={{
									fontWeight: 'bold',
									textDecoration: 'underline',
								}}
							>
								IKKE
							</span>{' '}
							blive lavet om efterfølgende?
							<br></br>
							Skriv "Slet" i feltet, for at kunne slette
							bookingen.
						</p>
						<input
							type='text'
							placeholder={'Slet'}
							onChange={(e) => setVerify(e.target.value)}
							value={verify}
							className='booking-update-input'
						/>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default CancelBooking;
