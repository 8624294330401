import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import utility from '../../../../utils/utility';

function withinWeeks(m, startDate, endDate) {
	const startMonth = new Date(m.getFullYear(), m.getMonth(), 1);
	const endMonth = new Date(m.getFullYear(), m.getMonth() + 1, 0);

	let start =
		startMonth < startDate
			? utility.getWeekNumber(startDate)
			: utility.getWeekNumber(startMonth);
	const end =
		endDate < endMonth
			? utility.getWeekNumber(endDate)
			: utility.getWeekNumber(endMonth);

	if (start > end) {
		start =
			utility.getWeekNumber(new Date(m.getFullYear() - 1, 11, 31)) -
			start;
	}

	return end - start + 1;
}

export default function CalenderObject(props) {
	const [hovering, setHovering] = useState(false);
	const [objects, setObjects] = useState([]);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const { object, month, lastDay, firstDay } = props;
		const n = withinWeeks(month, object.start, object.end);

		const objects = [];

		for (let i = 0; i < n; i++) {
			function calcLeft(s) {
				const time = (s.getUTCHours() * 60 + s.getUTCMinutes()) / 1440;
				return (
					((s.getDay() - 1 + 7) % 7) * (100.0 / 7) +
					(100.0 / 7) * time
				);
			}

			let firstWeekNo = utility.getWeekNumber(month);
			let weekNo = utility.getWeekNumber(object.start);
			const lastWeekNo = utility.getWeekNumber(
				new Date(month.getFullYear(), month.getMonth() + 1, 0)
			);

			if (lastWeekNo < firstWeekNo) {
				firstWeekNo =
					utility.getWeekNumber(
						new Date(month.getFullYear() - 1, 11, 31)
					) - firstWeekNo;
			}

			if (object.start.getTime() < month.getTime()) weekNo = firstWeekNo;

			const top =
				(100 / (lastWeekNo - firstWeekNo + 1)) *
					(weekNo - firstWeekNo + i) +
				'%';

			let left;
			let width;
			// If it's the first object.
			if (i === 0) {
				// If it started outside the calender.
				if (object.start.getTime() < firstDay.getTime()) {
					left = '-5px';
				} else {
					left = calcLeft(object.start) + '%';
				}
			} else {
				left = '-5px';
			}

			if (i + 1 !== n) {
				if (i !== 0) width = 'calc(100% + 10px)';
				else width = 'calc(100% - 5px - ' + left + ')';
			} else {
				if (lastDay.getTime() < object.end.getTime())
					width = 'calc(100% - 5px - ' + left + ')';
				else
					width =
						'calc(' +
						calcLeft(object.end) +
						'% - 5px - ' +
						left +
						')';
			}

			objects.push({
				style: {
					top: top,
					left: left,
					width: width,
				},
				data: object,
			});
		}

		setObjects(objects);
	}, [props]);

	const calenderObjectClickEvent = (object) => {
		navigate(`/booking/${object.id}`, { state: { from: location } });
	};

	return (
		<div className='parent-calender-object'>
			{objects.map((object, i) => {
				return (
					<div
						className={
							'child-calender-object' +
							(hovering === true
								? ' calender-object-hover'
								: '') +
							(' calender-object-' +
								object.data.calender_object_type.type)
						}
						style={object.style}
						key={i}
						onMouseOver={() => setHovering(true)}
						onMouseLeave={() => setHovering(false)}
						onClick={() => calenderObjectClickEvent(object.data)}
					>
						{i === 0 && ( // If it's the first child object.
							<div>
								<p className='calender-object-text'>
									{object.data.start.getUTCHours() +
										':' +
										(object.data.start.getUTCMinutes() <= 9
											? '0' +
											  object.data.start.getUTCMinutes()
											: object.data.start.getUTCMinutes()) +
										' ' +
										object.data.start.getUTCDate() +
										'/' +
										(object.data.start.getUTCMonth() + 1) +
										' - ' +
										object.data.end.getUTCHours() +
										':' +
										(object.data.end.getUTCMinutes() <= 9
											? '0' +
											  object.data.end.getUTCMinutes()
											: object.data.end.getUTCMinutes()) +
										' ' +
										object.data.end.getUTCDate() +
										'/' +
										(object.data.end.getUTCMonth() + 1)}
								</p>
								<p className='calender-object-text'>
									<span>ID: {object.data.id}, </span>
									<span>
										Type:{' '}
										{object.data.calender_object_type.type}
									</span>
								</p>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
}
