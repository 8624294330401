import { useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

const EditAddress = ({ toggle, id, updateAddress }) => {
	const [street, setStreet] = useState('');
	const [streetNumber, setStreetNumber] = useState('');
	const [postalCode, setPostalCode] = useState('');
	const [city, setCity] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const putAddress = async () => {
		setIsLoading(true);

		try {
			const response = await axios.put(
				`/organizations/${id}/address`,
				{
					street: street,
					streetNumber: streetNumber,
					postalCode: parseInt(postalCode),
					city: city,
				},
				{
					headers: {
						authorization: `Bearer ${auth?.jwt}`,
					},
				}
			);

			if (response?.status === 200) {
				updateAddress({ street, streetNumber, postalCode, city });
				addToaster({
					title: 'Updating Address',
					text: 'Adressen blev opdateret.',
					type: 'success',
				});
				toggle();
			}
		} catch (error) {
			setIsLoading(false);
			addToaster({
				title: 'Updating Address',
				text: 'Noget gik galt...',
				type: 'error',
			});
		}
	};

	return (
		<Popup
			toggle={toggle}
			name='Rediger Adresse'
			action={putAddress}
			actionRunning={isLoading}
			buttonText='Opdater'
		>
			<section className='vertical-form'>
				<div className='update-address-input-item'>
					<label htmlFor='update-street'>Vej</label>
					<input
						type='text'
						placeholder='Vej'
						id='update-street'
						value={street}
						onChange={(e) => setStreet(e.target.value)}
					/>
				</div>
				<div className='update-address-input-item'>
					<label htmlFor='update-streetNumber'>Husnummer</label>
					<input
						type='text'
						placeholder='Husnummer'
						id='update-streetNumber'
						value={streetNumber}
						onChange={(e) => setStreetNumber(e.target.value)}
					/>
				</div>
				<div className='update-address-input-item'>
					<label htmlFor='update-postalCode'>Postnummer</label>
					<input
						type='text'
						placeholder='Postnummer'
						id='update-postalCode'
						value={postalCode}
						onChange={(e) => setPostalCode(e.target.value)}
					/>
				</div>
				<div className='update-address-input-item'>
					<label htmlFor='update-city'>By</label>
					<input
						type='text'
						placeholder='By'
						id='update-city'
						value={city}
						onChange={(e) => setCity(e.target.value)}
					/>
				</div>
			</section>
		</Popup>
	);
};

export default EditAddress;
