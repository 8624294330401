import { useState } from 'react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useToaster from '../toaster/useToaster';
import Popup from './popup';

const EditBookingMessage = ({ toggle, id, updateBookingMessage }) => {
	const [bookingMessage, setBookingMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const { auth } = useAuth();
	const { addToaster } = useToaster();

	const putBookingMessage = async () => {
		setIsLoading(true);
		try {
			const response = await axios.put(
				`/organizations/${id}/bookingMessage`,
				{
					bookingMessage: bookingMessage,
				},
				{
					headers: {
						authorization: `Bearer ${auth?.jwt}`,
					},
				}
			);

			if (response?.status === 200) {
				updateBookingMessage(bookingMessage);
				addToaster({
					title: 'Update Booking Message',
					text: 'Booking message was updated.',
					type: 'success',
				});
				toggle();
			}
		} catch (error) {
			addToaster({
				title: 'Update Booking Message',
				text: 'Something went wrong...',
				type: 'error',
			});
			setIsLoading(false);
		}
	};

	return (
		<Popup
			toggle={toggle}
			name='Rediger Booking Besked'
			action={putBookingMessage}
			actionRunning={isLoading}
			buttonText='Opdater'
		>
			<section className='vertical-form'>
				<h1>Booking Besked</h1>
				<p>
					Besked bliver sendt til lejeren et par dage inden bookingen
					begynder. Her i kan du beskrive de vigtige informationer som
					lejeren skal vide inden bookingen begynder.
				</p>
				<textarea
					value={bookingMessage}
					onChange={(e) => setBookingMessage(e.target.value)}
					style={{ resize: 'none' }}
				/>
			</section>
		</Popup>
	);
};

export default EditBookingMessage;
