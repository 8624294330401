import React from 'react';
import { useNavigate } from 'react-router-dom';

import './styles.css';

export const ErrorHandling = (props) => {
	const navigate = useNavigate();

	const error = {
		400: {
			text: 'Dårlig anmodning sendt',
			img: 'error.png',
		},
		401: {
			text: 'Kræver log ind',
			img: '401.png',
		},
		402: {
			text: 'Kræver betaling',
			img: 'error.png',
		},
		403: {
			text: 'Du har ingen adgang til dette objekt',
			img: 'error.png',
		},
		404: {
			text: 'Kunne ikke finde objektet',
			img: '404.png',
		},
		500: {
			text: 'Noget gik galt på vores server',
			img: 'error.png',
		},
	};

	return (
		<section className='error-handling-centering'>
			<div className='error-handling-container'>
				<img
					src={`/codes/${error[props?.status]?.img}`}
					className='error-handling-icon'
					alt='Error code'
				/>
				<div className='error-handling-text-container'>
					<h1 className='error-handling-title'>
						Fejlkode {props?.status}
					</h1>
					<p className='error-handling-text'>
						{error[props.status]?.text}
					</p>
					<button
						className='error-handling-go-back-button'
						onClick={() => navigate(-1, { replace: true })}
					>
						Gå tilbage
					</button>
				</div>
			</div>
		</section>
	);
};
